import React from 'react'
import {Col, Form, Row} from "react-bootstrap";
import GsgButton from "../../../shared/components/gsg_button";
import GripDropdownItem from "../../../grips/components/GripDropdownItem";
import {createCategory, removeCategory} from "../actions";

function Categories(props) {

  const {
    categories,

    newCategory,
    editCategory,
    saveCategory,
    createCategory,
    changeCategory,
    cancelCategory,
    removeCategory,
  } = props;

  return (
    <li className="form_row">
      <Row>
        <Col xs={'3'} className={'label_names '}>
          <label>
            Item Categories
          </label>
        </Col>

        <Col xs={'12'} sm={'9'} className={''}>
          <Row className={'description'}>
            <div className={'clear'}>
              Create item categories to organize dashboard filtering
            </div>
            <div className={'my-2'}>
              <button
                type={'button'}
                className="btn gems_custom_button gems_orange_button btn-default"
                onClick={() => newCategory()}
              >
                CREATE CATEGORY
              </button>
            </div>
            <div className={'bold'}>
              Current Categories
            </div>

          </Row>
          <Row>
            {categories.map((category) => (
              <>
                {category.id !== 'new' && !category.attributes?.edit && <div className={'d-flex ps-4 mb-2'} style={{gap: "10px"}} key={category.id}>
                  <div style={{minWidth: '300px'}}>
                    {category.attributes?.name}
                  </div>
                  <div>
                    <button
                      type={'button'}
                      className="btn gems_custom_button btn-default color_blue"
                      onClick={() => editCategory(category.id)}
                    >
                      Edit
                    </button>
                  </div>
                  <div>
                    <button
                      type={'button'}
                      className="btn gems_custom_button btn-default color_red"
                      onClick={() => removeCategory(category)}
                    >
                      Remove
                    </button>
                  </div>
                </div>}
                {(category.id === 'new' || category.attributes?.edit) && <div className={'d-flex ps-4 mb-2'} style={{gap: "10px"}} key={category.id}>
                  <div style={{minWidth: '300px'}}>
                    <Form.Control type="text"
                                  name={'name'}
                                  value={category.attributes?.edit_name !== undefined ? category.attributes?.edit_name : category.attributes?.name}
                                  onChange={(e) => changeCategory(category, `${category.attributes?.edit ? 'edit_' : ''}name`, e.target.value)}
                                  className={'form-control form-control-sm custom_input no_color'}
                    />
                  </div>
                  <div>
                    <button
                      type={'button'}
                      className="btn gems_custom_button gems_orange_button btn-default"
                      onClick={() => category.id !== 'new' ? saveCategory(category) : createCategory(category)}
                    >
                      Save
                    </button>
                  </div>
                  <div>
                    <button
                      type={'button'}
                      className="btn gems_custom_button btn-default"
                      onClick={() => cancelCategory(category)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>}
              </>
            ))}
          </Row>
        </Col>
      </Row>
    </li>
  )

}


export default Categories
