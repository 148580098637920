import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Toast, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Form, Col, Row } from 'react-bootstrap';
import {useState, useEffect} from 'react';
import { hexToRgb, getComplementaryColor } from '../../../shared/helpers'

function MemberProfileTile(props) {

  const initialState = {updated_values: {}, editable_fields: []}

  const [state, setState] = useState(initialState);

  const handleSaveUpdates = () => {
    let params = {...state.updated_values}
    
    props.updateMemberProfile(JSON.stringify(params))

    setState({...state, updated_values: {}, editable_fields: [] });
  }

  const handleInputChange = (event) => {
    let updated_values = state.updated_values
    updated_values[event.target.name] = event.target.value
    
    setState({...state, updated_values: updated_values });
  }

  const handleEditFields = (event) => {
    let key = event.target.getAttribute("field_key")
    let editable_fields = state.editable_fields
    editable_fields.push(key)
    
    setState({...state, editable_fields: editable_fields });
  }

  const handleResetFields = (event) => {
    let key = event.target.getAttribute("field_key")
    let editable_fields = state.editable_fields
    editable_fields = editable_fields.filter(f => f != key)

    let updated_values = state.updated_values
    if(updated_values[key])
      delete updated_values[key]
    
    setState({...state, editable_fields: editable_fields, updated_values: updated_values });
  }
  const categories = props.member_profile_data.filter(c => !c.category && c.display)
  return (  
      <div className="row member_profile_tile pe-0 h-100" style={{overflowY: 'auto', overflowX: 'hidden'}}>
        <div className='col'>
          {props.member_id && <a
            className={`btn gems_custom_button float-end offset-10 mt-1 ${Object.keys(state.updated_values).length == 0 ? 'disabled' : ''}`}
            style={{backgroundColor: props.schemaColor, color: getComplementaryColor((props.schemaColor))}}
            onClick={handleSaveUpdates} >
            Update
          </a>}
        </div>
        {categories.map((cmp, index) => (
          <div className='col-12 pe-0' key={`${cmp.name}_${index}`}>
          <div className="row mt-2 py-1 px-0">
            <span className="p-0"><b>{cmp.name.toUpperCase()}</b></span>
          </div>
          <div className='row white_back p-0 py-2'>
          {props.member_profile_data.filter(m => m.category == cmp.name).map((mp, index) => (
            (mp.editable || props.member_profile_values[mp.key]) &&
            <div className='col-lg-6 col-12 ps-3 my-1' key={mp.key}>
              <Form.Group as={Row} className="form-row top-row" controlId="formTileTitle">
                <Form.Label column xs="5">
                  <div className="row">
                    {mp.editable && <div className='col-1 p-0' field_key={mp.key}>{ mp.editable ? !state.editable_fields.includes(mp.key) ? <i onClick={handleEditFields} field_key={mp.key} className="mt-1 fa fa-pencil fa-fw link"></i> : <i onClick={handleResetFields} field_key={mp.key} className="mt-1 fa fa-close fa-fw link color_red"></i> : null}</div>}
                    <div className={`col-10 ${!mp.editable ? 'offset-1' : ''} bold`}>
                    {mp.name}</div>
                  </div>
                </Form.Label>
                <Col xs="7">
                { state.editable_fields.includes(mp.key) ?
                  <input type="text"
                  name={mp.key}
                  placeholder={mp.name}
                  className="form-control custom_input"
                  defaultValue={ state.updated_values[mp.key] || props.member_profile_values[mp.key] || ""}
                  onChange={ handleInputChange }
                  autoFocus />
                  :
                  <Form.Label column>
                  <div className="row">
                    <div className="col"> 
                    {state.updated_values[mp.key] || props.member_profile_values[mp.key]}
                    </div>
                  </div>
                  </Form.Label>
                }
                </Col>
              </Form.Group>
            </div>))}
          </div>
        </div>))}
      </div>
  )
}

export default MemberProfileTile
