import React from 'react';
import Modal, { ModalHeader, ModalBody, ModalFooter} from './Modal';
import { Row, Form, Col, Table } from 'react-bootstrap';
import Select from 'react-select';
import {useState} from 'react';
import {FcPlus} from '@react-icons/all-files/fc/FcPlus'
import {MdCancel} from '@react-icons/all-files/md/MdCancel'
import _ from 'lodash';

function FilterCategoryModal(props) {
  
  const initialState = {}
  
  const [state, setState] = useState(initialState);

  const resetState = () => {
    setState(initialState)
  }

  const handleClose = () => {
    resetState()
    props.closeFilterModal()
  }
  const handleAddFilter = () => {
    let filters_list_local = [...props.filters_list]

    let initState = state.filters ? state.filters : filters_list_local
    let new_filter = {category_id: state.category_id, category_name: state.category_name}
    if(state.category_id && state.category_name)
      setState({...state, category_id: null, category_name: null, filters: [...initState, new_filter]});
  } 
  const handleRemoveFilter = (event) => {
    let filters_list_local = [...props.filters_list]
    let removed_id = event.target.getAttribute("category_id")

    if(!removed_id)
      removed_id = event.target.parentElement.getAttribute("category_id")

    let initState = (state.filters) ? state.filters : filters_list_local
    let newState = _.remove(initState, function(n) {
      return n.category_id != removed_id;
    });

    setState({...state, category_id: null, category_name: null, filters: newState});
  }
  
  const handleSubmit = () => {
    let params = [...state.filters]

    props.applyFilters(params)
    handleClose()
  }

  const handleCategorySelectChange = (event) => {
    setState({...state, category_id: event.value, category_name: event.label});
  }

  const {modal_filter_open, categories_list_for_filter, filters_list} = props;
  const {category_id, filters} = state;
  return (
    modal_filter_open && <div className="modal_stock_order_container">
      <Modal isOpen={modal_filter_open} klassName="filter_order_popup">
        <ModalHeader >
          <h4 className="modal-title form_new_edit no-margin"><div className="col">Filter Stock Orders by product category</div></h4>
        </ModalHeader>
        <ModalBody>
          <Form>
            <div className="row mb-2">Filter orders by following categories:</div>
            <Form.Group as={Row} controlId="formCategoiesList">
              {
                (filters || filters_list).length > 0 ? (filters || filters_list).map((f) => (
                  <div key ={f.category_id} className="col-12 mb-2">
                    <div className="row">
                        <div className="col-10">{f.category_name}</div>
                        <div className="col-2">
                          <a category_id={f.category_id} onClick={handleRemoveFilter} >
                            <MdCancel className="fs-4 color_red" category_id={f.category_id}/>
                          </a>
                        </div>
                      </div>
                    </div>
                )) : "No categories selected" }
              </Form.Group>
              <Form.Group as={Row} controlId="formCategoies">
                <Col sm="10">
                  <Select id = "category_id" 
                              name = 'category_id' 
                              options={categories_list_for_filter}
                              value={categories_list_for_filter.filter(function(option) {
                                return option.value === category_id;
                              })}
                              onChange={handleCategorySelectChange}
                              />
                </Col>
                <Col sm="2" className="mt-1">
                  <a>
                    <FcPlus className="fs-4" onClick={handleAddFilter} />
                  </a>
                </Col>
              </Form.Group>
          </Form>
        </ModalBody>
        <ModalFooter>
        <button
            className="float-start btn gems_custom_button"
            aria-label="Close"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className="btn gems_custom_button gems_orange_button float-end"
            onClick={handleSubmit}
          >
            Apply
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
} export default FilterCategoryModal