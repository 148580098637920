import {connect} from 'react-redux'
import {
  changeNotificationSendOption,
  closeMarkAsFoundItemModal,
  createEmployee,
  createGuest, getMembersDropdownData, getOverrideNotification, removeFile,
  transitionAction, updateContactInfo,
  updateNotifications, uploadFile
} from '../../actions'
import MarkAsFoundItemModal from "../../components/actions_modals/MarkAsFoundItemModal";

const mapStateToProps = (state) => ({
  date_format: state.dashboard.meta.settings.date_format,
  customer_id: state.dashboard.meta.settings.customer_id,
  default_current_location_id: state.dashboard.meta.settings.default_current_location_id,
  markAsFoundItemIsOpen: state.dashboard.uiState.modals.markAsFoundItemIsOpen,
  notification_communication_data: state.dashboard.uiState.notificationData.communicationData,

  item: state.dashboard.uiState.selectedItem,
  selectedItemAssociationIds: state.dashboard.uiState.selectedItemAssociationIds,
  activity_logs: Object.values(state.dashboard.entities.activity_logs).filter((al) => (state.dashboard.uiState.selectedItemAssociationIds.activityLogsIds).includes(al.id)),
  attachments: Object.values(state.dashboard.entities.attachments).filter((a) => (state.dashboard.uiState.selectedItemAssociationIds.attachmentsIds).includes(a.id)),
  email_delivery_reports: Object.fromEntries(Object.entries(state.dashboard.entities.email_delivery_reports).filter(([key, edr]) => (state.dashboard.uiState.selectedItemAssociationIds.emailDeliveryReportsIds || []).includes(edr.id))),


  members_dropdown_list: state.dashboard.cachedData.members,
  employees_dropdown_list: state.dashboard.cachedData.employees,
  guests_dropdown_list: state.dashboard.cachedData.guests,
  managers_dropdown_list: state.dashboard.cachedData.managers,
  current_locations_dropdown_list: state.dashboard.cachedData.current_locations,
})

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeMarkAsFoundItemModal()),
  createEmployee: (data, field) => dispatch(createEmployee(data, field)),
  createGuest: data => dispatch(createGuest(data)),
  getMembersDropdownData: data => dispatch(getMembersDropdownData(data)),
  transitionAction: (item_id, transition, params) => dispatch(transitionAction(item_id, transition, params)),
  updateNotifications: data => dispatch(updateNotifications(data)),
  updateContactInfo: (customer, channel, value) => dispatch(updateContactInfo(customer, channel, value)),
  changeNotificationSendOption: (customer, notification_type, channel, is_enabled) => dispatch(changeNotificationSendOption(customer, notification_type, channel, is_enabled)),
  getOverrideNotification: (item, notif_customer_id, channel, template_type) => dispatch(getOverrideNotification(item, notif_customer_id, channel, template_type)),
  uploadFile: data => dispatch(uploadFile(data)),
  removeFile: file => dispatch(removeFile(file)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MarkAsFoundItemModal)
