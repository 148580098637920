import React from 'react'
import {ActionsConfig, QuickActionByStatus, StatusLabels} from "./constants";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

function ItemStatus(props) {
  const {
    item,
    transitionQuickAction
  } = props

  const shouldDisplayQuickAction = () => {
    return item.attributes.status in QuickActionByStatus
  }

  return (<div className="d-flex justify-content-between" style={{gap: "8px"}}>
    <label
      className="order-0 basic_label_container white mb-1 mx-1 tooltip_delivered"
      style={{backgroundColor: StatusLabels[item.attributes.status]?.color || ''}}
    >
      {StatusLabels[item.attributes.status]?.label || ''}
    </label>
    {shouldDisplayQuickAction() ?
      <OverlayTrigger
        key={item.id}
        placement="top"
        overlay={
          <Tooltip id={`tooltip-quick-action`}>
            {ActionsConfig[QuickActionByStatus[item.attributes.status]]?.label || ''}
          </Tooltip>
        }

      >
        <span
          onClick={(e) => transitionQuickAction(item.id, QuickActionByStatus[item.attributes.status], {})}
          className="order-2 btn_icons_datatable padding_forward link next float-end standard me-2"
        >
          <i className="font_size_larger fa fa-forward mt-1"/>
        </span>
      </OverlayTrigger>
      : <span
        className="order-2 btn_icons_datatable padding_forward link next float-end standard me-2"
        id={item.id}
      >
        <i className="font_size_larger fa fa-forward mt-1 color_gray"/>
      </span>}

  </div>)
}

export default ItemStatus
