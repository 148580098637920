import {ActionTypes} from '../actions'
import {normalizeList} from "../../../shared/helpers";

const {
  NEW_LOCATION,
  EDIT_LOCATION,
  SAVE_LOCATION,
  CREATE_LOCATION,
  CHANGE_LOCATION,
  CANCEL_LOCATION,
  REMOVE_LOCATION,
  UPDATE_LOCATIONS,
  NEW_CATEGORY,
  EDIT_CATEGORY,
  SAVE_CATEGORY,
  CREATE_CATEGORY,
  CHANGE_CATEGORY,
  CANCEL_CATEGORY,
  REMOVE_CATEGORY,
} = ActionTypes

const initialState = {
  entities: {
    categories: [],
    current_locations: []
  },
  meta: {
    general: {}
  },
  app_state: {
    is_loading: false,
  },
  uiState: {},
}

const settings = (state = initialState, action) => {
  let temp_categories;
  let temp_locations;
  let category_aux;
  let current_location_aux;

  switch (action.type) {
    case NEW_LOCATION:
      temp_locations = {...state.entities.current_locations}
      temp_locations['new'] = {
        id: 'new',
        type :"lost_and_found_locations",
        attributes: {
          name: '',
          default: false,
        }
      };

      return {
        ...state,
        entities: {
          ...state.entities,
          current_locations: temp_locations
        }
      }

    case EDIT_LOCATION:
      temp_locations = {...state.entities.current_locations}
      temp_locations[action.data.location_id].attributes.edit = true
      return {
        ...state,
        entities: {
          ...state.entities,
          current_locations: temp_locations
        }
      }

    case CREATE_LOCATION:
      temp_locations = {...state.entities.current_locations}
      temp_locations[action.data.new_location.id] = action.data.new_location
      delete temp_locations['new']

      return {
        ...state,
        entities: {
          ...state.entities,
          current_locations: temp_locations
        }
      }

    case SAVE_LOCATION:
      temp_locations = {...state.entities.current_locations}
      temp_locations[action.data.location.id] = action.data.location
      return {
        ...state,
        entities: {
          ...state.entities,
          current_locations: temp_locations
        }
      }

    case CHANGE_LOCATION:
      temp_locations = {...state.entities.current_locations}
      temp_locations[action.data.location.id].attributes[action.data.field] = action.data.value
      return {
        ...state,
        entities: {
          ...state.entities,
          current_locations: temp_locations
        }
      }

    case CANCEL_LOCATION:
      temp_locations = {...state.entities.current_locations}
      if (action.data.location.id) {
        current_location_aux = action.data.location
        delete current_location_aux.attributes.edit
        delete current_location_aux.attributes.edit_name
        temp_locations[current_location_aux.id] = current_location_aux
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          current_locations: temp_locations
        }
      }

    case REMOVE_LOCATION:
      temp_locations = {...state.entities.current_locations}
      delete temp_locations[action.data.location.id]
      return {
        ...state,
        entities: {
          ...state.entities,
          current_locations: temp_locations
        }
      }

    case UPDATE_LOCATIONS:
      return {
        ...state,
        entities: {
          ...state.entities,
          current_locations: action.data.locations
        }
      }



    case NEW_CATEGORY:
      temp_categories = {...state.entities.categories}
      temp_categories['new'] = {
        id: 'new',
        attributes: {
          name: '',
        }
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          categories: temp_categories
        }
      }

    case EDIT_CATEGORY:
      temp_categories = {...state.entities.categories}
      temp_categories[action.data.category_id].attributes.edit = true
      return {
        ...state,
        entities: {
          ...state.entities,
          categories: temp_categories
        }
      }

    case CREATE_CATEGORY:
      temp_categories = {...state.entities.categories}
      temp_categories[action.data.new_category.id] = action.data.new_category
      delete temp_categories['new']
      return {
        ...state,
        entities: {
          ...state.entities,
          categories: temp_categories
        }
      }

    case SAVE_CATEGORY:
      temp_categories = {...state.entities.categories}
      temp_categories[action.data.category.id] = action.data.category
      return {
        ...state,
        entities: {
          ...state.entities,
          categories: temp_categories
        }
      }

    case CHANGE_CATEGORY:
      temp_categories = {...state.entities.categories}
      temp_categories[action.data.category.id].attributes[action.data.field] = action.data.value
      return {
        ...state,
        entities: {
          ...state.entities,
          categories: temp_categories
        }
      }

    case CANCEL_CATEGORY:
      temp_categories = {...state.entities.categories}
      if (action.data.category.id) {
        category_aux = action.data.category
        delete category_aux.attributes.edit
        delete category_aux.attributes.edit_name
        temp_categories[category_aux.id] = category_aux
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          categories: temp_categories
        }
      }

    case REMOVE_CATEGORY:
      temp_categories = {...state.entities.categories}
      delete temp_categories[action.data.category.id]
      return {
        ...state,
        entities: {
          ...state.entities,
          categories: temp_categories
        }
      }

    default:
      return {
        ...initialState, ...state,
      }
  }
}

export default settings