import React from 'react'

import Header from '../components/Header'
import BodyContainer from '../containers/BodyContainer'
import ViewFilterContainer from "../containers/ViewFilterContainer";
import DashboardAdvancedFilterContainer from "../containers/DashboardAdvancedFilterContainer";
import FooterContainer from "../containers/FooterContainer";

function Dashboard(props) {
  return (
    <div className="col-12 h-100 d-flex flex-column">
      <Header/>
      <DashboardAdvancedFilterContainer/>
      <ViewFilterContainer/>
      <BodyContainer/>
      <FooterContainer/>
    </div>
  )

}


export default Dashboard
