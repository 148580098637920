import React, { Component } from 'react'
import GsgSpan from '../../shared/components/gsg_date'
import PropTypes from 'prop-types'
import { MdCallSplit } from '@react-icons/all-files/md/MdCallSplit'
import { FcCancel } from '@react-icons/all-files/fc/FcCancel'
import { Toast, OverlayTrigger, Tooltip } from 'react-bootstrap'

class PipedriveItem extends Component {
  constructor(props) {
    super(props)
  };

  state = {
    isOpen: false
  };

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

  edit = (event) => { this.props.editStockOrder(event.currentTarget.id) };
  delete = (event) => { this.props.deleteStockOrder(event.currentTarget.id) };
  openCancelStockOrder = (event) => { this.props.openCancelStockOrder(event.currentTarget.id) };
  openShipDetailsStockOrder = (event) => { this.props.openShipDetailsStockOrder(event.currentTarget.id) };
  nextAction = (event) => { (this.props.nextAction(event.currentTarget.id)) };
  restoreStockOrder = (event) => { this.props.restoreStockOrder(event.currentTarget.id) };
  openSplitStockOrder = (event) => { (this.props.openSplitStockOrder(event.currentTarget.id)) };
  createSimilarOrder = (event) => { (this.props.newStockOrder(event.currentTarget.id)) };

  extendTile = (event) => {
    let id = event.target.getAttribute("data-id")
    let expd_id = "expanded_view_tile_" + id
    let cond_id = "condensed_view_tile_" + id

    document.getElementById(expd_id).classList.remove('d-none')
    document.getElementById(cond_id).classList.add('d-none')
  };

  render() {
    const { id,
      vendor,
      order_no,
      order_nickname,
      total_items,
      vendor_confirmation_number,
      days,
      end_date,
      ordered_date,
      delivered_date,
      expected_ship_date,
      expected_delivery_date,
      stock_order_categories_list,
      vendor_cancel_date,
      cancellation_date, alerts, color, position, ship_details_name, cancel_alert, deleted,
      vendor_invoice_received, received_into_inventory, invoice_processed, print_po_url } = this.props.data
    const { next_action_name, visible, condensed_tile_layout } = this.props
    const menuClass = `dropdown-menu${this.state.isOpen ? " show" : ""}`;

    if (this.props.stock_order_id && this.props.stock_order_id == id) {
      this.props.editStockOrder(this.props.stock_order_id)
    }

    return (
      <div className={`col-12 ${visible ? "" : "d-none"}`} >
        {cancel_alert && <Toast bg='danger'
          key={"toast_" + id}
          id={"toast_" + id}
          autohide={true}
          delay={3000}
          onClose={() => { document.getElementById("toast_" + id).classList.add('d-none') }}
          style={{ position: 'absolute' }}>
          <Toast.Header>
            <strong className="me-auto" style={{ color: 'black' }}>Alert</strong>
          </Toast.Header>
          <Toast.Body style={{ color: 'white' }}>Stock Order:
            <b>
              {order_no + (order_nickname ? " ( " + order_nickname + " ) " : ' ')}
            </b>
            will be removed from your dashboard in 24h.</Toast.Body>
        </Toast>}
        <div onClick={this.extendTile} className={condensed_tile_layout ? "stock-order-item-box condensed_view_tile cursor_pointer" : "d-none"} data-id={id} style={{ borderLeft: "2px solid " + color }} id={"condensed_view_tile_" + id}>
          <div className="title text_align_left" data-id={id} >
            <span className="searchable_field link float-start font_size_16" data-id={id}>{order_no + (order_nickname ? " ( " + order_nickname + " )" : '')}</span>
            <span className="searchable_field float-end font_size_16" data-id={id}>{vendor}</span>
            {alerts.length > 0 &&
              <span className={"float-end font_size_16 add_padding_top_3 add_padding_right_10 position_relative " + ((alerts.length == 1 && alerts[0] == "Finalized") ? "color_green_finalized fa fa-check" : "color_red fa fa-exclamation-circle")} data-id={id} />}
          </div>
          <div className="row m-0 d_item_categories">
            {stock_order_categories_list.map((scat) => (
              <div key={scat.id} className="searchable_field p-0 mb-2 me-2" style={{ width: "auto" }}>
                <span className="container_name_category">
                  {scat.name}: {scat.qty}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className={condensed_tile_layout ? "d-none stock-order-item-box expanded_view_tile_" + id : "stock-order-item-box expanded_view_tile_" + id} style={{ borderLeft: "2px solid " + color }} id={"expanded_view_tile_" + id}>
          <div className='link title text_align_left add-line-height-30' >
            <a onClick={this.edit} id={id}>
              {vendor}
            </a>
          </div>
          <hr className="add-margin-top-5 add-margin-bottom-5"></hr>
          <div className="pipedrive_item_container">
            Order No:&nbsp;
            <span className="searchable_field">
              {order_no + (order_nickname ? " ( " + order_nickname + " )" : '')}
            </span>
            <br />
            Total items:&nbsp;
            <span className="searchable_field">
              {total_items}
            </span>
            <br />
            {vendor_confirmation_number && <GsgSpan name="Vendor Confirmation Number" value={vendor_confirmation_number} />}
            Days in Stage:&nbsp;
            <span className="searchable_field">
              {days}
            </span>
            <br />
            {end_date && <GsgSpan name="End Ship Date" value={end_date} />}
            {/* {cancellation_date && <GsgSpan name="Cancel Date" value={cancellation_date}/>} */}
            {ordered_date && <GsgSpan name="Ordered Date" value={ordered_date} />}
            {expected_ship_date && <GsgSpan name="Expected Ship Date" value={expected_ship_date} />}
            {expected_delivery_date && <GsgSpan name="Expected Delivery Date" value={expected_delivery_date} />}
            {delivered_date && <GsgSpan name="Received Date" value={delivered_date} />}
            <div className="row m-0 d_item_categories">
              {stock_order_categories_list.map((scat) => (
                <div key={scat.id} className="searchable_field p-0 mb-2 me-2" style={{ width: "auto" }}>
                  <span className="container_name_category">
                    {scat.name}: {scat.qty}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="row m-0 alerts">
            <div className="pe-0 text-end">
              {alerts.map((a, index) => (
                (a != "Not Finalized")
                  ?
                  <span key={"alert_" + index} className={"white tooltip_delivered basic_label_container mx-1 mb-1 " + a.replace(/ /g, "_")}>
                    {a}
                  </span>
                  :
                  <OverlayTrigger
                    key="alerts"
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-alerts`}>
                        {!vendor_invoice_received && <p>No Vendor Invoice Received</p>}
                        {!received_into_inventory && <p>No Received Into Inventory</p>}
                        {!invoice_processed && <p>No Invoice Processed</p>}
                      </Tooltip>
                    }
                  >
                    <span key={"alert_" + index} className={"white tooltip_delivered basic_label_container mx-1 mb-1 " + a.replace(/ /g, "_")}>
                      {a}
                    </span>
                  </OverlayTrigger>
              ))}
            </div>
          </div>
          <hr className="add-margin-top-5 add-margin-bottom-5"></hr>
          <div className="row add-line-height-25">
            {!deleted && <div className="col order-0">
              <div className="dropdown">
                <button className="btn dropdown-toggle lh-1 fs-5" aria-expanded="false" data-bs-toggle="dropdown" type="button" id={"dropdownContainerItemMenu_" + id} />
                <ul className="dropdown-menu" aria-labelledby={"dropdownContainerItemMenu_" + id}>
                  {!this.props.view_only_stock_orders && <li className="dropdown-item">
                    <a className="color_black dropdown-item" onClick={this.edit} id={id}>
                      <i className="fa fa-pencil" />
                      <span className="border-bottom">
                        Edit
                      </span>
                    </a>
                  </li>}
                  {this.props.view_only_stock_orders && <li className="dropdown-item">
                    <a className="color_black dropdown-item" onClick={this.edit} id={id}>
                      <i className="fa fa-eye" />
                      <span className="border-bottom">
                        View
                      </span>
                    </a>
                  </li>}
                  <li className="dropdown-item">
                    <a className='color_black dropdown-item' href={print_po_url} data-method="get" target='_blank'>
                      <i className='icon-print-shaft-labels' />
                      <span className="border-bottom">Print Purchase Order</span>
                    </a>
                  </li>
                  {/* %li.dropdown-item
                  %a.color_black{href: new_customer_order_line_path(customer_id: line.customer_id, :similar_order_line_id => line.id, :new_similar_special_order => true), "data-method" => "get", "data-remote" => "true", id: "create_similar_#{line.id}"}
                    %i.fa.fa-refresh
                    %span
                      Create Similar Order */}
                  <li className="dropdown-item">
                    <a className='color_black dropdown-item' onClick={this.createSimilarOrder} id={id}>
                      <i className='fa fa-refresh' />
                      <span className="border-bottom">Create Similar Order</span>
                    </a>
                  </li>
                  {!vendor_cancel_date && !this.props.view_only_stock_orders && <li className="dropdown-item">
                    <a className="color_black dropdown-item" onClick={this.openSplitStockOrder} id={id}>
                      <i><MdCallSplit /></i>
                      <span className="border-bottom">
                        Split
                      </span>
                    </a>
                  </li>}
                  {!this.props.view_only_stock_orders && <li className="dropdown-item">
                    <a className="color_red dropdown-item" onClick={this.delete} id={id}>
                      <i className="fa fa-trash" />
                      <span className="border-bottom">
                        Delete
                      </span>
                    </a>
                  </li>}
                  {this.props.openCancelStockOrder && !this.props.view_only_stock_orders && !vendor_cancel_date && <li className="dropdown-item">
                    <a className="color_red dropdown-item" onClick={this.openCancelStockOrder} id={id}>
                      <i><FcCancel /></i>
                      <span className="border-bottom">
                        Cancel Order/Items
                      </span>
                    </a>
                  </li>}
                </ul>
              </div>
            </div>}
            {!deleted && !vendor_cancel_date && (!this.props.view_only_stock_orders || ship_details_name == "Shipped") && <div className={((position == "orders_placed") && (ship_details_name == "Shipped")) ? "text-center col order-2 " : "text-start col order-2 "} >
              {position == "orders_placed" && <a className="next link p-0" onClick={this.openShipDetailsStockOrder} id={id}>
                {ship_details_name}
              </a>}
            </div>}
            {!deleted && !vendor_cancel_date && <div className="col order-3 text-end ps-0">
              {!this.props.view_only_stock_orders && (this.props.can_place_stock_orders || next_action_name != "Place Stock Order") && <a className="next color_black" onClick={this.nextAction} id={id}>
                {next_action_name}
                <i className="fa fa-step-forward p-1" />
              </a>}
            </div>}
            {deleted && !this.props.view_only_stock_orders && <div className="col order-3 text-end">
              <a className="next color_green" onClick={this.restoreStockOrder} id={id}>
                Restore
                <i className="fa fa-undo p-1" />
              </a>
            </div>}
          </div>
        </div>
      </div>
    )
  }
}

export default PipedriveItem
