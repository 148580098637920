import React, {useState,useEffect, useRef} from 'react';
import {Form, Spinner} from 'react-bootstrap';

function UploadImage(props){
  const {
    customer_id,
    files_type,
    object_id,
    object_type,

    uploadFile,
  } = props;

  const [files, setSelectedFiles] = useState();
  const [isSelected, setIsSelected] = useState(false);

  const changeHandler = (event) => {
    setSelectedFiles(event.target.files);
    setIsSelected(true);
  };
  const myInput = useRef(null);

  const clickElement = () => {
    // To simulate a user focusing an input you should use the
    // built in .focus() method.
    myInput.current?.click();
  }

  useEffect(() => {
    if(isSelected)
      handleSubmission()
  });

  const handleSubmission = () => {
    submitFiles(files)
  };

  const submitFiles = (attachments) => {
    const formData = new FormData();
    Array.from(attachments).forEach((file, index) => formData.append(`file_${index}`, file))

    formData.append('id', object_id);
    formData.append('customer_id', customer_id);
    formData.append('type_id', object_id);
    formData.append('type', object_type);
    uploadFile(formData)
    setSelectedFiles(null)
    setIsSelected(false)
    document.getElementById('files_id').value= null;
  }


  const handleDrop = (event) => {
    event.preventDefault();
    if (event.dataTransfer.files.length > 0) {
      submitFiles(event.dataTransfer.files)
    }
  };

  return (
    <div className={'col position-relative'}
         onClick={clickElement}
         onDragOver={(event) => event.preventDefault()}
         onDrop={handleDrop}
    >
      <div id={'file_zone'}>
        <div className="text-center" id={'file_zone_text'}>
          <div className="fs-5 text-center">Drop files here or click to upload</div>
          <i className="text-center fa fa-upload color_orange font_size_larger"></i>
        </div>

        <div className="text-center w-100 d-none" id="upload_spinner">
          <p className="text-center">Uploading files...</p>
          <Spinner className="" animation="border" variant="warning"
                   style={{width: "3rem", height: "3rem"}}/>
        </div>
        <Form.Group className="mb-3" controlId="formUploadAttch">
          <input className="d-none" type="file" name="file" onChange={changeHandler} id="files_id" ref={myInput}
                 multiple={true}
                 accept={files_type === 'images' ? ".jpg,.jpeg,.png,.webp,.gif" : ".jpg,.jpeg,.png,.webp,.gif,.pdf,.doc,.docx,.xls,.xlsx"}/>
        </Form.Group>
      </div>
    </div>

)
}

export default UploadImage