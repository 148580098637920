import React, {useState} from 'react'
import Select from "react-select";
import {Col, Form, OverlayTrigger, Row, Tooltip} from "react-bootstrap";

function GuestDropdown(props) {
  const {
    guests, field, guest_id, disabled,

    inputChange, createGuest,
  } = props;

  const initialState = {
    create_section_is_displayed: false, first_name: '', last_name: '', email: '', phone: '', keep_in_roster: false,
  }
  const [state, setState] = useState(initialState);

  const {
    create_section_is_displayed, first_name, last_name, email, phone, keep_in_roster
  } = state
  const handleClickNewGuest = (event) => {
    setState({...state, create_section_is_displayed: true});
  }

  const handleInputChange = (name, value) => setState((prev) => ({...prev, [name]: value}));

  const handleClose = (event) => setState(initialState);

  const handleSaveGuest = (e) => {
    e.preventDefault();
    const validationErrors = validateFields();

    if (Object.keys(validationErrors).length > 0) {
      Object.values(validationErrors).forEach((message, index) => window.gems.toast_message.init(message, "warning"))
    } else {
      console.log("Guest submitted successfully", state);
      createGuest({
        first_name: first_name, last_name: last_name, email: email, cell_phone: phone, keep_in_roster: keep_in_roster
      }, field);
      handleClose()
    }
  }

  const validateFields = () => {
    const rules = [{
      condition: !first_name, field: "first_name", message: "First Name is required",
    }, {
      condition: !last_name, field: "last_name", message: "Last Name is required",
    }, {
      condition: !email, field: "email", message: "Email is required",
    },];

    return rules.reduce((errors, {condition, field, message}) => {
      if (condition) {
        errors[field] = message;
      }
      return errors;
    }, {});
  };


  return (<>
    <Select id={field}
            isDisabled={disabled}
            name={field}
            value={guests.filter(function (option) {
              return option.value === guest_id;
            })}
            options={guests}
            onChange={(e) => inputChange(field, e.value)}/>
    {!create_section_is_displayed && !disabled && <>
      <label onClick={handleClickNewGuest} className='mt-2 link'>
        Add new Guest
      </label>
    </>}

    {create_section_is_displayed && <>
      <Row className='mx-0 my-2 py-2 shop_user_container border rounded'>
        <Col style={{gap: "8px"}} className='d-flex flex-column'>
          <Form.Group as={Row} className="" controlId="formGuestCustomerFirstName">
            <Form.Label column sm="4" className="col-form-label col-form-label-sm">
              First Name
            </Form.Label>
            <Col sm="8">
              <Form.Control type="text"
                            name='first_name'
                            value={first_name}
                            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="" controlId="formGuestCustomerLastName">
            <Form.Label column sm="4" className="col-form-label col-form-label-sm">
              Last Name
            </Form.Label>
            <Col sm="8">
              <Form.Control type="text"
                            name='last_name'
                            value={last_name}
                            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="" controlId="formGuestCustomerEmail">
            <Form.Label column sm="4" className="col-form-label col-form-label-sm">
              Email
            </Form.Label>
            <Col sm="8">
              <Form.Control type="text"
                            name='email'
                            value={email}
                            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="" controlId="formGuestCustomerPhone">
            <Form.Label column sm="4" className="col-form-label col-form-label-sm">
              Cell Phone
            </Form.Label>
            <Col sm="8">
              <Form.Control type="text"
                            name='phone'
                            value={phone}
                            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
              />
            </Col>
          </Form.Group>

          <div className='col d-flex align-items-center'>
            <Form.Check
              name="guest_keep_in_roster"
              value={keep_in_roster}
              defaultChecked={keep_in_roster}
              type='checkbox'
              id="guest_keep_in_roster"
              label="Add to Guest Roster"
              className="p-0 me-2 d-flex align-items-center"
              onChange={(e) => handleInputChange('keep_in_roster', e.target.checked)}
            />
            <OverlayTrigger
              key="guest_keep_in_roster"
              placement="top"
              overlay={
                <Tooltip id={`tooltip-guest_keep_in_roster`}>
                  Please provide a valid first name, last name and email before adding guest to the roster
                </Tooltip>
              }
            >
              <i className=" align-middle gga-info-circle tooltip_info"/>
            </OverlayTrigger>

          </div>

          <Row className='mx-0'>
            <Col className='py-2 border-top'>
              <button type={'button'} className={'btn gems_custom_button float-start me-2'}
                      onClick={handleClose}>Close
              </button>
              <button type={'button'} className={'btn gems_custom_button gems_orange_button float-end me-2'}
                      onClick={handleSaveGuest}>Save
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>}
  </>)
}

export default GuestDropdown
