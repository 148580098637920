import { connect } from 'react-redux'
import DashboardAdvancedFilter from '../components/DashboardAdvancedFilter'
import {changeQuickSearchValue, getMembersDropdownData, search} from '../actions'


const mapStateToProps = (state) => ({
  quick_search_value: state.dashboard.uiState.searchTerm || null,
  search_params: state.dashboard.uiState.advancedFilters,
  categories: state.dashboard.cachedData.categories,
  date_format: state.dashboard.meta.settings.date_format,

  members: state.dashboard.cachedData.members,
  employees: state.dashboard.cachedData.employees,
  guests: state.dashboard.cachedData.guests,
})

const mapDispatchToProps = (dispatch) => ({
  changeQuickSearchValue: value => dispatch(changeQuickSearchValue(value)),
  search: value => dispatch(search(value)),
  getMembersDropdownData: data => dispatch(getMembersDropdownData(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardAdvancedFilter)