import {connect} from 'react-redux'
import Dashboard from '../components/Dashboard'
import {newFoundItem, newLostItem} from '../actions'

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
