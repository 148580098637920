import React, {useState} from 'react';
import Modal, {ModalBody, ModalFooter, ModalHeader} from '../Modal';
import {Form} from 'react-bootstrap';
import Summary from "../Summary";
import moment from 'moment';
import {validateFields} from "../../actions";
import FormBuilder from "../FormBuilder";
import {NotificationTypes} from "../../../../shared/components/notifications/constants";
import {EditStates, initialEntry} from "../constants";
import _ from "lodash";


function ManageItemModal(props) {

  const {
    customer_id,
    date_format,
    members_dropdown_list,
    employees_dropdown_list,
    guests_dropdown_list,
    managers_dropdown_list,
    categories_dropdown_list,
    current_locations_dropdown_list,

    manageItemIsOpen,
    entry,
    notification_communication_data,
    selectedItemAssociationIds,

    closeModal,
    createItem,
    updateItem,
    createEmployee,
    createGuest,
    updateNotifications,
    updateContactInfo,
    changeNotificationSendOption,
    getOverrideNotification,
    uploadFile,
    removeFile,
  } = props;

  const initialState = {
    ...props.item.attributes,
    id: props.item.id,
    entered_by: props.item?.relationships?.entered_by?.data?.id,
    category_id: props.item?.relationships?.category?.data?.id,
    current_location_id: props.item?.relationships?.current_location?.data?.id,
    lost_date: entry === 'lost' ? moment() : props.item.attributes.lost_date,
    found_date: entry === 'found' ? moment() : props.item.attributes.found_date,
    lost_by_entity_type: entry === 'edit' ? props.item?.attributes?.lost_by_entity_type : (entry === 'lost' ? 'Member' : null),
    found_by_entity_type: entry === 'edit' ? props.item?.attributes?.lost_by_entity_type : (entry === 'found' ? 'Member' : null),
    internal_notes: props.item.attributes?.internal_notes,
  };

  const [state, setState] = useState(initialState);

  const required_fields = [
    ...entry === 'lost' ? ['lost_date', 'lost_by_entity_id'] : [],
    ...entry === 'found' ? ['found_date', 'found_by_entity_id'] : [],
    ...entry === 'edit' && props.item.attributes.status.includes('LOST') ? ['lost_date', 'lost_by_entity_id'] : [],
    ...entry === 'edit' && props.item.attributes.status.includes('FOUND') ? ['found_date', 'found_by_entity_id'] : [],
    'lfin',
  ]

  const fieldsOrderedList = ['lost_date', 'lost_by_entity_id', 'found_date', 'found_by_entity_id', 'lfin', 'category', 'description', 'found_location', 'current_location', 'claimed_date', 'claimed_by_entity_id', 'pickup_date']

  const fields_list = [
    ...(entry === 'lost' || (entry === 'edit' && props.item.attributes.status.includes('LOST')) ? ['lost_date', 'lost_by_entity_id'] : []),
    ...(entry === 'found' || (entry === 'edit' && props.item.attributes.status.includes('FOUND')) ? ['found_date', 'found_by_entity_id'] : []),
    'description',
    'lfin',
    'category',
    ...(entry === 'found' || entry === 'edit' && props.item.attributes.status.includes('FOUND') ? ['found_location', 'current_location'] : []),
    ...(entry === 'edit' && props.item.attributes.status.includes('FOUND_CLAIMED')) ? ['claimed_date', 'claimed_by_entity_id', ...(props.item.attributes.lost_date ? ['lost_date', 'lost_by_entity_id'] : [])] : [],
    ...(entry === 'edit' && props.item.attributes.status.includes('FOUND_RETURNED_TO_OWNER') ? ['pickup_date', 'claimed_date', 'claimed_by_entity_id', ...(props.item.attributes.lost_date ? ['lost_date', 'lost_by_entity_id'] : [])] : []),
  ].sort((a, b) => fieldsOrderedList.indexOf(a) - fieldsOrderedList.indexOf(b))

  const handleClose = () => closeModal();

  const handleInputChange = (name, value) => {
    let editState = EditStates(initialEntry(state.lost_date))[state.status]
    if (entry === 'edit' && editState.autoFill.hasOwnProperty(name)) {
      setState((prev) => ({...prev, [name]: value, [editState.autoFill[name]]: value}))
    } else
      setState((prev) => ({...prev, [name]: value}))
  }

  const handleCustomerTypeChange = (field, value) => {
    let editState = EditStates(initialEntry(state.lost_date))[state.status]
    if (entry === 'edit' && editState.autoFill.hasOwnProperty(`${field}_entity_type`)) {
      setState((prev) => ({
        ...prev,
        [`${field}_entity_type`]: value,
        [`${field}_entity_id`]: null,
        [editState.autoFill[`${field}_entity_type`]]: value,
        [editState.autoFill[`${field}_entity_id`]]: null,
      }));
    }
    else
      setState((prev) => ({...prev, [`${field}_entity_type`]: value, [`${field}_entity_id`]: null}));

  }

  const handleDateChange = (field, date) => {
    const allowedFields = EditStates(initialEntry(state.lost_date))[state.status]?.editable || [];
    if (!allowedFields.includes(field)) return;

    handleInputChange(field, date);
  }


  const handleCreateEmployee = (fields, entity_name) => {
    createEmployee(fields, entity_name).then((response => {
      setState({...state, [entity_name]: response})

      handleUpdateNotifications({type: NotificationTypes[entity_name], customer: {type: 'employee', id: response}})
    }))
  }

  const handleCreateGuest = (fields, entity_name) => {
    createGuest(fields, entity_name).then((response => {
      setState({...state, [entity_name]: response})

      handleUpdateNotifications({type: NotificationTypes[entity_name], customer: {type: 'guest', id: response}})
    }))


  }

  const handleUpdateNotifications = (notification) => {
    if (notification.customer.id)
      updateNotifications([notification])
  }

  const handleGetOverrideNotification = (channel, template_type) => {
    getOverrideNotification(state, state.lost_by_entity_id, channel, template_type)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateFields(state, required_fields, entry === 'edit');

    if (Object.keys(validationErrors).length > 0) {
      Object.values(validationErrors).forEach((message, index) => window.gems.toast_message.init(message, "warning"))
    } else {
      console.log("Form submitted successfully", state);
      if (entry === 'edit') {
        const keysToReject = ["lost_by", "claimed_by", "found_by", "displayed_customer", "entered_by", "days"];

        // Create a new object rejecting the selected keys
        const selectedState = Object.keys(state)
          .filter(key => !keysToReject.includes(key))  // Filter the keys you want
          .reduce((obj, key) => {
            obj[key] = state[key];  // Add the selected key-value pairs to the new object
            return obj;
          }, {});

        updateItem(selectedState);
      } else {
        const keysToReject = ["lost_by", "claimed_by", "found_by", "displayed_customer", "days"];

        // Create a new object rejecting the selected keys
        const selectedState = Object.keys(state)
          .filter(key => !keysToReject.includes(key))  // Filter the keys you want
          .reduce((obj, key) => {
            obj[key] = state[key];  // Add the selected key-value pairs to the new object
            return obj;
          }, {});
        createItem(selectedState);
      }

    }
  };


  const title = entry === "lost" ? "NEW LOST ITEM" : entry === "found" ? "NEW FOUND ITEM" : "EDIT ITEM";
  const submitButtonName = entry === "lost" || entry === "found" ? "Create" : "Update";

  return (<div className="modal_lost_item_container">
    <Modal isOpen={manageItemIsOpen} scrollable={true} className="lostItemModal min-height-body-modal"
           dialogClassName="modal-dialog-centered">
      <ModalHeader>
        <div className="row">
          <div className="col order-1">
            <h4 className="modal-title form_new_edit no-margin">
              <span>{title} </span>
              {entry === 'edit' &&
                <>
                  {props.item?.attributes.lost_by_entity_id ?
                    <span>
                     - #{props.item.attributes.lfin} - Lost By: {props.item.attributes.lost_by.full_name}
                    </span> :
                    <span>
                     - #{props.item.attributes.lfin} - Found By: {props.item.attributes.found_by.full_name}
                    </span>
                  }
                </>
              }

            </h4>
          </div>
        </div>
      </ModalHeader>
      <ModalBody style={{display: 'flex', padding: '0'}}>
        <div className="col overflow-auto pe-3 ps-1 pt-2">
          <FormBuilder
            form_action={entry}
            fields_list={fields_list}
            item={state}
            members={members_dropdown_list}
            employees={employees_dropdown_list}
            guests={guests_dropdown_list}
            managers={managers_dropdown_list}
            categories={categories_dropdown_list}
            current_locations={current_locations_dropdown_list}
            date_format={date_format}

            handleCustomerTypeChange={handleCustomerTypeChange}
            handleInputChange={handleInputChange}
            handleCreateEmployee={handleCreateEmployee}
            handleCreateGuest={handleCreateGuest}
            handleDateChange={handleDateChange}
            handleUpdateNotifications={handleUpdateNotifications}
            getMembersDropdownData={props.getMembersDropdownData}
          />


        </div>
        <div className="col background_grey p-2">
          <Summary customer_id={customer_id}
                   item={state}
                   internal_notes={state.internal_notes}
                   selectedItemAssociationIds={selectedItemAssociationIds}
                   activity_logs={props.activity_logs}
                   attachments={props.attachments}
                   email_delivery_reports={props.email_delivery_reports}
                   isRestricted={false}
                   action={entry}
                   notification_communication_data={notification_communication_data}
                   handleInputChange={handleInputChange}
                   updateContactInfo={updateContactInfo}
                   changeNotificationSendOption={changeNotificationSendOption}
                   getOverrideNotification={handleGetOverrideNotification}
                   uploadFile={uploadFile}
                   removeFile={removeFile}
          />
        </div>

      </ModalBody>
      <ModalFooter>
        <button
          className="float-start btn gems_custom_button"
          aria-label="Close"
          onClick={handleClose}
        >
          Close
        </button>

        <button
          className={`btn gems_custom_button gems_orange_button float-end`}
          onClick={(event) => handleSubmit(event)}
        >
          {submitButtonName}
        </button>
      </ModalFooter>
    </Modal>
  </div>);
}

export default ManageItemModal