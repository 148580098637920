import React from 'react';
import { Spinner} from 'react-bootstrap';

function AccountHome(props) {
  return (
    <div className='row pt-3 pb-3 pe-3 text-center'  >
      <div className='col-2' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <h5 className='text-start mx-3 mb-0'>ACCOUNT</h5>
      </div>
        {props.isLoading 
          ? 
          <div className='spinner_class'>
            <Spinner className="d-block" animation="border" variant="warning" id="loading_spinner"/>
          </div>
          :
          props.data.map((ac) => (
          <>
            <div className='col body_blue_component me-2 ' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <a className='row text-center body_blue_component' href={ac["url"]} data-method="get" style={{position: "relative"}} target="_blank">
                <h5 className='mt-2'><b>{ac["value"]} {ac["name"]}</b></h5>
              </a>
            </div>
          </>))}
      </div>
    )}
export default AccountHome
