import React from 'react';
import {Col, Form, Row, Spinner, Tab, Table, Tabs} from 'react-bootstrap';
import NotificationsTab from "../../../shared/components/notifications/NotificationsTab";
import ImagesTab from "../../../shared/components/attachments/ImagesTab";
import ActivityLogsTable from "../../../shared/components/ActivityLogsTable";

function Summary(props) {

  const {
    customer_id,
    action,
    item,
    notification_communication_data,

    internal_notes,
    activity_logs,
    attachments,
    email_delivery_reports,

    updateContactInfo,
    changeNotificationSendOption,
    getOverrideNotification,
    uploadFile,
    removeFile,
  } = props;

  return (<Row className={'h-100 summary_container m-0'}>
    <div className="col d-flex flex-column h-100">
      <Tabs
        defaultActiveKey={(action === 'lost' || action === 'mark_as_found') ? "notif" : "attachments"}
        transition={false}
        id="summary_tabs"
      >

        {(action === 'lost' || action === 'mark_as_found') && <Tab eventKey="notif" title="Notifications"
                                                                   className="p-2">
          <NotificationsTab
            customers_notifications={notification_communication_data}
            customer_id={customer_id}
            updateContactInfo={updateContactInfo}
            changeNotificationSendOption={changeNotificationSendOption}
            getOverrideNotification={getOverrideNotification}
          />
        </Tab>}

        <Tab eventKey="attachments" title="Files"
             className="p-2">
          <ImagesTab
            customer_id={customer_id}
            attachments={attachments}
            files_type={'images'}
            object_id={item.id || 'new'}
            object_type={'lost_and_found'}


            uploadFile={uploadFile}
            removeFile={removeFile}
          />
        </Tab>


        {!['lost', 'found'].includes(action) && <Tab eventKey="activity_log" title="Activity Log"
                                                     className="p-2">
          <Col className="h-100 table-responsive activity_log_content tableFixHead">
            <ActivityLogsTable
              activity_logs={activity_logs}
              email_delivery_reports={email_delivery_reports}
            />
          </Col>
        </Tab>}
      </Tabs>
      <Form.Group className="mb-2 mt-2" controlId="formInternalNotes">
        <Form.Label><b>INTERNAL NOTES:</b></Form.Label>
        <Form.Control as="textarea" rows={3}
                      className={props.isRestricted ? "restricted-container" : ""}
                      name='internal_notes'
                      value={internal_notes || ""}
                      onChange={(e) => props.handleInputChange("internal_notes", e.target.value)}/>
      </Form.Group>
    </div>
  </Row>)
}

export default Summary