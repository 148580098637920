import React, {useState} from 'react';
import Modal, {ModalBody, ModalFooter, ModalHeader} from '../Modal';
import {Col, Form, Row} from 'react-bootstrap';
import Summary from "../Summary";
import moment from 'moment';
import {validateFields} from "../../actions";
import FormBuilder from "../FormBuilder";


function ClaimItemModal(props) {

  const initialState = {
    claimed_date: moment(),
    claimed_by_entity_type: 'Member',
    internal_notes: props.item.attributes?.internal_notes,
    item: props.item,

  };

  const [state, setState] = useState(initialState);

  const {
    item,
    claimed_date,
    claimed_by_entity_type,
    claimed_by_entity_id,
    internal_notes,
  } = state;

  const {
    date_format,
    customer_id,

    members_dropdown_list,
    guests_dropdown_list,
    employees_dropdown_list,
    managers_dropdown_list,

    claimItemIsOpen,

    closeModal,
    transitionAction,
    createEmployee,
    createGuest,
    uploadFile,
    removeFile,
  } = props;

  const required_fields = ['claimed_date', 'claimed_by_entity_id'];

  const fields_list = ['claimed_date', 'claimed_by_entity_id'];

  const handleClose = () => closeModal();

  const handleInputChange = (name, value) => setState((prev) => ({...prev, [name]: value}));
  const handleCustomerTypeChange = (field, value) => {
    setState((prev) => ({...prev, [`${field}_entity_type`]: value, [`${field}_entity_id`]: null}));
  }

  const handleDateChange = (name, date) => handleInputChange(name, date);


  const handleCreateEmployee = (fields, entity_name) => {
    createEmployee(fields, entity_name).then((response => {
      setState({...state, [entity_name]: response})
    }))
  }

  const handleCreateGuest = (fields, entity_name) => {
    createGuest(fields, entity_name).then((response => {
      setState({...state, [entity_name]: response})
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateFields(state, required_fields);

    if (Object.keys(validationErrors).length > 0) {
      Object.values(validationErrors).forEach((message, index) => window.gems.toast_message.init(message, "warning"))
    } else {
      console.log("Form submitted successfully", state);
      transitionAction(item.id, 'claim_item', {
        claimed_date: claimed_date,
        claimed_by_entity_type: claimed_by_entity_type,
        claimed_by_entity_id: claimed_by_entity_id,
        internal_notes: internal_notes
      })
    }
  };

  const title = 'CLAIM ITEM';
  const submitButtonName = 'Save';

  return (<div className="modal_claim_item_container">
    <Modal isOpen={claimItemIsOpen} scrollable={true} className="claimItemModal min-height-body-modal"
           dialogClassName="modal-dialog-centered">
      <ModalHeader>
        <div className="row">
          <div className="col">
            <h4 className="modal-title form_new_edit no-margin">
              <span>{title} </span>
              {props.item.attributes?.found_by && <span>
                 - #{props.item.attributes?.lfin} - Found By: {props.item.attributes?.found_by.full_name}
              </span>}
            </h4>
          </div>
        </div>
      </ModalHeader>
      <ModalBody  style={{display: 'flex', padding: '0'}}>
        <div className="col overflow-auto pe-3 ps-1 pt-2">
          <Row>
            <Col className='color_orange mb-4'>
              <div className='text-center icon-item-returned-replaced font_size_50'></div>
              <div className='text-center'>
                <label className='bold'>
                  The item has been claimed
                </label>
              </div>
            </Col>
          </Row>

          <FormBuilder
            form_action={'claim_item'}
            fields_list={fields_list}
            item={state}
            members={members_dropdown_list}
            employees={employees_dropdown_list}
            guests={guests_dropdown_list}
            managers={managers_dropdown_list}
            date_format={date_format}

            handleCustomerTypeChange={handleCustomerTypeChange}
            handleInputChange={handleInputChange}
            handleCreateEmployee={handleCreateEmployee}
            handleCreateGuest={handleCreateGuest}
            handleDateChange={handleDateChange}
            getMembersDropdownData={props.getMembersDropdownData}
          />


        </div>
        <div className="col background_grey p-2">
          <Summary customer_id={customer_id}
                   item={item}
                   internal_notes={internal_notes}
                   selectedItemAssociationIds={props.selectedItemAssociationIds}
                   activity_logs={props.activity_logs}
                   attachments={props.attachments}
                   email_delivery_reports={props.email_delivery_reports}
                   isRestricted={true}
                   action={'claim_item'}
                   notification_communication_data={{}}


                   handleInputChange={handleInputChange}
                   uploadFile={uploadFile}
                   removeFile={removeFile}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className="float-start btn gems_custom_button"
          aria-label="Close"
          onClick={handleClose}
        >
          Close
        </button>

        <button
          className={`btn gems_custom_button gems_orange_button float-end`}
          onClick={(event) => handleSubmit(event)}
        >
          {submitButtonName}
        </button>
      </ModalFooter>
    </Modal>
  </div>);
}

export default ClaimItemModal