import {callAPI, showErrorNotification} from 'shared/helpers'

export const ActionTypes = {
  NEW_LOCATION: 'NEW_LOCATION',
  EDIT_LOCATION: 'EDIT_LOCATION',
  CREATE_LOCATION: 'CREATE_LOCATION',
  SAVE_LOCATION: 'SAVE_LOCATION',
  CHANGE_LOCATION: 'CHANGE_LOCATION',
  CANCEL_LOCATION: 'CANCEL_LOCATION',
  REMOVE_LOCATION: 'REMOVE_LOCATION',
  NEW_CATEGORY: 'NEW_CATEGORY',
  CREATE_CATEGORY: 'CREATE_CATEGORY',
  EDIT_CATEGORY: 'EDIT_CATEGORY',
  SAVE_CATEGORY: 'SAVE_CATEGORY',
  CHANGE_CATEGORY: 'CHANGE_CATEGORY',
  CANCEL_CATEGORY: 'CANCEL_CATEGORY',
  REMOVE_CATEGORY: 'REMOVE_CATEGORY',
  SAVE_GENERAL_SETTINGS: 'SAVE_GENERAL_SETTINGS',
  UPDATE_LOCATIONS: 'UPDATE_LOCATIONS',
}


export const newLocation = () => (dispatch) => {
  dispatch({
    type: ActionTypes.NEW_LOCATION
  })
}

export const newCategory = () => (dispatch) => {
  dispatch({
    type: ActionTypes.NEW_CATEGORY
  })
}

export const changeCategoryField = (category, field, value) => (dispatch) => {
  dispatch({
    type: ActionTypes.CHANGE_CATEGORY,
    data: {
      category: category,
      field: field,
      value: value
    }
  })
}

export const changeLocationField = (location, field, value) => (dispatch) => {
  dispatch({
    type: ActionTypes.CHANGE_LOCATION,
    data: {
      location: location,
      field: field,
      value: value
    }
  })
}

export const cancelLocation = (location) => (dispatch) => {
  dispatch({
    type: ActionTypes.CANCEL_LOCATION,
    data: {
      location: location
    },
  })
}

export const cancelCategory = (category) => (dispatch) => {
  dispatch({
    type: ActionTypes.CANCEL_CATEGORY,
    data: {
      category: category
    },
  })
}

export const editLocation = (location_id) => (dispatch) => {
  dispatch({
    type: ActionTypes.EDIT_LOCATION,
    data: {
      location_id: location_id
    },
  })
}

export const editCategory = (category_id) => (dispatch) => {
  dispatch({
    type: ActionTypes.EDIT_CATEGORY,
    data: {
      category_id: category_id
    },
  })
}



export const createCategory = (category) => (dispatch, getState) => {
  const state = getState()
  const dataURL = `/api/web/customers/${state.settings.meta.general.customer_id}/lost_and_found/categories`
  if (category) {
    let data = {
      customer_id: state.settings.meta.general.customer_id,
      name: category.attributes.name,
    }

    callAPI(dataURL, 'POST', data, new Headers({'Accept': 'application/json', 'Content-type': 'application/json'}))
      .then(response => {
        if (response.errors)
          response.errors.forEach((message, index) => window.gems.toast_message.init(message, "error"))
        else {
          dispatch({
            type: ActionTypes.CREATE_CATEGORY,
            data: {
              new_category: response,
              old_category: category,
            },
          })
          window.gems.toast_message.init('Category has been created successfully!', "success")
        }
      })
      .catch(() => {
        showErrorNotification('An error was encountered. Could not create category!')
      })
  }
}

export const saveCategory = (category) => (dispatch, getState) => {
  const state = getState()
  const dataURL = `/api/web/customers/${state.settings.meta.general.customer_id}/lost_and_found/categories/${category.id}`
  if (category) {
    let data = {
      customer_id: state.settings.meta.general.customer_id,
      name: category.attributes?.edit_name,
    }

    callAPI(dataURL, 'PATCH', data, new Headers({'Accept': 'application/json', 'Content-type': 'application/json'}))
      .then(response => {
        if (response.errors)
          response.errors.forEach((message, index) => window.gems.toast_message.init(message, "error"))
        else {
          dispatch({
            type: ActionTypes.SAVE_CATEGORY,
            data: {
              category: response,
            },
          })
          window.gems.toast_message.init('Category has been updated successfully!', "success")
        }
      })
      .catch(() => {
        showErrorNotification('An error was encountered. Could not update category!')
      })
  }
}
export const createLocation = (location) => (dispatch, getState) => {
  const state = getState()
  const dataURL = `/api/web/customers/${state.settings.meta.general.customer_id}/lost_and_found/locations`
  if (location) {
    let data = {
      customer_id: state.settings.meta.general.customer_id,
      name: location.attributes.name,
      location_type: 'current',
    }

    callAPI(dataURL, 'POST', data, new Headers({'Accept': 'application/json', 'Content-type': 'application/json'}))
      .then(response => {
        if (response.errors)
          response.errors.forEach((message, index) => window.gems.toast_message.init(message, "error"))
        else {
          dispatch({
            type: ActionTypes.CREATE_LOCATION,
            data: {
              new_location: response,
              old_location: location,
            },
          })
          window.gems.toast_message.init('Current Location has been created successfully!', "success")
        }
      })
      .catch(() => {
        showErrorNotification('An error was encountered. Could not create location!')
      })
  }
}

export const saveLocation = (location) => (dispatch, getState) => {
  const state = getState()
  const dataURL = `/api/web/customers/${state.settings.meta.general.customer_id}/lost_and_found/locations/${location.id}`
  if (location) {
    let data = {
      customer_id: state.settings.meta.general.customer_id,
      name: location.attributes?.edit_name,
    }

    callAPI(dataURL, 'PATCH', data, new Headers({'Accept': 'application/json', 'Content-type': 'application/json'}))
      .then(response => {
        if (response.errors)
          response.errors.forEach((message, index) => window.gems.toast_message.init(message, "error"))
        else {
          dispatch({
            type: ActionTypes.SAVE_LOCATION,
            data: {
              location: response,
            },
          })
          window.gems.toast_message.init('Current Location has been updated successfully!', "success")
        }
      })
      .catch(() => {
        showErrorNotification('An error was encountered. Could not update location!')
      })
  }
}



export const removeCategory = (category) => (dispatch, getState) => {
  const state = getState()
  const dataURL = `/api/web/customers/${state.settings.meta.general.customer_id}/lost_and_found/categories/${category.id}`
  if (category) {
    let data = {
      customer_id: state.settings.meta.general.customer_id,
    }

    callAPI(dataURL, 'DELETE', data, new Headers({'Accept': 'application/json', 'Content-type': 'application/json'}))
      .then(response => {
        if (response.errors)
          response.errors.forEach((message, index) => window.gems.toast_message.init(message, "error"))
        else {
          dispatch({
            type: ActionTypes.REMOVE_CATEGORY,
            data: {
              category: category,
            },
          })
          window.gems.toast_message.init('Category has been removed successfully!', "success")
        }
      })
      .catch(() => {
        showErrorNotification('An error was encountered. Could not remove category!')
      })
  }
}

export const removeLocation = (location) => (dispatch, getState) => {
  const state = getState()
  const dataURL = `/api/web/customers/${state.settings.meta.general.customer_id}/lost_and_found/locations/${location.id}`
  if (location) {
    let data = {
      customer_id: state.settings.meta.general.customer_id,
    }

    callAPI(dataURL, 'DELETE', data, new Headers({'Accept': 'application/json', 'Content-type': 'application/json'}))
      .then(response => {
        if (response.errors)
          response.errors.forEach((message, index) => window.gems.toast_message.init(message, "error"))
        else {
          dispatch({
            type: ActionTypes.REMOVE_LOCATION,
            data: {
              location: location,
            },
          })
          window.gems.toast_message.init('Current Location has been removed successfully!', "success")
        }
      })
      .catch(() => {
        showErrorNotification('An error was encountered. Could not remove location!')
      })
  }
}


export const saveGeneralSettings = (settings) => (dispatch, getState) => {
  const state = getState()
  const dataURL = `/api/web/customers/${state.settings.meta.general.customer_id}/lost_and_found/settings/${state.settings.meta.general.id}`
  if (settings) {
    let data = {
      customer_id: state.settings.meta.general.customer_id,
      ...settings,
    }

    callAPI(dataURL, 'PATCH', data, new Headers({'Accept': 'application/json', 'Content-type': 'application/json'}))
      .then(response => {
        if (response.errors)
          response.errors.forEach((message, index) => window.gems.toast_message.init(message, "error"))
        else {
          window.location.href = response.redirect_to
          // dispatch({
          //   type: ActionTypes.SAVE_GENERAL_SETTINGS,
          //   data: {
          //     general: response.general,
          //   },
          // })
          window.gems.toast_message.init('Settings have been updated successfully!', "success")
        }
      })
      .catch(() => {
        showErrorNotification('An error was encountered. Could not update settings!')
      })
  }
}

export const updateLocationDefaultOption = (id, enabled) => (dispatch, getState) => {
  const state = getState()
  const dataURL = `/api/web/customers/${state.settings.meta.general.customer_id}/lost_and_found/locations/${id}/mark_as_default`
  if (id) {
    let data = {
      customer_id: state.settings.meta.general.customer_id,
      location_id: id,
      enabled: enabled
    }

    callAPI(dataURL, 'PATCH', data, new Headers({'Accept': 'application/json', 'Content-type': 'application/json'}))
      .then(response => {
        if (response.errors)
          response.errors.forEach((message, index) => window.gems.toast_message.init(message, "error"))
        else {
          dispatch({
            type: ActionTypes.UPDATE_LOCATIONS,
            data: response,
          })
          window.gems.toast_message.init('Location has been marked as default successfully!', "success")
        }
      })
      .catch(() => {
        showErrorNotification('An error was encountered. Could not mark location as default!')
      })
  }
}