import React, {useEffect} from 'react'

import rootReducer from '../../settings/reducers'
import SettingsContainer from "./SettingsContainer";
import configureStore from "../../../shared/configure_store";
import {GlobalProvider} from "../../../shared/global_context";
import {normalizeList} from "../../../shared/helpers";


const LostFoundSettingsRoot = ({data}) => {
  const state = {
    settings: {
      entities: {
        categories: normalizeList(data.categories),
        current_locations: normalizeList(data.current_locations),
      },
      meta: data.meta
    }
  }

  const store = configureStore(rootReducer, state)
  return (
    <GlobalProvider store={store}>
      <SettingsContainer/>
    </GlobalProvider>
  )
}

LostFoundSettingsRoot.propTypes = {}

export default LostFoundSettingsRoot
