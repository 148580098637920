import {ActionTypes} from '../actions'
import {ViewFilter} from "../components/constants";
import {getAssociationIds, normalizeList} from "../../../shared/helpers";

const {
  OPEN_LIST_VIEW_ADDITIONAL_FIELDS,
  UPDATE_LIST_VIEW_OPTIONAL_FIELDS,
  CLOSE_OPTIONAL_FIELDS_MODAL,
  UPDATE_LIST_VIEW_SORTING,
  CHANGE_VIEW_FILTER,
  NEW_LOST_ITEM,
  NEW_FOUND_ITEM,
  CLOSE_ITEM_MODAL,
  CREATE_ITEM,
  EDIT_ITEM,
  UPDATE_ITEM,
  CREATE_EMPLOYEE,
  CREATE_GUEST,
  TRANSITION,
  OPEN_CLAIM_ITEM_MODAL,
  CLOSE_CLAIM_ITEM_MODAL,
  OPEN_RETURN_TO_OWNER_ITEM_MODAL,
  CLOSE_RETURN_TO_OWNER_ITEM_MODAL,
  OPEN_MARK_AS_FOUND_ITEM_MODAL,
  CLOSE_MARK_AS_FOUND_ITEM_MODAL,
  UPDATE_NOTIFICATIONS,
  UPDATE_NOTIF_CONTACT_INFO,
  CHANGE_NOTIFICATION_SEND_OPTION,
  OPEN_NOTIFICATION_OVERRIDE_MODAL,
  CLOSE_NOTIFICATION_OVERRIDE_MODAL,
  UPDATE_NOTIFICATION_OVERRIDE,
  QUICK_SEARCH,
  SET_SEARCH_FILTERS,
  SEARCH_BACKEND,
  APPLY_FILES_TO_ITEM,
  REMOVE_FILE,
  SET_MEMBERS_DATA,
} = ActionTypes

const initialState = {
  entities: {
    items: {},       // Normalized items by ID
    categories: {},  // Normalized categories by ID
    locations: {},   // Normalized locations by ID
    customers: {},   // Normalized customers by ID
    attachments: {}, // Normalized attachments by ID
    activity_logs: {}, // Normalized attachments by ID
    email_delivery_reports: {}, // Normalized attachments by ID
  },
  meta: {
    totalItems: 0,
    page: 1,
    perPage: 10,
    settings: {}  // General settings about the page
  },
  cachedData: {
    employees: [],
    guests: [],
    managers: [],
    categories: [],
    current_locations: [],
  },
  uiState: {
    sortBy: [],
    listSort: {
      column: 'status',
      direction: 'asc'
    },
    searchTerm: "",
    advancedFilters: {},
    viewFilter: "LOST_AND_FOUND", // Enum-like value
    selectedItem: null,  // Store just the ID instead of full object
    selectedItemAssociationIds: {
      attachmentsIds: [],
      activityLogsIds: [],
      emailDeliveryReportsIds: [],
    },
    manageItemEntry: "",
    notificationData: {
      communicationData: [],
      overrideBody: null,
      type: null,
      objectId: null,
      customerId: null,
      channel: null
    },
    modals: {
      configureFieldsIsOpen: false,
      manageItemIsOpen: false,
      claimItemIsOpen: false,
      returnToOwnerItemIsOpen: false,
      markAsFoundItemIsOpen: false,
      notificationOverrideIsOpen: false
    }
  }
}

const dashboard = (state = initialState, action) => {

  let item = null;
  let items_list = null;
  let dup_notification_communication_data = [];
  let customer_notif = null;
  let dup_notifications = [];
  let dup_notif = null;
  let temp_attachments = {}
  let temp_items = {}
  let temp_item = {}



  switch (action.type) {
    case OPEN_LIST_VIEW_ADDITIONAL_FIELDS:
      return {
        ...state, meta: {
          ...state.meta,
          settings: {
            ...state.meta.settings,
            fields: action.data.fields,
            in_fields: action.data.in_fields,
            mandatory_fields: action.data.mandatory_fields,
          }
        }, uiState: {
          ...state.uiState, modals: {...state.uiState.modals, configureFieldsIsOpen: true},
        },
      }

    case UPDATE_LIST_VIEW_OPTIONAL_FIELDS:
      return {
        ...state,
        meta: {
          ...state.meta,
          settings: {
            ...state.meta.settings,
            fields: JSON.parse(action.data.fields),
            in_fields: JSON.parse(action.data.in_fields),
          },
        },
        uiState: {
          ...state.uiState, modals: {...state.uiState.modals, configureFieldsIsOpen: false},
        },
      }

    case CLOSE_OPTIONAL_FIELDS_MODAL:
      return {
        ...state, uiState: {
          ...state.uiState, modals: {...state.uiState.modals, configureFieldsIsOpen: false},
        },
      }

    case UPDATE_LIST_VIEW_SORTING:
      return {
        ...state, uiState: {
          ...state.uiState,
          listSort: {
            column: action.data.sort_column,
            direction: action.data.sort_direction
          }
        }
      }

    case CHANGE_VIEW_FILTER:
      return {
        ...state, uiState: {...state.uiState, viewFilter: action.data.view},
      }

    case NEW_LOST_ITEM:
      return manageItemParseResponse(state, action, 'lost')

    case NEW_FOUND_ITEM:
      return manageItemParseResponse(state, action, 'found')

    case EDIT_ITEM:
      return manageItemParseResponse(state, action, 'edit')
    
    case CREATE_ITEM:
      return updateItemState(state, action)

    case UPDATE_ITEM:
      return updateItemState(state, action)

    case TRANSITION:
      return updateItemState(state, action)

    case OPEN_MARK_AS_FOUND_ITEM_MODAL:
      return openTransitionModal(state, action, 'markAsFoundItemIsOpen')

    case OPEN_CLAIM_ITEM_MODAL:
      return openTransitionModal(state, action, 'claimItemIsOpen')

    case OPEN_RETURN_TO_OWNER_ITEM_MODAL:
      return openTransitionModal(state, action, 'returnToOwnerItemIsOpen')

    case CLOSE_ITEM_MODAL:
      return closeModals(state)
    
    case CLOSE_CLAIM_ITEM_MODAL:
      return closeModals(state)

    case CLOSE_RETURN_TO_OWNER_ITEM_MODAL:
      return closeModals(state)

    case CLOSE_MARK_AS_FOUND_ITEM_MODAL:
      return closeModals(state)

    case QUICK_SEARCH:
      return {
        ...state, uiState: {
          ...state.uiState, searchTerm: action.data.quick_search_value,
        },

      }

    case SET_SEARCH_FILTERS:
      return {
        ...state, uiState: {
          ...state.uiState, advancedFilters: action.data.filters,
        },
      }

    case SEARCH_BACKEND:
      return {
        ...state,
        uiState: {
          ...state.uiState,
          advancedFilters: action.data.filters,
        },
        entities: {
          ...state.entities,
          items: normalizeList(action.data.items),
          categories: normalizeList(action.data.categories),
          current_locations: normalizeList(action.data.locations),
          activity_logs: normalizeList(action.data.activity_logs),
          attachments: normalizeList(action.data.attachments),
          entered_by_managers: normalizeList(action.data.entered_by_managers),
          email_delivery_reports: normalizeList(action.data.email_delivery_reports),
        }

      }

    case CREATE_EMPLOYEE:
      item = state.uiState.selectedItem
      item.attributes[action.data.field] = action.data.employee.id
      return {
        ...state, uiState: {
          ...state.uiState, selectedItem: item,
        }, cachedData: {
          ...state.cachedData,
          employees: action.data.employees,
        }
      }

    case CREATE_GUEST:
      item = state.uiState.selectedItem
      item.attributes[action.data.field] = action.data.guest.id
      return {
        ...state, uiState: {
          ...state.uiState, selectedItem: item,

        }, cachedData: {
          ...state.cachedData,
          guests: action.data.guests,
        }
      }

    case UPDATE_NOTIFICATIONS:
      return {
        ...state, uiState: {
          ...state.uiState,
          notificationData: {
            ...state.uiState.notificationData,
            communicationData: action.data.notifications,
          }
        },

      }
    case OPEN_NOTIFICATION_OVERRIDE_MODAL:
      return {
        ...state, uiState: {
          ...state.uiState,
          modals: {...state.uiState.modals, notificationOverrideIsOpen: true},
          notificationData: {
            ...state.uiState.notificationData,
            overrideBody: action.data.body,
            type: action.data.notification_type,
            objectId: action.data.notification_object_id,
            customerId: action.data.notification_customer_id,
            channel: action.data.notification_channel,
          }
        },

      }
    case CLOSE_NOTIFICATION_OVERRIDE_MODAL:
      return {
        ...state, uiState: {
          ...state.uiState,
          modals: {...state.uiState.modals, notificationOverrideIsOpen: false},
          notificationData: {
            ...state.uiState.notificationData,
            overrideBody: null,
            type: null,
            objectId: null,
            customerId: null,
            channel: null
          }
        },

      }
    case UPDATE_NOTIF_CONTACT_INFO:
      customer_notif = {...[...state.uiState.notificationData.communicationData].filter(function (item) {
        return item.customer.id === action.data.customer.id
      })[0]}
      customer_notif.customer = action.data.customer

      dup_notification_communication_data = [...state.uiState.notificationData.communicationData].filter(function (item) {
        return item.customer.id !== action.data.customer.id
      })
      dup_notification_communication_data.push(customer_notif)

      return {
        ...state, uiState: {
          ...state.uiState, notificationData: {
            ...state.uiState.notificationData,
            communicationData: dup_notification_communication_data
          },
        },
      }
    case CHANGE_NOTIFICATION_SEND_OPTION:
      dup_notification_communication_data = [...state.uiState.notificationData.communicationData].filter(function (item) {
        return item.customer.id !== action.data.customer.id
      })
      customer_notif = {...[...state.uiState.notificationData.communicationData].filter(function (item) {
          return item.customer.id === action.data.customer.id
        })[0]}
      dup_notifications = [...customer_notif.notifications].filter(function (n) {
        return n.email.type !== action.data.notification_type
      })
      dup_notif = customer_notif.notifications.filter(function (n) {
        return n[action.data.channel].type === action.data.notification_type
      })[0]
      dup_notif[action.data.channel].enabled = action.data.is_enabled
      dup_notifications.push(dup_notif)
      customer_notif.notifications = dup_notifications
      dup_notification_communication_data.push(customer_notif)

      return {
        ...state, uiState: {
          ...state.uiState,
          notificationData: {
            ...state.uiState.notificationData,
            communicationData: dup_notification_communication_data
          },
        },

      }
    case UPDATE_NOTIFICATION_OVERRIDE:
      dup_notification_communication_data = [...state.uiState.notificationData.communicationData].filter(function (item) {
        return item.customer.id !== action.data.notification_customer_id
      })
      customer_notif = {...[...state.uiState.notificationData.communicationData].filter(function (item) {
          return item.customer.id === action.data.notification_customer_id
        })[0]}
      dup_notifications = [...customer_notif.notifications].filter(function (n) {
        return n.email.type !== action.data.notification_type
      })
      dup_notif = customer_notif.notifications.filter(function (n) {
        return n[action.data.channel].type === action.data.notification_type
      })[0]
      dup_notif[action.data.channel].is_overwritten = true
      dup_notif[action.data.channel].overwritten_body = action.data.body
      dup_notifications.push(dup_notif)
      customer_notif.notifications = dup_notifications
      dup_notification_communication_data.push(customer_notif)

      return {
        ...state, uiState: {
          ...state.uiState,
          notificationData: {
            ...state.uiState.notificationData,
            communicationData: dup_notification_communication_data
          },
        },

      }
    


    case APPLY_FILES_TO_ITEM:
      temp_attachments = {...state.entities.attachments, ...normalizeList(action.data)}
      temp_item = {...state.uiState.selectedItem}
      temp_item.relationships.attachments.data = [...temp_item.relationships.attachments.data, ...action.data.map((file) => ({ id: file.id, type: "attachments" }))]
      temp_items = {...state.entities.items}
      if (temp_item.id) {
        temp_items[temp_item.id] = temp_item
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          attachments: temp_attachments,
          items: temp_items,
        },
        uiState: {
          ...state.uiState,
          selectedItem: {
            ...state.uiState.selectedItem,
            relationships: {
              ...state.uiState.selectedItem.relationships,
              attachments: temp_item.relationships.attachments
            }
          },
          selectedItemAssociationIds: {
            ...state.uiState.selectedItemAssociationIds,
            attachmentsIds: getAssociationIds(temp_item.relationships?.attachments)
          }
        },
      }

    case REMOVE_FILE:
      temp_attachments = {...state.entities.attachments}
      delete temp_attachments[action.data.id]
      temp_items = {...state.entities.items}
      temp_item = {...state.uiState.selectedItem}
      temp_item.relationships.attachments.data = temp_item.relationships.attachments.data.filter((att) => att.id !== action.data.id)

      if (temp_item.id !== 'new') {
        temp_items[temp_item.id] = temp_item
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          attachments: temp_attachments,
          items: temp_items,
        },
        uiState: {
          ...state.uiState,
          selectedItem: {
            ...state.uiState.selectedItem,
            relationships: {
              ...state.uiState.selectedItem.relationships,
              attachments: temp_item.relationships.attachments
            }
          },
          selectedItemAssociationIds: {
            ...state.uiState.selectedItemAssociationIds,
            attachmentsIds: getAssociationIds(temp_item.relationships?.attachments)
          }
        },
      }
    case SET_MEMBERS_DATA:
      return {
        ...state,
        cachedData: {
          ...state.cachedData,
          members: action.data
        }
      }

    default:
      return {
        ...initialState, ...state,
      }
  }
}

const manageItemParseResponse = (state, action, entry) => {
  let temp_attachments = []
  let temp_activity_logs = []
  let temp_entered_by_managers = []
  let temp_categories = []
  let temp_current_locations = []
  let temp_selectedItemAssociationIds = []

  temp_attachments = normalizeList(action.data.included.attachments)
  temp_activity_logs = normalizeList(action.data.included.activity_logs)
  temp_categories = normalizeList(action.data.included.lost_and_found_categories)
  temp_current_locations = normalizeList(action.data.included.lost_and_found_locations)
  temp_entered_by_managers = normalizeList([action.data.included.entered_by.data])
  temp_selectedItemAssociationIds = {
    attachmentsIds: getAssociationIds(action.data.data?.relationships?.attachments),
    activityLogsIds: getAssociationIds(action.data.data?.relationships?.activity_logs),
    emailDeliveryReportsIds: getAssociationIds(action.data.data?.relationships?.email_delivery_reports)
  }

  return {
    ...state, uiState: {
      ...state.uiState,
      manageItemEntry: entry,
      selectedItem: action.data.data,
      selectedItemAssociationIds: temp_selectedItemAssociationIds,
      modals: {...state.uiState.modals, manageItemIsOpen: true},
    },
    entities: {
      ...state.entities,
      entered_by_managers: {...state.entities.entered_by_managers, ...temp_entered_by_managers},
      attachments: {...state.entities.attachments, ...temp_attachments},
      activity_logs: {...state.entities.activity_logs, ...temp_activity_logs},
      categories: {...state.entities.categories, ...temp_categories},
      current_locations: {...state.entities.current_locations, ...temp_current_locations},
    }
  }
}

const updateItemState = (state, action) => {
  let temp_attachments = []
  let temp_activity_logs = []
  let temp_entered_by_managers = []
  let temp_categories = []
  let temp_current_locations = []
  let temp_selectedItemAssociationIds = []
  let temp_item = action.data.data

  temp_attachments = normalizeList(action.data.included.attachments)
  temp_activity_logs = normalizeList(action.data.included.activity_logs)
  temp_categories = normalizeList(action.data.included.lost_and_found_categories)
  temp_current_locations = normalizeList(action.data.included.lost_and_found_locations)
  temp_entered_by_managers = normalizeList([action.data.included.entered_by.data])
  temp_selectedItemAssociationIds = {
    attachmentsIds: [],
    activityLogsIds: [],
    emailDeliveryReportsIds: []
  }

  return {
    ...state, uiState: {
      ...state.uiState,
      manageItemEntry: null,
      selectedItem: null,
      selectedItemAssociationIds: temp_selectedItemAssociationIds,
      modals: {...state.uiState.modals,
        manageItemIsOpen: false,
        claimItemIsOpen: false,
        markAsFoundItemIsOpen: false,
        returnToOwnerItemIsOpen: false,
        notificationOverrideIsOpen: false,
      },
    },
    entities: {
      ...state.entities,
      items: {...state.entities.items, ...normalizeList([temp_item])},
      entered_by_managers: {...state.entities.entered_by_managers, ...temp_entered_by_managers},
      attachments: {...state.entities.attachments, ...temp_attachments},
      activity_logs: {...state.entities.activity_logs, ...temp_activity_logs},
      categories: {...state.entities.categories, ...temp_categories},
      current_locations: {...state.entities.current_locations, ...temp_current_locations},
    }
  }
}

const openTransitionModal = (state, action, transition) => {
  let item = state.entities.items[action.data.id]
  
  return {
    ...state, uiState: {
      ...state.uiState, 
      selectedItem: item,
      selectedItemAssociationIds: {
        attachmentsIds: getAssociationIds(item.relationships?.attachments),
        activityLogsIds: getAssociationIds(item.relationships?.activity_logs),
        emailDeliveryReportsIds: getAssociationIds(item.relationships?.email_delivery_reports)
      },
      modals: {...state.uiState.modals, [transition]: true},
    },
  }
}

const closeModals = (state) => {
  return {
    ...state, uiState: {
      ...state.uiState,
      selectedItem: null,
      selectedItemAssociationIds: {
        attachmentsIds: [],
        activityLogsIds: [],
        emailDeliveryReportsIds: [],
      },
      notificationData: {
        communicationData: [],
        overrideBody: null,
        type: null,
        objectId: null,
        customerId: null,
        channel: null
      },
      modals: {
        configureFieldsIsOpen: false,
        manageItemIsOpen: false,
        claimItemIsOpen: false,
        returnToOwnerItemIsOpen: false,
        markAsFoundItemIsOpen: false,
        notificationOverrideIsOpen: false
      }
    }
  }
}


export default dashboard