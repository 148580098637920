import React from 'react'

import rootReducer from '../reducers'

import configureStore from 'shared/configure_store'
import {GlobalProvider} from 'shared/global_context'
import DashboardContainer from "./DashboardContainer";
import {normalizeList} from "../../../shared/helpers";



const LostFoundRoot = ({data}) => {
  const state = {
    dashboard: {
      entities: {
        items: normalizeList(data.items),
        categories: normalizeList(data.categories),
        current_locations: normalizeList(data.locations),
        activity_logs: normalizeList(data.activity_logs),
        attachments: normalizeList(data.attachments),
        entered_by_managers: normalizeList(data.entered_by_managers),
        email_delivery_reports: normalizeList(data.email_delivery_reports)
      },
      meta: data.meta,
      cachedData: data.cachedData,
    },
  }

  const store = configureStore(rootReducer, state)
  return (
    <GlobalProvider store={store}>
      <DashboardContainer/>
    </GlobalProvider>
  )
}

LostFoundRoot.propTypes = {}

export default LostFoundRoot
