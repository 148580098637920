import {connect} from 'react-redux'
import {getMembersDropdownData, newFoundItem, newLostItem} from '../actions'
import Footer from "../components/Footer";

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
  newLostItem: () => dispatch(newLostItem()),
  newFoundItem: () => dispatch(newFoundItem()),
  getMembersDropdownData: data => dispatch(getMembersDropdownData(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer)
