import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { NotificationManager } from 'react-notifications'
import queryString from 'query-string'
import confirm from './confirm'
import invert, { RGB, RgbArray, HexColor, BlackWhite } from 'invert-color';

/*
 * message: string or functional component
 * options: object with props to pass to the popup component (e.g. showCloseButton)
 */
export const onConfirm = (message, callback, options) => {
  confirm(message, callback, options)
}

export const showNotification = (message, duration = 5000) => {
  NotificationManager.success(message, '', duration)
}

export const showErrorNotification = (message, duration = 10000) => {
  NotificationManager.error(message, '', duration)
}

const _formatDate = (date, timeZone, format) => {
  if (date) {
    let formattedDate = moment(date)
    if (timeZone) {
      formattedDate = formattedDate.tz(timeZone)
    }
    return formattedDate.locale(window.I18n.locale).format(format)
  } else {
    return ''
  }
}

export const formatDate = (date, timeZone, format = 'MMM D, YYYY') => (
  _formatDate(date, timeZone, format)
)

export const formatDateTime = (date, timeZone, format = 'MMM DD, YYYY h:mm A') => (
  _formatDate(date, timeZone, format)
)

export const getTodayWithTimezone = (timeZone) => {
  return moment().tz(timeZone)
}


export const getComplementaryColor = (hex) => {
  // if ((rgb["r"]*0.299 + rgb["b"]*0.587 + rgb["b"]*0.114) / 255 > 0.5) {
  //   return "#424242"
  // }
  // else {
  //   return "#ffffff"
  // }
  return invert(hex, true)
}

let uniqueId = 1
export const getUniqueElementId = () => {
  const currentUniqueId = uniqueId
  uniqueId++
  return `element-id-${ currentUniqueId }`
}

/*
 * items: array or map of {
 *   id: ...
 *   [labelField]: ...
 * }
 */
export const mapToSelectArray = (items, { labelField = 'name', changeNone = true } = {}) => (
  _.values(items).map(item => ({
    value: ((item.id === 'none' && changeNone) ? '' : item.id),
    label: item[labelField],
  }))
)

/*
 * This method compares the content of 2 children arrays by doing a shallow 
 * comparison between corresponding children.
 * Useful in shouldComponentUpdate methods.
 */
export const areChildrenEqual = (children1, children2) => {
  const children1Count = React.Children.count(children1)
  const children2Count = React.Children.count(children2)
  if (children1Count !== children2Count) {
    return false
  }

  const childrenArray1 = React.Children.toArray(children1)
  const childrenArray2 = React.Children.toArray(children2)
  for (let i = 0; i < children1Count; i++) {
    if (childrenArray1[i] !== childrenArray2[i]) {
      return false
    }
  }

  return true
}

export const getAuthenticityToken = () => (
  $('head meta[name=\'csrf-token\']').attr('content')
)

export const callAPI = (url, method = 'GET', data = {}, headers = {}) => {
  let fullURL = url
  if (method === 'GET') {
    fullURL = `${ url }${ url.includes('?') ? '&' : '?' }${ $.param(data) }`
  }

  let fetchData = {
    method,
    credentials: 'same-origin',
  }
  if (method === 'POST') {
    fetchData = {
      ...fetchData,
      headers: {
        'X-CSRF-Token': getAuthenticityToken(),
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: queryString.stringify(data),
    }
  } else if (method === 'PUT') {
    fetchData = {
      ...fetchData,
      headers: {
        'X-CSRF-Token': getAuthenticityToken(),
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: queryString.stringify(data),
    }
  } else if (method === 'PATCH') {
    fetchData = {
      ...fetchData,
      headers: {
        'X-CSRF-Token': getAuthenticityToken(),
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: queryString.stringify(data),
    }
  } else if (headers !== {}) {
    fetchData = {
      ...fetchData,
      headers: headers,
    }
  }

  return new Promise((resolve, reject) => {
    fetch(fullURL, fetchData)
    .then(response => Promise.all([ response, response.json() ]))
    .then(([ response, json ]) => {
      if (response.status === 200 && _.isEmpty(json.errorMessage)) {
        resolve(json)
      } else {
        reject(json.errorMessage, json)
      }
    })
    .catch(() => {
      reject()
    })
  })
}

// Recursively transforms an object's keys to snake case
export const objectToSnakeCase = object => {
  if (_.isUndefined(object) ||
      _.isNull(object) ||
      typeof(object) === 'number' ||
      typeof(object) === 'string') {
    return object
  }

  if (_.isArray(object)) {
    return object.map(objectToSnakeCase)
  }

  object = _.mapKeys(object, (v, key) => _.snakeCase(key))
  object = _.mapValues(object, objectToSnakeCase)

  return object
}

export const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export const validateEmail = (email) => {
  if (email === undefined || email === null || email.trim().length === 0)
    return false

  let rgx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  let regex_data_fields = /\%(.*?)%/
  let i = 0
  let ok = true
  if ( regex_data_fields.test(email.replace(/\s/g, '')) )
    email = email.replace(/%.*%/, '').replace(/\s/g, '')
  ok = rgx.test(email.replace(/\s/g, ''))
  return ok
}

export const isSingularAssociation = (data, associationName) => {
  const relationship = data?.relationships?.[associationName]?.data;
  return relationship && !Array.isArray(relationship); // true = singular, false = has_many
}

export const normalizeList = objects => {
  let objects_hash = {}

  Array.from(objects).forEach((obj) => {objects_hash[obj.id] = obj})

  return objects_hash
}

export const getAssociationIds = (association) => {
  let list_objects = Array.isArray(association.data) ? association.data : [association.data]
  return list_objects.map((obj) => obj.id)
}
