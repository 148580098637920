import React, {useEffect, useState} from 'react'
import Select from "react-select";
import {selectStyles} from "../constants";

function MemberDropdown(props) {
  const {
    members,
    field,
    member_id,
    disabled,

    handleInputChange,
  } = props

  const initialState = {
    isLoading: false,
  }

  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState({...state, isLoading: false})
  }, [props.members])

  const handleInputMemberChange = (key) => {
    if (key.length > 2){
      setState({...state, isLoading: true})
      props.getMembersDropdownData({key: key, edit: props.edit ?? false})
    }
    else
      return false
  }

  return (
    <Select id={field}
      name={field}
      value={members.filter(function (option) {
        return option.value === member_id;
      })}
      options={members}
      menuPlacement="auto"
      isLoading={state.isLoading}
      placeholder="Limited results. Please enter 3 or more characters"
      onInputChange={(e) => {handleInputMemberChange(e)}}
      onChange={(e) => handleInputChange(field, e.value)}
      isDisabled={disabled}
      styles={selectStyles}
     />


  )
}

export default MemberDropdown
