import {connect} from 'react-redux'
import SettingsPage from "../components/SettingsPage";
import {
  cancelCategory,
  cancelLocation,
  changeCategoryField,
  changeLocationField,
  createCategory, createLocation,
  editCategory,
  editLocation,
  newCategory,
  newLocation,
  removeCategory, removeLocation,
  saveCategory, saveGeneralSettings, saveLocation, updateLocationDefaultOption
} from "../actions";

const mapStateToProps = (state) => ({
  lfin_from: state.settings.meta.general.lfin_from,
  lfin_prefix: state.settings.meta.general.lfin_prefix,
  current_locations: Object.values(state.settings.entities.current_locations).sort((a, b) => compareObjects(a, b)),
  categories: Object.values(state.settings.entities.categories).sort((a, b) => compareObjects(a, b)),
})

const mapDispatchToProps = (dispatch) => ({
  saveGeneralSettings: (data) => dispatch(saveGeneralSettings(data)),

  newLocation: () => dispatch(newLocation()),
  editLocation: (location_id) => dispatch(editLocation(location_id)),
  saveLocation: (location) => dispatch(saveLocation(location)),
  createLocation: (location) => dispatch(createLocation(location)),
  changeLocation: (location, field, value) => dispatch(changeLocationField(location, field, value)),
  cancelLocation: (location) => dispatch(cancelLocation(location)),
  removeLocation: (location) => dispatch(removeLocation(location)),
  updateDefaultOption: (location_id, enabled) => dispatch(updateLocationDefaultOption(location_id, enabled)),

  newCategory: () => dispatch(newCategory()),
  editCategory: (category_id) => dispatch(editCategory(category_id)),
  saveCategory: (category) => dispatch(saveCategory(category)),
  createCategory: (category) => dispatch(createCategory(category)),
  changeCategory: (category, field, value) => dispatch(changeCategoryField(category, field, value)),
  cancelCategory: (category) => dispatch(cancelCategory(category)),
  removeCategory: (category) => dispatch(removeCategory(category)),
})


const compareObjects = (a, b) => {
  if (a.id === "new") return -1; // Keep 'new' first
  if (b.id === "new") return 1;  // Keep 'new' first

  if (a.id == null || b.id == null) return 0;

  return a.attributes?.name.localeCompare(b.attributes?.name, undefined, { sensitivity: "base" });
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsPage)
