import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment-timezone"; // Ensure you're using moment-timezone
import "react-datepicker/dist/react-datepicker.css";

const DatePickerWithTimezone = ({ selected, onChange, timezone, ...props }) => {
  return (
    <DatePicker
      selected={selected ? new Date(stringToTimestampWithTimezone(selected, timezone)) : null} // Convert string to timestamp
      onChange={(date, event) => {
        onChange(date ? setZone(date, timezone) : null, event); // Convert to the selected timezone on change
      }}
      {...props} // Pass additional props to DatePicker
    />
  );
};

const stringToTimestampWithTimezone = (date, timezone) => {
  return Date.parse(new Date(date).toLocaleString("en-US", {timeZone: timezone})); // Return as Timestamp object
};

// Function to adjust the date to the specified timezone
const setZone = (date, timezone) => {
  const dateWithoutZone = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS"); // Format date without timezone
  const otherZone = moment.tz(date, timezone).format("Z"); // Get timezone offset
  const dateWithZone = `${dateWithoutZone}${otherZone}`; // Combine date and timezone

  return dateWithZone; // Return as String Date object
};

export default DatePickerWithTimezone;
