import {connect} from 'react-redux'
import ListBody from '../components/ListBody'
import {
  editItem,
  openClaimItem,
  openReturnToOwnerItem,
  openMarkAsFoundItem,
  openListViewOptionalFieldsModal,
  transitionAction,
  updateListViewSorting, getMembersDropdownData, updateNotifications
} from "../actions";
import moment from "moment/moment";

const mapStateToProps = (state) => ({
  in_fields: state.dashboard.meta.settings.in_fields,
  fields_column_mapping: state.dashboard.meta.settings.fields_column_mapping,
  sort_column: state.dashboard.uiState.listSort.column,
  sort_direction: state.dashboard.uiState.listSort.direction,
  date_format: state.dashboard.meta.settings.date_format,


  items: applyFilters(state.dashboard.entities.items, state.dashboard.entities.categories, state.dashboard.entities.current_locations, {
    ...state.dashboard.uiState.advancedFilters,
    quick_search_value: state.dashboard.uiState.searchTerm,
    view_filter: state.dashboard.uiState.viewFilter
  }, {
    date_format: state.dashboard.meta.settings.date_format,
  }),
  categories: state.dashboard.entities.categories,
  attachments: state.dashboard.entities.attachments,
  current_locations: state.dashboard.entities.current_locations,
  entered_by_managers: state.dashboard.entities.entered_by_managers,
})

const mapDispatchToProps = (dispatch) => ({
  openListViewOptionalFieldsModal: () => dispatch(openListViewOptionalFieldsModal()),
  updateListViewSorting: data => dispatch(updateListViewSorting(data)),
  editItem: (id) => dispatch(editItem(id)),
  openClaimItem: (id) => dispatch(openClaimItem(id)),
  openReturnToOwnerItem: (id) => dispatch(openReturnToOwnerItem(id)),
  openMarkAsFoundItem: (id) => dispatch(openMarkAsFoundItem(id)),
  transitionAction: (item_id, transition, params) => dispatch(transitionAction(item_id, transition, params)),
  getMembersDropdownData: data => dispatch(getMembersDropdownData(data)),
  updateNotifications: data => dispatch(updateNotifications(data)),
})

// Helper function to normalize strings
const normalizeString = (str) => {
  return (str || "")
    .trim()
    .toLowerCase()
    .normalize("NFD") // Remove accents/diacritics
    .replace(/[\u0300-\u036f]/g, "") // Strip combining marks
    .replace(/\s+/g, " "); // Normalize spaces
};

export const applyFilters = (items, categories, locations, filters, settings) => {
  const quick_search_fields = ['customer_name', 'lfin', 'description', 'internal_notes', 'category', 'found_location', 'current_location', 'lost_date', 'found_date', 'claimed_date', 'pickup_date', 'lost_by', 'found_by', 'claimed_by']
  let quick_search_value = normalizeString(filters.quick_search_value || "")

  return Object.values(items)
    .filter((item) => {
      const {view_filter} = filters;
      let matchesSearch = true
      let quick_search_match = !quick_search_value

      switch (view_filter) {
        case "LOST_AND_FOUND":
          break;
        case "LOST":
          matchesSearch = ["LOST_CLAIMED", "LOST_CLAIMED_DELETED"].includes(item.attributes?.status)
          break;
        case "FOUND":
          matchesSearch = ["FOUND_UNCLAIMED", "FOUND_UNCLAIMED_DELETED", "FOUND_CLAIMED", "FOUND_CLAIMED_DELETED", "FOUND_RETURNED_TO_OWNER", "FOUND_RETURNED_TO_OWNER_DELETED"].includes(item.attributes?.status)
          break;
        default:
          break
      }

      if (quick_search_value) {
        let value = ""
        let category = ''
        let current_location = ''
        quick_search_fields.forEach((field) => {
          if (['lost_by', 'found_by', 'claimed_by'].includes(field))
            value = item.attributes[field].full_name
          else if (field === 'category')
            value = categories[item.relationships?.category?.data?.id]?.attributes?.name
          else if (field === 'current_location')
            value = locations[item.relationships?.current_location?.data?.id]?.attributes?.name
          else
            value = item.attributes[field]
          value = normalizeString(value || "")

          if (value.includes(quick_search_value))
            quick_search_match = true
        })

        matchesSearch &= quick_search_match
      }

      if (filters.default_customer_type && filters.default_customer_id) {
        matchesSearch &= (item.attributes.lost_by_entity_id === filters.default_customer_id || item.attributes.found_by_entity_id === filters.default_customer_id || item.attributes.claimed_by_entity_id === filters.default_customer_id)
      }

      if (filters.lost_date_start && item.attributes.lost_date) {
        matchesSearch &= moment(item.attributes.lost_date) >= moment(filters.lost_date_start)
      }

      if (filters.lost_date_end && item.attributes.lost_date) {
        matchesSearch &= moment(item.attributes.lost_date) <= moment(filters.lost_date_end)
      }

      if (filters.found_date_start && item.attributes.found_date) {
        matchesSearch &= moment(item.attributes.found_date) >= moment(filters.found_date_start)
      }

      if (filters.found_date_end && item.attributes.found_date) {
        matchesSearch &= moment(item.attributes.found_date) <= moment(filters.found_date_end)
      }

      if (filters.category_id) {
        matchesSearch &= item.attributes.category_id === filters.category_id
      }


      if (filters.deleted) {
        matchesSearch &= moment(item.attributes.removed_date).isValid()
      }

      if (filters.returned_to_owner) {
        matchesSearch &= moment(item.attributes.pickup_date).isValid()
      }

      if (filters.status) {
        switch (filters.status) {
          case "CLAIMED":
            matchesSearch &= ["FOUND_CLAIMED", "FOUND_CLAIMED_DELETED"].includes(item.attributes.status)
            break;
          case "UNCLAIMED":
            matchesSearch &= ["FOUND_UNCLAIMED", "FOUND_UNCLAIMED_DELETED"].includes(item.attributes.status)
            break;
          case "RETURNED_TO_OWNER":
            matchesSearch &= ["FOUND_RETURNED_TO_OWNER", "FOUND_RETURNED_TO_OWNER_DELETED"].includes(item.attributes.status)
          default:
            break
        }
      }


      return matchesSearch
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(ListBody)
