import React, { useState } from "react";
import Modal, { ModalHeader, ModalBody, ModalFooter } from "./Modal";
import { Form, Col } from "react-bootstrap";
import SortableList from "../../shop_dashboard/components/shared/SortableList";

function ListViewOptionalFieldsModal(props) {
  const initialState = { ...props };
  const [state, setState] = useState(initialState);

  const { modal_list_view_optional_fields, fields, in_fields, mandatory_fields } = props;

  const handleFieldChange = (event) => {
    let updatedInFields = [...state.in_fields];
    if (updatedInFields.includes(event.target.name)) {
      updatedInFields = updatedInFields.filter((item) => item !== event.target.name);
    } else {
      updatedInFields.push(event.target.name);
    }
    setState({ ...state, in_fields: updatedInFields });
  };

  const handleClose = () => {
    props.closeOptionalFieldsModal();
  };

  const handleSubmit = () => {
    let params = { in_fields: JSON.stringify(state.in_fields), fields: JSON.stringify(state.fields) };
    props.updateStockOrdersListViewOptionalFields(params);
  };

  return modal_list_view_optional_fields ? (
      <div id="stockOrderOptionalFieldsModalDiv">
        <Modal isOpen={modal_list_view_optional_fields} scrollable className="stockOrderOptionalFieldsModalSize" dialogClassName="modal-dialog-centered">
          <ModalHeader className="header">
            <h4 className="modal-title form_new_edit m-0">OPTIONAL FIELDS</h4>
          </ModalHeader>
          <ModalBody style={{ minHeight: "300px", maxHeight: "calc(100vh - 200px)", overflowY: "auto" }} className="modal-body body_fields_container overflow-auto">
            <SortableList
                style = {{gap: "1px"}}
                items={state.fields.map(field => ({ id: field[0], label: field[1] }))}
                setItems={(newFields) => {
                  const updatedInFields = newFields.map(field => field.id).filter(id => state.in_fields.includes(id));
                  setState({ ...state, fields: newFields.map(({ id, label }) => [id, label]), in_fields: updatedInFields });
                }}
                renderItem={(item) => (
                    <Form.Group as={Col} className="d-flex align-items-center justify-content-between" controlId={item.id}>
                        <Form.Check
                            name={item.id}
                            value={state.in_fields.includes(item.id)}
                            defaultChecked={state.in_fields.includes(item.id)}
                            type="checkbox"
                            id={item.id}
                            label={item.label}
                            className="form-check-label ps-2 pt-1"
                            onChange={handleFieldChange}
                            disabled={mandatory_fields.includes(item.id)}
                        />
                        <i className="fa fa-bars ms-2 drag-handle"></i>
                    </Form.Group>
                )}

            />
          </ModalBody>
          <ModalFooter>
            <button className="float-start btn gems_custom_button" onClick={handleClose}>
              Close
            </button>
            <button className="btn gems_custom_button gems_orange_button float-end" onClick={handleSubmit}>
              Save
            </button>
          </ModalFooter>
        </Modal>
      </div>
  ) : null;
}

export default ListViewOptionalFieldsModal;
