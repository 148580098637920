import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select';
import { Row, Form, Col, Table, Tab, Tabs, Spinner, Card, OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types'
import {useState, useEffect} from 'react';
import update from 'immutability-helper';
import GsgButton from '../../shared/components/gsg_button';
import {ReferenceLine, Sector , LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, BarChart, Bar, Legend, PieChart, Pie } from 'recharts';
import TimePeriod from './TimePeriod'
import AccountHome from './home/AccountHome'
import OrdersRepairsHome from './home/OrdersRepairsHome'
import DemoClubsHome from './home/DemoClubsHome'
import OrOnlineSurveyHome from './home/OrOnlineSurveyHome';
import TasksHome from "./home/TasksHome";
import RentalsHome from './home/RentalsHome';

import ReorderTilesComponent from "./ReorderTilesComponent";
function Home(props) {

  const initialState = {}

  const [state, setState] = useState(initialState);

  const {customer_id, moduleKey, from, to, refresh_date} = props;
  const {} = state;

  useEffect(() => {
    if(props.isLoading)
      props.load_component_data({key: "home", customer_id: customer_id, to: to, from: from})
  });

  const componentMapping = {
    special_orders: (key) => <OrdersRepairsHome key={key} data={props.components.special_orders} title="SPECIAL ORDERS" />,
    demo_clubs: (key) => <DemoClubsHome key={key} data={props.components.demo_clubs} />,
    stock_orders: (key) => <OrdersRepairsHome key={key} data={props.components.stock_orders} title="STOCK ORDERS" />,
    club_repairs: (key) => <OrdersRepairsHome key={key} data={props.components.club_repairs} title="CLUB REPAIRS" />,
    tasks: (key) => <TasksHome key={key} data={props.components.tasks} title="TASKS" />,
    rentals: (key) => <RentalsHome key={key} data={props.components.rentals} title="RENTALS" />,
    open_online_orders: (key) => <OrOnlineSurveyHome key={key} data={props.components.online_orders["data"]} isLoading={props.components.online_orders["isLoading"]} title="OPEN ONLINE ORDERS" />,
    open_surveys: (key) => <OrOnlineSurveyHome key={key} data={props.components.surveys["data"]} isLoading={props.components.surveys["isLoading"]} title="OPEN SURVEYS" />
  };

  return (
    <div className="pe-0 h-100 background_white home_container_class">
      <div className="row home_class_account add-margin-all-10">
        <div className='col-9'>
          <AccountHome data={props.components.account["data"]} isLoading={props.components.account["isLoading"]}/>
        </div>
        <div className='col-3 text-right'>
          <TimePeriod to={to} 
                      from={from} 
                      refresh_date={refresh_date}
                      moduleKey={moduleKey} 
                      load_component_data={props.load_component_data}/>
          <div className='d-flex justify-content-end mt-2'>
            <ReorderTilesComponent tiles={props.tiles} reorder_tiles={props.reorder_tiles} />
          </div>
        </div>
      </div>
      <div className="flex-container overflow-auto justify-content-center">
        {props.tiles.map((tile, index) => componentMapping[tile]?.(index))}
      </div>
    </div>
  )
}
export default Home