import React from 'react'
import {FloatingLabel, OverlayTrigger, Tooltip} from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { useState } from 'react';
import { TiArrowSortedDown} from '@react-icons/all-files/ti/TiArrowSortedDown';
import {  TiArrowSortedUp } from '@react-icons/all-files/ti/TiArrowSortedUp';
import { Form, Col, Row } from 'react-bootstrap';
import update from 'immutability-helper';
import moment from 'moment';
import {CustomerTypeList} from "./constants";
import MemberDropdown from "./customer_entity/MemberDropdown";

function DashboardAdvancedFilter(props) {


  const initialState = { open_search: false, new_quick_search_value: "", search_params: props.search_params }
  const [state, setState] = useState(initialState);

  const { categories, members, guests, employees } = props;
  const { search_params, open_search } = state


  const status_options = [
    { value: 'CLAIMED', label: 'Claimed' },
    { value: 'UNCLAIMED', label: 'Unclaimed' },
    { value: 'RETURNED_TO_OWNER', label: 'Returned to owner' }
  ]

  //setup before functions
  let typingTimer;                //timer identifier
  let doneTypingInterval = 500;  //time in ms, 5 second for example

  const handleQuickSearchKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(QuickSearch, doneTypingInterval);
  }

  const handleQuickSearchKeyDown = () => {
    clearTimeout(typingTimer);
  }

  const QuickSearch = () => {
    props.changeQuickSearchValue(state.new_quick_search_value)
  }
  const handleChangeQuickSearchValue = (event) => {
    setState({ ...state, new_quick_search_value: event.target.value });
  }
  const handleQuickSearchReset = () => {
    setState({ ...state, new_quick_search_value: "" });
    props.changeQuickSearchValue(null)
  }

  const handleStatusSelectFilterChange = (event) => {
    setState(update(state, {
      search_params: {
        ['status']: { $set: event.value },
      }
    }))
  }

  const handleCategorySelectFilterChange = (event) => {
    setState(update(state, {
      search_params: {
        ['category_id']: { $set: event.value }
      }
    }))
  }

  const handleDateFilterChange = (event, date) => {
    setState(update(state, {
      search_params: {
        [date]: { $set: event }
      }
    }))

  }

  const handleSearch = () => {
    let search_params = { ...state.search_params }
    props.search(search_params)
  }

  const handleResetSearch = () => {
    setState(update(state, {
      search_params: {
        ["deleted"]: { $set: false },
        ["returned_to_owner"]: { $set: false },
        ["status"]: { $set: '' },
        ["default_customer_type"]: { $set: '' },
        ["default_customer_id"]: { $set: '' },
        ["category_id"]: { $set: null },
        ["lost_date_start"]: { $set: null },
        ["lost_date_end"]: { $set: null },
        ["found_date_start"]: { $set: null },
        ["found_date_end"]: { $set: null },
        ["search_keyword"]: { $set: null }
      }
    }))

    props.search({deleted: false})
  }

  const handleOpenSearch = () => {
    setState({ ...state, open_search: !state.open_search });
  }

  const handleInputChange = (field, value) => {
    setState(update(state, {
      search_params: {
        [field]: { $set: value }
      }
    }))
  }

  const handleCustomerTypeChange = (event) => {
    setState(update(state, {
      search_params: {
        ['default_customer_type']: { $set: event.value },
        ['default_customer_id']: { $set: null },
      }
    }))
  }

  const handleCheckboxInputChange = (event) => {

    setState(update(state, {
      search_params: {
        [event.target.name]: { $set: event.target.checked }
      }
    }))
  }

  return (
    <div className="row bg-white border-bottom">
      <div className="col-12 quick-search-dropdown py-2">
        <div className="row">
          <div className={"col-md-8 col-lg-9 col-xl-10 link col add-line-height-35"} onClick={handleOpenSearch} style={{ cursor: 'pointer' }}>
            Lost and Found Dashboard Search
          </div>

          <div className="col col-md-4 col-lg-3 col-xl-2">
            <div className="row m-0">
              <div className="col-10 p-0">
                <div className="input-group input-group-sm">
                  <Form.Control type="text"
                    name='quick_search'
                    placeholder='Search...'
                    value={state.new_quick_search_value || ""}
                    onChange={handleChangeQuickSearchValue}
                    onKeyUp={handleQuickSearchKeyUp}
                    onKeyDown={handleQuickSearchKeyDown}
                  />
                  <div className="input-group-text clear_quick_search fa fa-eraser" onClick={handleQuickSearchReset}>
                  </div>
                </div>
              </div>
              <div className="col-1 add-line-height-35 fs-4 text-end">
                {!open_search ? <TiArrowSortedDown className="cursor_pointer" onClick={handleOpenSearch} /> : <TiArrowSortedUp className="cursor_pointer" onClick={handleOpenSearch} />}
              </div>
            </div>
          </div>
        </div>
      </div>



      {open_search && <div className="row quick-search-dropdown search_container_for_lost_and_found_dashboard m-0">
        <div className="row">
          <div className="col-12 p-0">
            <Form.Group as={Row} className="mb-2" controlId="formSearch" style={{zIndex: 400, paddingTop: 10}}>
              <Form.Label column sm="2" lg="1">
                Status
              </Form.Label>

              <div className="col col-lg-2" style={{zIndex: 400}}>
                <Select id="status"
                        name='status'
                        value={status_options.filter(function (option) {
                          return option.value == search_params.status
                        })}
                        placeholder={"Choose a status..."}
                        options={status_options}
                        onChange={handleStatusSelectFilterChange}/>
              </div>

              <Form.Label column sm="2" lg="1">
                Customer Type
              </Form.Label>

              <div className="col col-lg-2" style={{zIndex: 400}}>
                <Select id="default_customer_type"
                        name='default_customer_type'
                        value={CustomerTypeList.filter(function (option) {
                          return option.value === search_params.default_customer_type;
                        })}
                        options={CustomerTypeList}
                        onChange={handleCustomerTypeChange}/>
              </div>


              <div className="col-sm-2 col-lg-1 d-flex align-items-center">
                {search_params.default_customer_type && <Form.Label>
                  Customer Name
                </Form.Label>}
              </div>

              <div className="col col-lg-2" style={{zIndex: 400}}>
                {search_params.default_customer_type === 'Member' &&
                  <MemberDropdown
                    member_id={state.search_params['default_customer_id']}
                    field={'default_customer_id'}
                    members={members}
                    handleInputChange={handleInputChange}
                    getMembersDropdownData={props.getMembersDropdownData}
                    disabled={false}
                  />
                }

                {search_params.default_customer_type === 'Employee' && <Select
                  id='default_customer_id'
                  name='default_customer_id'
                  options={employees}
                  onChange={(e) => handleInputChange('default_customer_id', e.value)}
                  value={employees.filter(function (option) {
                    return option.value === search_params.default_customer_id;
                  })}
                />}

                {search_params.default_customer_type === 'Guest' && <Select
                  id='default_customer_id'
                  name='default_customer_id'
                  options={guests}
                  onChange={(e) => handleInputChange('default_customer_id', e.value)}
                  value={guests.filter(function (option) {
                    return option.value === search_params.default_customer_id;
                  })}
                />}
              </div>

              <Form.Label column sm="2" lg="1">
                Category
              </Form.Label>

              <div className="col col-lg-2" style={{zIndex: 400}}>
                <Select id="category_id"
                        name='category_id'
                        value={categories.filter(function (option) {
                          return option.value === search_params.category_id;
                        })}
                        options={categories}
                        onChange={handleCategorySelectFilterChange}/>
              </div>

            </Form.Group>

            <Form.Group as={Row} className="mb-2" controlId="formSearch" style={{ zIndex: 300, paddingTop: 10 }}>
              <Form.Label column sm="2" lg="1">
                Lost On Start Date
              </Form.Label>

              <div className="col col-lg-2" style={{ zIndex: 300 }}>
                <DatePicker className="form-control"
                            name='lost_date_start'
                            id='lost_date_start'
                            selected = {Date.parse(moment(search_params.lost_date_start, props.date_format.toUpperCase()))}
                            value =  {Date.parse(moment(search_params.lost_date_start, props.date_format.toUpperCase()))}
                            dateFormat={ props.date_format.replaceAll('m', 'M')}
                            onChange={(e) => handleDateFilterChange(e, 'lost_date_start')}/>
              </div>

              <Form.Label column sm="2" lg="1">
                Lost On End Date
              </Form.Label>

              <div className="col col-lg-2" style={{ zIndex: 300 }}>
                <DatePicker className="form-control"
                            name='lost_date_end'
                            id='lost_date_end'
                            selected = {Date.parse(moment(search_params.lost_date_end, props.date_format.toUpperCase()))}
                            value =  {Date.parse(moment(search_params.lost_date_end, props.date_format.toUpperCase()))}
                            dateFormat={ props.date_format.replaceAll('m', 'M')}
                            onChange={(e) => handleDateFilterChange(e, 'lost_date_end')}/>
              </div>


              <Form.Label column sm="2" lg="1">
                Found On Start Date
              </Form.Label>

              <div className="col col-lg-2" style={{ zIndex: 300 }}>
                <DatePicker className="form-control"
                            name='found_date_start'
                            id='found_date_start'
                            selected = {Date.parse(moment(search_params.found_date_start, props.date_format.toUpperCase()))}
                            value =  {Date.parse(moment(search_params.found_date_start, props.date_format.toUpperCase()))}
                            dateFormat={ props.date_format.replaceAll('m', 'M')}
                            onChange={(e) => handleDateFilterChange(e, 'found_date_start')}/>
              </div>

              <Form.Label column sm="2" lg="1">
                Found On End Date
              </Form.Label>

              <div className="col col-lg-2" style={{ zIndex: 300 }}>
                <DatePicker className="form-control"
                            name='found_date_end'
                            id='found_date_end'
                            selected = {Date.parse(moment(search_params.found_date_end, props.date_format.toUpperCase()))}
                            value =  {Date.parse(moment(search_params.found_date_end, props.date_format.toUpperCase()))}
                            dateFormat={ props.date_format.replaceAll('m', 'M')}
                            onChange={(e) => handleDateFilterChange(e, 'found_date_end')}/>
              </div>

            </Form.Group>

            <Form.Group as={Row} className="" controlId="formSearch">
              <Form.Label column sm="2">
                SHOW ITEMS
              </Form.Label>

              <Form.Check
                name="deleted"
                checked={search_params.deleted || false}
                type='checkbox'
                id="deleted"
                label="Deleted"
                className="col-3 d-flex align-items-center"
                onChange={handleCheckboxInputChange}
              />

              <Form.Check
                name="returned_to_owner"
                checked={search_params.returned_to_owner || false}
                type='checkbox'
                id="returned_to_owner"
                label="Returned to Owner"
                className="col-3 d-flex align-items-center"
                onChange={handleCheckboxInputChange}
              />

            </Form.Group>
          </div>
        </div>

        <div className="row m-0 mb-3">
          <div className="col p-0">
            <a className="btn gems_custom_button float-end order-2" onClick={handleSearch}>
              Search
            </a>
            <a className="btn gems_custom_button float-end me-2 order-1" onClick={handleResetSearch}>
              Reset
            </a>
          </div>
        </div>
      </div >}
    </div >
  )
}


export default DashboardAdvancedFilter
