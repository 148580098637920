import {connect} from 'react-redux'
import {
  closeItemModal,
  createEmployee,
  createGuest,
  createItem,
  updateContactInfo,
  changeNotificationSendOption,
  updateItem,
  updateNotifications, getOverrideNotification, uploadFile, removeFile, getMembersDropdownData
} from '../../actions'
import ManageItemModal from "../../components/actions_modals/ManageItemModal";

const mapStateToProps = (state) => ({
  date_format: state.dashboard.meta.settings.date_format,
  customer_id: state.dashboard.meta.settings.customer_id,
  manageItemIsOpen: state.dashboard.uiState.modals.manageItemIsOpen,


  item: state.dashboard.uiState.selectedItem,
  entry: state.dashboard.uiState.manageItemEntry,
  selectedItemAssociationIds: state.dashboard.uiState.selectedItemAssociationIds,
  notification_communication_data: state.dashboard.uiState.notificationData.communicationData,
  activity_logs: Object.values(state.dashboard.entities.activity_logs).filter((al) => (state.dashboard.uiState.selectedItemAssociationIds.activityLogsIds).includes(al.id)),
  attachments: Object.values(state.dashboard.entities.attachments).filter((a) => (state.dashboard.uiState.selectedItemAssociationIds.attachmentsIds).includes(a.id)),
  email_delivery_reports: Object.fromEntries(Object.entries(state.dashboard.entities.email_delivery_reports).filter(([key, edr]) => (state.dashboard.uiState.selectedItemAssociationIds.emailDeliveryReportsIds || []).includes(edr.id))),

  members_dropdown_list: state.dashboard.cachedData.members,
  employees_dropdown_list: state.dashboard.cachedData.employees,
  guests_dropdown_list: state.dashboard.cachedData.guests,
  managers_dropdown_list: state.dashboard.cachedData.managers,
  categories_dropdown_list: state.dashboard.cachedData.categories,
  current_locations_dropdown_list: state.dashboard.cachedData.current_locations,
})

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeItemModal()),
  createItem: (data) => dispatch(createItem(data)),
  updateItem: data => dispatch(updateItem(data)),
  createEmployee: (data, field) => dispatch(createEmployee(data, field)),
  createGuest: data => dispatch(createGuest(data)),
  getMembersDropdownData: data => dispatch(getMembersDropdownData(data)),
  updateNotifications: data => dispatch(updateNotifications(data)),
  updateContactInfo: (customer, channel, value) => dispatch(updateContactInfo(customer, channel, value)),
  changeNotificationSendOption: (customer, notification_type, channel, is_enabled) => dispatch(changeNotificationSendOption(customer, notification_type, channel, is_enabled)),
  getOverrideNotification: (item, notif_customer_id, channel, template_type) => dispatch(getOverrideNotification(item, notif_customer_id, channel, template_type)),
  uploadFile: data => dispatch(uploadFile(data)),
  removeFile: file => dispatch(removeFile(file)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageItemModal)
