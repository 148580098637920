import React from 'react'
import ListBodyContainer from '../containers/ListBodyContainer'
import ManageItemModalContainer from "../containers/actions_modals/ManageItemModalContainer";
import ClaimItemModalContainer from "../containers/actions_modals/ClaimItemModalContainer";
import ReturnToOwnerItemModalContainer from "../containers/actions_modals/ReturnToOwnerItemModalContainer";
import MarkAsFoundItemModalContainer from "../containers/actions_modals/MarkAsFoundItemModalContainer";
import ListViewOptionalFieldsModalContainer from "../containers/ListViewOptionalFieldsModalContainer";
import NotificationOverrideModalContainer from "../containers/NotificationOverrideModalContainer";

function Body(props) {

  const {
    configureFieldsIsOpen,
    manageItemIsOpen,
    claimItemIsOpen,
    returnToOwnerItemIsOpen,
    markAsFoundItemIsOpen,
    notificationOverrideIsOpen,
  } = props;

  return (
    <div className="row flex-grow-1 bg-white" id="container_view_dashboard_stock_orders"
         style={{overflowX: "auto", overflowY: "hidden"}}>
      {
        <div className="col p-0 h-100">
          <ListBodyContainer/>
        </div>
      }

      {configureFieldsIsOpen && <ListViewOptionalFieldsModalContainer/>}
      {manageItemIsOpen && <ManageItemModalContainer/>}
      {claimItemIsOpen && <ClaimItemModalContainer/>}
      {returnToOwnerItemIsOpen && <ReturnToOwnerItemModalContainer/>}
      {markAsFoundItemIsOpen && <MarkAsFoundItemModalContainer/>}
      {notificationOverrideIsOpen && <NotificationOverrideModalContainer/>}

    </div>
  )
}

export default Body
