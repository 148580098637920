import {connect} from 'react-redux'
import {
  closeReturnToOwnerItemModal,
  createEmployee,
  createGuest, getMembersDropdownData,
  removeFile,
  transitionAction,
  uploadFile
} from '../../actions'
import ReturnToOwnerItemModal from "../../components/actions_modals/ReturnToOwnerItemModal";

const mapStateToProps = (state) => ({
  date_format: state.dashboard.meta.settings.date_format,
  customer_id: state.dashboard.meta.settings.customer_id,
  returnToOwnerItemIsOpen: state.dashboard.uiState.modals.returnToOwnerItemIsOpen,

  item: state.dashboard.uiState.selectedItem,
  entry: state.dashboard.uiState.manageItemEntry,
  selectedItemAssociationIds: state.dashboard.uiState.selectedItemAssociationIds,
  activity_logs: Object.values(state.dashboard.entities.activity_logs).filter((al) => (state.dashboard.uiState.selectedItemAssociationIds.activityLogsIds).includes(al.id)),
  attachments: Object.values(state.dashboard.entities.attachments).filter((a) => (state.dashboard.uiState.selectedItemAssociationIds.attachmentsIds).includes(a.id)),
  email_delivery_reports: Object.fromEntries(Object.entries(state.dashboard.entities.email_delivery_reports).filter(([key, edr]) => (state.dashboard.uiState.selectedItemAssociationIds.emailDeliveryReportsIds || []).includes(edr.id))),


  members_dropdown_list: state.dashboard.cachedData.members,
  employees_dropdown_list: state.dashboard.cachedData.employees,
  guests_dropdown_list: state.dashboard.cachedData.guests,
  managers_dropdown_list: state.dashboard.cachedData.managers,
})

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeReturnToOwnerItemModal()),
  createEmployee: (data, field) => dispatch(createEmployee(data, field)),
  createGuest: data => dispatch(createGuest(data)),
  getMembersDropdownData: data => dispatch(getMembersDropdownData(data)),
  transitionAction: (item_id, transition, params) => dispatch(transitionAction(item_id, transition, params)),
  uploadFile: data => dispatch(uploadFile(data)),
  removeFile: file => dispatch(removeFile(file)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReturnToOwnerItemModal)
