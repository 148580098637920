import React from "react";
import {
    DndContext,
    closestCenter,
    useSensor,
    useSensors,
    PointerSensor,
    KeyboardSensor
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { restrictToParentElement } from "@dnd-kit/modifiers";

const SortableItem = ({ item, renderItem }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item.id });

    const style = {
        transform: CSS.Transform.toString(transform) || undefined,
        transition,
        cursor: "grab",
        touchAction: "none"
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {renderItem(item)}
        </div>
    );
};

const SortableList = ({ items, setItems, renderItem }) => {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
    );

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const oldIndex = items.findIndex((item) => item.id === active.id);
            const newIndex = items.findIndex((item) => item.id === over.id);
            setItems(arrayMove(items, oldIndex, newIndex));
        }
    };

    return (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} modifiers={[restrictToParentElement]}>
            <SortableContext items={items.map(item => item.id)} strategy={verticalListSortingStrategy}>
                <ul style={{ listStyle: "none", padding: 0, display: "flex", flexDirection: "column", gap: "1px" }}>
                    {items.map((item) => (
                        <SortableItem key={item.id} item={item} renderItem={renderItem} />
                    ))}
                </ul>
            </SortableContext>
        </DndContext>
    );
};

export default SortableList;
