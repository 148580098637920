import React from 'react';
import {Table} from 'react-bootstrap';

function ActivityLogsTable(props) {

  const {
    activity_logs,
    email_delivery_reports,
  } = props;

  return (<Table responsive>
    <thead>
    <tr>
      <th>Date / Time</th>
      <th>Description</th>
    </tr>
    </thead>
    <tbody>
    {(activity_logs.length > 0) &&
      activity_logs.map((log) => {
          return (
            <tr key={log.id}>
              <td>
                <div style={{wordWrap: "break-word"}}>
                  {log.attributes?.date} <span style={{whiteSpace: "nowrap"}}>{log.attributes?.time}</span>
                </div>
              </td>
              <td>
                <span dangerouslySetInnerHTML={{__html: log.attributes?.message}}/> {log.relationships?.email_delivery_report?.data?.id &&
                <span>
                      Click <a className={'link cursor_pointer'} href={email_delivery_reports[log.relationships?.email_delivery_report?.data?.id]?.attributes?.url}
                               target={'_blank'}> here </a> to view the full email delivery report for this order.
                    </span>}
              </td>
            </tr>
          )
        }
      )}
    </tbody>
  </Table>)
}

export default ActivityLogsTable