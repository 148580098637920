import React, {useEffect, useId} from 'react';
import Modal, { ModalHeader, ModalBody, ModalFooter } from './Modal';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import {Row, Form, Col, CloseButton, OverlayTrigger, Tooltip} from 'react-bootstrap';
import moment from 'moment-timezone'
import { useState } from 'react';
import TaskSummary from "./TaskSummary";
import {toast} from "react-toastify";
import DatePickerWithTimezone from "./DatePickerWithTimezone";
import _ from "lodash";
import {IoMdStar} from "@react-icons/all-files/io/IoMdStar";
import DueDate from "./DetailedEntry/DueDate";

function TaskModal(props) {

  const initialState = {
    ...props.item,
    isLoading: false,
  }

  const [state, setState] = useState(initialState);

  const { id, name, due_date, tag_member_id, description, recurring, recurring_cadence, recurring_list_references, is_important,
    recurring_end_date,
    assignees_list, due_time, tags_ids, tag_customer_type,
    tag_guest_first_name, tag_guest_last_name, tag_guest_email, tag_guest_phone, isLoading,
    recurring_start_date, recurring_start_time} = state;
  const { files,  task_recurring_setting_id} = props.item

  const { modal_open, closeModal, members_list, users_list, title, btn_name,
    updateStockOrder, customer_id, similar_task, cadence_options,
    assign_to_options, due_date_options, default_due_time, tags_options, createTask, updateTask, deleteTask,
    timezone, dateTimeFormat, isRestricted } = props;

  let real_date = due_date
  if (isNaN(new Date(real_date)))
    real_date = _.find(props.due_date_options, (d) => d["value"] === due_date)["real_value"]

  const firstTimeRecurring = (!id || (!task_recurring_setting_id && recurring)) && !task_recurring_setting_id && recurring

  useEffect(() => {
      setState({...state, isLoading: false})
    }, [props.members_list])

  const handleInputChange = (name, value) => {
    if(name === "recurring_cadence") {
      const recurring_val = !!value
      let recurring_cadence_details = []
      let due_date_val = due_date

      if(value === "weekly") {
        recurring_cadence_details = [moment(real_date).tz(timezone).day()];
      }

      if(value === "monthly") {
        recurring_cadence_details = ["day"];
      }

      setState({...state, recurring_cadence: value, recurring: recurring_val, recurring_list_references: recurring_cadence_details, due_date: firstTimeRecurring ? due_date_val : due_date, recurring_start_date: due_date_val});
    }
    else
      if(name === "recurring_start_date") {
        let a_real_date = value
        if (isNaN(new Date(a_real_date)))
          a_real_date = _.find(props.due_date_options, (d) => d["value"] === value)["real_value"]
        let recurring_cadence_details = []

        if(recurring_cadence === "weekly")
          recurring_cadence_details = [moment(a_real_date).tz(timezone).day()];

        if(recurring_cadence === "monthly")
          recurring_cadence_details = recurring_cadence_details.length !== 1 ? ["day"] : recurring_cadence_details ;

        setState({...state, recurring_list_references: recurring_cadence_details, due_date: firstTimeRecurring ? value : due_date, recurring_start_date: value});
      }
      else
        if((name === "due_time" || name === "recurring_start_time") && firstTimeRecurring) {
          setState({...state, due_time: value, recurring_start_time: value})
        }
        else
          setState({...state, [name]: value });
  }

  const handleRecurringDetails = (value, type) => {
    let new_recurring_list_references = recurring_list_references
    let due_date_val = due_date
    if(recurring_cadence === "weekly"){
      if(type === "push") {
        new_recurring_list_references.push(value);

        setState({...state, recurring_list_references: new_recurring_list_references, due_date: due_date_val});
      }
      else
      {
        new_recurring_list_references = new_recurring_list_references.filter(function (option) {
          return option !== value;
        })

        setState({...state, recurring_list_references: new_recurring_list_references,  due_date: due_date_val});
      }
    }
    if(recurring_cadence === "monthly"){
      setState({...state, recurring_list_references: [value]})
    }
  }

  const handleClose = () => {
    closeModal()
  }

  const handleSubmit = (createSimilar) => {
    let params = {...state}

    if(!params["name"]) {
      window.gems.toast_message.init("Task Name cannot be empty.", "warning")
      return false;
    }

    if(params["recurring"])
      if(params["recurring_cadence"] === "weekly" && params["recurring_list_references"].length <= 0) {
        window.gems.toast_message.init("Please select at least one day of the week.", "warning")
        return false;
      }

    params["assignees_list"] = JSON.stringify(assignees_list)
    params["tags_ids"] = JSON.stringify(tags_ids)
    params["recurring_list_references"] = JSON.stringify(recurring_list_references)

    if(id) {
      params["files"] = JSON.stringify([])
      updateTask(params)
    }
    else
    {
      props.item.files.map((element) => {
        params["files"].push(element.id)
      })
      params["files"] = JSON.stringify(params["files"])

      createTask(params, createSimilar)
    }
  }

  const handleDelete = () => {
    deleteTask(id)
  }

  const handleInputMemberChange = (key) => {
    if (key.length > 2){
      setState({...state, isLoading: true})
      props.getTagCustomerData(key)
    }
    else
      return false
  }

  const select_styles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderRadius: 0,
      boxShadow: "none",
      backgroundColor: "white",
      color: "#212529",
      width: "100%",
    }),
      option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected || state.isFocused ? '#FDEDE9' : 'inherit',
      color: state.isSelected ? '#212529' : 'inherit',
    }),
      borderRadius: 0,
      groupHeading: (base) => ({
      ...base,
      marginRight: '10px',
      marginLeft: '10px',
      border: '1px solid #F7F7F7',
      borderWidth: 'thin'
    })
  }
  function getWeekdayOccurrenceName(date, dayOfWeek) {
    const inputDate = moment(date);

    // Find the first occurrence of the specified weekday in the month
    let firstOccurrence = inputDate.clone().startOf('month').day(dayOfWeek);

    // Adjust for cases where the first occurrence falls in the previous month
    if (firstOccurrence.month() !== inputDate.month()) {
      firstOccurrence.add(7, 'days');
    }

    // Calculate the ordinal occurrence
    const occurrence = Math.ceil((inputDate.date() - firstOccurrence.date()) / 7) + 1;

    // Get the total occurrences of the weekday in the month
    const totalOccurrences = Math.ceil(inputDate.clone().endOf('month').date() / 7);

    // Map of ordinal values
    const ordinalMap = ["first", "second", "third", "fourth", "fifth"];

    // Return 'last' if it's the last occurrence in the month
    if (occurrence === totalOccurrences) {
      return 'last';
    }

    return ordinalMap[occurrence - 1] || `${occurrence}th`;
  }

  const date_format = dateTimeFormat.replaceAll('m', 'M').split(' ')[0]
  const time_format = dateTimeFormat.replaceAll('M', 'm').split(' ')[1] + (props.dateTimeFormat.split(' ')[2] ? " a" : "")
  const tag_customer_type_options = [{label: "No tag customer", value: null } ,{label: "Member", value: "member"}, {label: "Guest", value: "guest"}];
  const week_days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const due_date_select_value = (_.find(due_date_options, (d) => (moment(d["real_value"]).format(date_format.toUpperCase()) === moment(due_date).tz(timezone).format(date_format.toUpperCase()))) || {value: isNaN(new Date(due_date)) ? due_date || "today" : "custom"})["value"]
  const start_date_select_value = (_.find(due_date_options, (d) => (moment(d["real_value"]).format(date_format.toUpperCase()) === moment(recurring_start_date).tz(timezone).format(date_format.toUpperCase()))) || {value: isNaN(new Date(recurring_start_date)) ? recurring_start_date || "today" : "custom"})["value"]
  const tag_guest_data = {name: tag_guest_first_name + " " + tag_guest_last_name, email: tag_guest_email, phone: tag_guest_phone,
                              email_notif: props.notification_communication_data["tag_customer_notification"]["email"],
                              sms_notif: props.notification_communication_data["tag_customer_notification"]["email"]}

  const real_start_date = !isNaN(new Date(recurring_start_date)) ? recurring_start_date : real_date

  const wOccurrenceName = getWeekdayOccurrenceName(real_start_date, moment(real_start_date).tz(timezone).day())
  let cadence_day_options = [{value: "day", label: "on day " + moment(real_start_date).tz(timezone).format("D")},
    {value: wOccurrenceName, label: "on " + wOccurrenceName +" "+ moment(real_start_date).tz(timezone).format("dddd")}]
  if(wOccurrenceName === "fourth")
    cadence_day_options.push({value: "last", label: "on last " + moment(real_start_date).tz(timezone).format("dddd")})

  const cadence_day_value= recurring_list_references ? recurring_list_references[0] || "day" : "day"

  return (
    <div className="modal_task_container">
      {/* TO DO: modal is called even if no data is initialized and is set to isOpen false but this component is rendered anyhow */}
      <Modal isOpen={modal_open}>
        <ModalHeader>
          <div className="row">
            <div className="col d-flex">
              <h4 className="modal-title form_new_edit no-margin order-0">{title}</h4>
              <CloseButton className="order-1 align-vertical-middle" onClick={handleClose}/>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <Form id="TaskForm" className="h-auto">
            <div className="row h-auto">
              <div className="col pt-2 overflow-auto min-height-body-modal mb-2">
                <Form.Group as={Row} className="mb-2" controlId="taskName">
                  <Form.Label column sm="3">
                    Task Name
                  </Form.Label>
                  <Col sm="9" className={isRestricted ? "restricted-container" : ""}>
                    <Form.Control type="text"
                                  name='name'
                                  value={name}
                                  onChange={(e) => handleInputChange("name", e.target.value)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2" controlId="taskAssignTo">
                  <Form.Label column sm="3">
                    Assign To
                  </Form.Label>
                  <Col sm="9" className={isRestricted ? "restricted-container" : ""}>
                    <Select id="assignee"
                            name='assignee'
                            className="basic-multi-select"
                            isMulti
                            value={assignees_list}
                            options={assign_to_options}
                            onChange={(e) => handleInputChange("assignees_list", e)}
                            styles={select_styles}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2" controlId="taskRecurring">
                  <Form.Label column sm="3">
                    Recurring
                  </Form.Label>
                  <div className={`col-9 ${(isRestricted || id) ? "restricted-container" : ""}`}>
                    <div className={`${recurring ? "active" : ""} text-center recurring_container`}>
                      <div className="d-flex flex-row w-100 text-start">
                        <Select id="recurring_cadence"
                                menuPlacement="bottom"
                                name='recurring_cadence'
                                value={cadence_options.filter(function (option) {
                                  return option.value === recurring_cadence;
                                })}
                                options={cadence_options}
                                onChange={(e) => handleInputChange("recurring_cadence", e.value)}
                                styles={select_styles}
                        />
                      </div>
                      {recurring && <>
                        {id && recurring_cadence === "weekly" && <div className="row gap-5px">
                          {week_days.map((day, index) => (
                            <div
                              className={`col week_day_item ${recurring_list_references.includes(index) ? 'active' : ''}`}
                              key={`${day}_${index}`}>
                              <span
                                className={'mb-1 p-1 item-box cursor_pointer pick_up_dates'}
                                value={index}
                                onClick={(e) => handleRecurringDetails(index, recurring_list_references.includes(index) ? "pull" : "push")}>
                                {day}
                              </span>
                            </div>
                          ))}
                        </div>}
                        <DueDate
                          isRestricted = {isRestricted}
                          firstTimeRecurring = {true}
                          due_date_select_value = {firstTimeRecurring ? due_date_select_value : start_date_select_value}
                          due_date_options={due_date_options}
                          select_styles={select_styles}
                          due_date={firstTimeRecurring ? due_date : recurring_start_date}
                          default_due_time={default_due_time}
                          date_format={date_format}
                          timezone={timezone}
                          time_format={time_format}
                          handleInputChange = {handleInputChange}
                          due_time={firstTimeRecurring ? due_time : recurring_start_time}
                          minDate={moment()}
                          maxDate={recurring_end_date}
                          id={id}
                          input_name={"recurring_start"}
                          name="Start On"
                        />

                        {recurring_cadence === "monthly" &&
                          <div className="row w-50">
                            <Select id="monthly_cadence"
                                    name='monthly_cadence'
                                    value={cadence_day_options.filter(function (option) {
                                      return option.value === cadence_day_value;
                                    })}
                                    options={cadence_day_options}
                                    placeholder="..."
                                    onChange={(e) => handleRecurringDetails(e.value)}
                                    styles={select_styles}
                            />
                          </div>}

                        <div className="row">
                          End On
                        </div>
                        <div className="row">
                          <DatePicker className="form-control"
                                      name='recurring_end_date'
                                      id='recurring_end_date'
                                      popperPlacement="bottom-start"
                                      placeholderText="Never"
                                      onChange={(e) => handleInputChange("recurring_end_date", e)}
                                      selected={Date.parse(moment(recurring_end_date))}
                                      value={Date.parse(moment(recurring_end_date))}
                                      minDate={moment(real_date).tz(timezone).toDate()}
                                      dateFormat={date_format}
                                      autoComplete="off"/>
                        </div>
                      </>}
                    </div>
                  </div>
                </Form.Group>
                {!firstTimeRecurring && <DueDate
                          isRestricted = {isRestricted}
                          firstTimeRecurring = {firstTimeRecurring}
                          due_date_select_value = {due_date_select_value}
                          due_date_options={due_date_options}
                          select_styles={select_styles}
                          due_date={due_date}
                          default_due_time={default_due_time}
                          date_format={date_format}
                          timezone={timezone}
                          time_format={time_format}
                          handleInputChange = {handleInputChange}
                          due_time={due_time}
                          id={id}
                          input_name={"due"}
                          minDate={recurring_start_date}
                          maxDate={recurring_end_date}
                          name="Due Date"
                          />}
                <Form.Group as={Row} className="mb-2" controlId="taskDescription">
                  <Form.Label column sm="3">
                    Task Description
                  </Form.Label>
                  <Col sm="9" className={isRestricted ? "restricted-container" : ""}>
                    <Form.Control as="textarea" rows={3}
                                  name='des'
                                  value={description}
                                  onChange={(e) => handleInputChange("description", e.target.value)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2" controlId="taskTagCustomerType">
                  <Form.Label column sm="3">
                    Tag Customer Type
                  </Form.Label>
                  <Col sm="9" className={isRestricted ? "restricted-container" : ""}>
                    <Select id="tag_customer_type"
                            name='tag_customer_type'
                            value={tag_customer_type_options.filter(function (option) {
                              return option.value === tag_customer_type;
                            })}
                            options={tag_customer_type_options}
                            placeholder="Please select customer type (Optional)"
                            onChange={(e) => handleInputChange("tag_customer_type", e.value)}
                            styles={select_styles}
                    />
                  </Col>
                </Form.Group>
                {tag_customer_type === "member" && <Form.Group as={Row} className="mb-2" controlId="taskTagMember">
                  <Form.Label column sm="3">
                    Tag Customer
                  </Form.Label>
                  <Col sm="9" className={isRestricted ? "restricted-container" : ""}>
                    <Select id="tag_member_id"
                            name='tag_member_id'
                            value={members_list.filter(function (option) {
                              return option.value === tag_member_id;
                            })}
                            options={members_list}
                            menuPlacement="top"
                            isLoading={isLoading}
                            placeholder="Limited results. Please enter 3 or more characters"
                            onInputChange={(e) => {handleInputMemberChange(e)}}
                            onChange={(e) => handleInputChange("tag_member_id", e.value)}
                            styles={select_styles}
                    />
                  </Col>
                </Form.Group>}
                {tag_customer_type === "guest" &&
                  <>
                    <Form.Group as={Row} className="mb-2" controlId="taskTagGuestFName">
                      <Form.Label column sm="3">
                        Tag Guest First Name
                      </Form.Label>
                      <Col sm="9" className={isRestricted ? "restricted-container" : ""}>
                        <Form.Control type="text"
                                      className=""
                                      name='tag_guest_first_name'
                                      value={tag_guest_first_name}
                                      onChange={(e) => handleInputChange("tag_guest_first_name", e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2" controlId="taskTagGuestLName">
                      <Form.Label column sm="3">
                        Tag Guest Last Name
                      </Form.Label>
                      <Col sm="9" className={isRestricted ? "restricted-container" : ""}>
                        <Form.Control type="text"
                                      name='tag_guest_last_name'
                                      className=""
                                      value={tag_guest_last_name}
                                      onChange={(e) => handleInputChange("tag_guest_last_name", e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2" controlId="taskTagGuestEmail">
                      <Form.Label column sm="3">
                        Tag Guest Email
                      </Form.Label>
                      <Col sm="9" className={isRestricted ? "restricted-container" : ""}>
                        <Form.Control type="text"
                                      name='tag_guest_email'
                                      className=""
                                      value={tag_guest_email}
                                      onChange={(e) => handleInputChange("tag_guest_email", e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2" controlId="taskTagGuestPhone">
                      <Form.Label column sm="3">
                        Tag Guest Phone
                      </Form.Label>
                      <Col sm="9" className={isRestricted ? "restricted-container" : ""}>
                        <Form.Control type="text"
                                      name='tag_guest_phone'
                                      className=""
                                      value={tag_guest_phone}
                                      onChange={(e) => handleInputChange("tag_guest_phone", e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </>
                }
                <Form.Group as={Row} className="mb-2" controlId="taskTag">
                  <Form.Label column sm="3">
                    Task Tags
                  </Form.Label>
                  <Col sm="9" className={isRestricted ? "restricted-container" : ""}>
                    <Select id="tags_ids"
                            name='tags_ids'
                            className="basic-multi-select"
                            isMulti
                            menuPlacement="top"
                            value={tags_ids}
                            options={tags_options}
                            onChange={(e) => handleInputChange("tags_ids", e)}
                            styles={select_styles}
                    />
                  </Col>
                  <Form.Label column sm="3">
                  </Form.Label>
                  <Col sm="9" className={isRestricted ? "restricted-container" : ""}>
                    <a className="link ms-1 me-1" href={"/customers/" +customer_id+ "/shop_task/task_settings"} target="_blank">
                      Manage Tags
                    </a>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2" controlId="taskImportant">
                  <Form.Label column sm="3">
                    <span className="">Important</span>
                    <OverlayTrigger
                      key="set_important"
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-set_important`}>
                          Tasks marked as Important will be listed on the top of the task board in the Important
                          Section.
                        </Tooltip>
                      }
                    >
                      <i className="gga-info-circle tooltip_info ps-2"/>
                    </OverlayTrigger>
                  </Form.Label>
                  <Col sm="9" className={isRestricted ? "restricted-container text-center" : "text-center"}>
                    {(is_important || false) ?
                      <IoMdStar className="fs-1 cursor_pointer"
                                onClick={(e) => handleInputChange("is_important", !(is_important || false))}/>
                      :
                      <IoMdStar  fill="white" stroke="black" stroke-width="3"
                                 className="fs-1 cursor_pointer"
                                 onClick={(e) => handleInputChange("is_important", !(is_important || false))}/>
                    }
                  </Col>
                </Form.Group>
              </div>
              <div className="col pt-2 h-auto overflow-auto background_grey summary_container min-height-body-modal">
                <TaskSummary files={files}
                             handleInputChange={handleInputChange}
                             uploadFile={props.uploadFile}
                             customer_id={customer_id}
                             tag_customer_type={tag_customer_type}
                             tag_member_id={tag_member_id}
                             tag_guest={tag_guest_data}
                             assignees_list={assignees_list}
                             notification_communication_data={props.notification_communication_data}
                             state={state}
                             isRestricted={isRestricted}
                             btn_name={props.btn_name || null}
                />
              </div>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <button
            className="float-start btn gems_custom_button"
            aria-label="Close"
            onClick={handleClose}
          >
            Close
          </button>
          {!isRestricted && <>
            <button
              className={`btn gems_custom_button gems_orange_button float-end submit_task_more_options`}
              onClick={(event) => handleSubmit(false)}
            >
              Save Task
            </button>
            {btn_name == "Create" && <button
              className="btn gems_custom_button gems_orange_button float-end"
              onClick={(event) => handleSubmit(true)}
            >
              Save and Add Similar Task
            </button>
          }
          </>}
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default TaskModal