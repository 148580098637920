import {connect} from 'react-redux'
import {closeOverrideNotificationModal, updateOverrideNotification} from '../actions'
import NotificationOverrideModal from "../../../shared/components/notifications/NotificationOverrideModal";

const mapStateToProps = (state) => ({
  customer_id: state.dashboard.meta.settings.customer_id,

  object_id: state.dashboard.uiState.notificationData.objectId,
  notif_customer_id: state.dashboard.uiState.notificationData.customerId,
  channel: state.dashboard.uiState.notificationData.channel,
  template_type: state.dashboard.uiState.notificationData.type,
  body: state.dashboard.uiState.notificationData.overrideBody,

  modal_is_open: state.dashboard.uiState.modals.notificationOverrideIsOpen,
})

const mapDispatchToProps = (dispatch) => ({
  closeOverrideNotificationModal: () => dispatch(closeOverrideNotificationModal()),
  updateOverrideNotification: (notif_customer_id, channel, template_type, text) => dispatch(updateOverrideNotification(notif_customer_id, channel, template_type, text)),

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationOverrideModal)
