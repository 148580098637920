import {connect} from 'react-redux'
import Body from "../components/Body";


const mapStateToProps = (state) => ({
  configureFieldsIsOpen: state.dashboard.uiState.modals.configureFieldsIsOpen,
  manageItemIsOpen: state.dashboard.uiState.modals.manageItemIsOpen,
  claimItemIsOpen: state.dashboard.uiState.modals.claimItemIsOpen,
  returnToOwnerItemIsOpen: state.dashboard.uiState.modals.returnToOwnerItemIsOpen,
  markAsFoundItemIsOpen: state.dashboard.uiState.modals.markAsFoundItemIsOpen,
  notificationOverrideIsOpen: state.dashboard.uiState.modals.notificationOverrideIsOpen,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Body)