import WebpackerReact from 'webpacker-react'

import StockOrdersRoot from '../src/stock_orders/containers/StockOrdersRoot'
import MultiWidgetsRoot from '../src/multi_widgets/components/containers/root'
import MasterRosterSpreadsheetUpdateRoot from '../src/member_roster_spreadsheet_update/containers/MasterRosterSpreadsheetUpdateRoot'
import ShopDashboardRoot from '../src/shop_dashboard/containers/ShopDashboardRoot'
import CommunicationCenterRoot from '../src/communications/communication_center/containers/CommunicationCenterRoot'
import EmailCenterRoot from '../src/communications/email_center/containers/EmailCenterRoot'
import TextMessageCenterRoot from '../src/communications/text_message_center/containers/TextMessageCenterRoot'
import CallawayConnectMatchCategoriesRoot from '../src/integrations/callaway_connect/containers/CallawayConnectMatchCategoriesRoot'
import CallawayConnectMatchFieldsRoot from '../src/integrations/callaway_connect/containers/CallawayConnectMatchFieldsRoot'
import OnlineOrderFormRoot from '../src/online_forms/containers/OnlineOrderFormRoot'
import ProfileSetupRoot from '../src/profile_setup/containers/ProfileSetupRoot'
import TasksRoot from '../src/tasks/containers/TasksRoot'
import Toast from '../src/shared/components/Toast'
import LostFoundRoot from "../src/lost_and_found/dashboard/containers/LostFoundRoot";
import LostFoundSettingsRoot from "../src/lost_and_found/settings/containers/LostFoundSettingsRoot";

import { I18n } from "i18n-js";
window.I18n = new I18n();
const translations = require("../../../config/locales/en.json");
window.I18n.translations = translations;

WebpackerReact.setup({CommunicationCenterRoot})
WebpackerReact.setup({EmailCenterRoot})
WebpackerReact.setup({TextMessageCenterRoot})
WebpackerReact.setup({CallawayConnectMatchCategoriesRoot})
WebpackerReact.setup({CallawayConnectMatchFieldsRoot})
WebpackerReact.setup({ StockOrdersRoot })
WebpackerReact.setup({ MultiWidgetsRoot })
WebpackerReact.setup({ MasterRosterSpreadsheetUpdateRoot })
WebpackerReact.setup({ ShopDashboardRoot })
WebpackerReact.setup({OnlineOrderFormRoot})
WebpackerReact.setup({ ProfileSetupRoot })
WebpackerReact.setup({ LostFoundRoot })
WebpackerReact.setup({ LostFoundSettingsRoot })
WebpackerReact.setup({ Toast })
WebpackerReact.setup({ TasksRoot })

// Enable Hot Module Replacement (HMR)
if (module.hot) {
  // Tasks
  module.hot.accept('src/tasks/containers/TasksRoot', () => {
    const NextTasksRoot = require('src/tasks/containers/TasksRoot').default;

    // Clear previous registration and re-register the updated component
    WebpackerReact.unregister('TasksRoot');
    WebpackerReact.setup({ TasksRoot: NextTasksRoot });
  });
  //StockOrders
  module.hot.accept('src/stock_orders/containers/StockOrdersRoot', () => {
    const NextStockOrdersRoot = require('src/stock_orders/containers/StockOrdersRoot').default;

    // Clear previous registration and re-register the updated component
    WebpackerReact.unregister('StockOrdersRoot');
    WebpackerReact.setup({ StockOrdersRoot: NextStockOrdersRoot });
  });
  //CommunicationCenterRoot
  module.hot.accept('src/communications/communication_center/containers/CommunicationCenterRoot', () => {
    const NextCommunicationCenterRoot = require('src/communications/communication_center/containers/CommunicationCenterRoot').default;

    // Clear previous registration and re-register the updated component
    WebpackerReact.unregister('CommunicationCenterRoot');
    WebpackerReact.setup({ CommunicationCenterRoot: NextCommunicationCenterRoot });
  });
  //EmailCenterRoot
  module.hot.accept('src/communications/email_center/containers/EmailCenterRoot', () => {
    const NextEmailCenterRoot = require('src/communications/email_center/containers/EmailCenterRoot').default;

    // Clear previous registration and re-register the updated component
    WebpackerReact.unregister('EmailCenterRoot');
    WebpackerReact.setup({ EmailCenterRoot: NextEmailCenterRoot });
  });
  //TextMessageCenterRoot
  module.hot.accept('src/communications/text_message_center/containers/TextMessageCenterRoot', () => {
    const NextTextMessageCenterRoot = require('src/communications/text_message_center/containers/TextMessageCenterRoot').default;

    // Clear previous registration and re-register the updated component
    WebpackerReact.unregister('TextMessageCenterRoot');
    WebpackerReact.setup({ TextMessageCenterRoot: NextTextMessageCenterRoot });
  });
  //CallawayConnectMatchCategoriesRoot
  module.hot.accept('src/integrations/callaway_connect/containers/CallawayConnectMatchCategoriesRoot', () => {
    const NextCallawayConnectMatchCategoriesRoot = require('src/integrations/callaway_connect/containers/CallawayConnectMatchCategoriesRoot').default;

    // Clear previous registration and re-register the updated component
    WebpackerReact.unregister('CallawayConnectMatchCategoriesRoot');
    WebpackerReact.setup({ CallawayConnectMatchCategoriesRoot: NextCallawayConnectMatchCategoriesRoot });
  });
  //CallawayConnectMatchFieldsRoot
  module.hot.accept('src/integrations/callaway_connect/containers/CallawayConnectMatchFieldsRoot', () => {
    const NextCallawayConnectMatchFieldsRoot = require('src/integrations/callaway_connect/containers/CallawayConnectMatchFieldsRoot').default;

    // Clear previous registration and re-register the updated component
    WebpackerReact.unregister('CallawayConnectMatchFieldsRoot');
    WebpackerReact.setup({ CallawayConnectMatchFieldsRoot: NextCallawayConnectMatchFieldsRoot });
  });
  //MultiWidgetsRoot
  module.hot.accept('src/multi_widgets/components/containers/root', () => {
    const NextMultiWidgetsRoot = require('src/multi_widgets/components/containers/root').default;

    // Clear previous registration and re-register the updated component
    WebpackerReact.unregister('MultiWidgetsRoot');
    WebpackerReact.setup({ MultiWidgetsRoot: NextMultiWidgetsRoot });
  });
  //MasterRosterSpreadsheetUpdateRoot
  module.hot.accept('src/member_roster_spreadsheet_update/containers/MasterRosterSpreadsheetUpdateRoot', () => {
    const NextMasterRosterSpreadsheetUpdateRoot = require('src/member_roster_spreadsheet_update/containers/MasterRosterSpreadsheetUpdateRoot').default;

    // Clear previous registration and re-register the updated component
    WebpackerReact.unregister('MasterRosterSpreadsheetUpdateRoot');
    WebpackerReact.setup({ MasterRosterSpreadsheetUpdateRoot: NextMasterRosterSpreadsheetUpdateRoot });
  });
  //ShopDashboardRoot
  module.hot.accept('src/shop_dashboard/containers/ShopDashboardRoot', () => {
    const NextShopDashboardRoot = require('src/shop_dashboard/containers/ShopDashboardRoot').default;

    // Clear previous registration and re-register the updated component
    WebpackerReact.unregister('ShopDashboardRoot');
    WebpackerReact.setup({ ShopDashboardRoot: NextShopDashboardRoot });
  });
  //OnlineOrderFormRoot
  module.hot.accept('src/online_forms/containers/OnlineOrderFormRoot', () => {
    const NextOnlineOrderFormRoot = require('src/online_forms/containers/OnlineOrderFormRoot').default;

    // Clear previous registration and re-register the updated component
    WebpackerReact.unregister('OnlineOrderFormRoot');
    WebpackerReact.setup({ OnlineOrderFormRoot: NextOnlineOrderFormRoot });
  });
  //ProfileSetupRoot
  module.hot.accept('src/profile_setup/containers/ProfileSetupRoot', () => {
    const NextProfileSetupRoot = require('src/profile_setup/containers/ProfileSetupRoot').default;

    // Clear previous registration and re-register the updated component
    WebpackerReact.unregister('ProfileSetupRoot');
    WebpackerReact.setup({ ProfileSetupRoot: NextProfileSetupRoot });
  });
  //Toast
  module.hot.accept('src/shared/components/Toast', () => {
    const NextToast = require('src/shared/components/Toast').default;

    // Clear previous registration and re-register the updated component
    WebpackerReact.unregister('Toast');
    WebpackerReact.setup({ Toast: NextToast });
  });
  //LostFoundRoot
  module.hot.accept('src/lost_and_found/dashboard/containers/LostFoundRoot', () => {
    const NextLostFoundRoot = require('src/lost_and_found/dashboard/containers/LostFoundRoot').default;

    // Clear previous registration and re-register the updated component
    WebpackerReact.unregister('LostFoundRoot');
    WebpackerReact.setup({ LostFoundRoot: NextLostFoundRoot });
  });
  //LostFoundSettingsRoot
  module.hot.accept('src/lost_and_found/settings/containers/LostFoundSettingsRoot', () => {
    const NextLostFoundSettingsRoot = require('src/lost_and_found/settings/containers/LostFoundSettingsRoot').default;

    // Clear previous registration and re-register the updated component
    WebpackerReact.unregister('LostFoundSettingsRoot');
    WebpackerReact.setup({ LostFoundSettingsRoot: NextLostFoundSettingsRoot });
  });
}




