import React, { useId } from 'react';
import Modal, { ModalHeader, ModalBody, ModalFooter } from './Modal';
import { Form, Row, Col, FormLabel } from 'react-bootstrap';
import { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import Select from 'react-select'

function UploadStockOrderModal(props) {

  const initialState = {
  }

	const [selectedFile, setSelectedFile] = useState();
	const [isSelected, setIsSelected] = useState(false);
  const [uploadIsStarted, setuploadIsStarted] = useState(false);

	const [vendorId, setVendorId] = useState(null);
  const [aiEngine, setAiEngine] = useState("gemini-2.0-flash")

  const [state, setState] = useState(initialState);
  const forceUpdate = React.useCallback(() => setState({}), []);

  const { modal_upload_open, closeUploadModal, title, customer_id, upload_finished_with_error, upload_error_description } = props;

  const getGeminiModelLabel = (model) => {
    switch(model) {
      case 'gemini-2.0-flash':
        return { label: 'Gemini 2.0 Flash', value: model }
      case 'gemini-2.0-flash-lite':
        return { label: 'Gemini 2.0 Flash Lite', value: model }
      case 'gemini-1.5-flash-002':
        return { label: 'Gemini 1.5 Flash', value: model }
      case 'gemini-1.5-pro-002':
        return { label: 'Gemini 1.5 Pro', value: model }
      default:
        return { label: 'Gemini 2.0 Flash', value: model }
    }
  }

  const getGeminiModelOptions = () => {
    return [
      {label: "Gemini 2.0 Flash", value: "gemini-2.0-flash"},
      {label: "Gemini 2.0 Flash Lite", value: "gemini-2.0-flash-lite"}, 
      {label: "Gemini 1.5 Flash", value: "gemini-1.5-flash-002"}, 
      {label: "Gemini 1.5 Pro", value: "gemini-1.5-pro-002"}
    ]
  }

  const handleClose = () => {
    closeUploadModal()
  }

	const changeHandler = (event) => {
    if (event.target.files[0]){
      setSelectedFile(event.target.files[0]);
      setIsSelected(true);
    }
	};

  const clickElement = () => {
    // To simulate a user focusing an input you should use the
    // built in .focus() method.
    myInput.current?.click();

    // To simulate a click on a button you can use the .click()
    // method.
    // myInput.current?.click();
  }

  const myInput = useRef(null);

  const handleVendorSelectChange = (event) => {
    setVendorId(event.value)
  }
  const handleAiEngineSelectChange = (event) => {
    setAiEngine(event.value)
  }

	const handleSubmission = () => {
		const formData = new FormData();

		formData.append('File', selectedFile);
    formData.append('customer_id', customer_id);
    formData.append('vendor_id', vendorId)
    formData.append('ai_engine', aiEngine)
    props.uploadStockOrderFile(formData)
    setuploadIsStarted(true)
    setSelectedFile(null)
    setIsSelected(false)
    document.getElementById('files_id').value= null;
	};

  const handleJobRetry = () => {
    props.retryUploadStockOrder(props.upload_stock_order_job_id)
  }

  return (
    <div className="">
      <Modal className="modal-xl" style={{height: "700px!important"}} isOpen={modal_upload_open}>
        <ModalHeader >
          <div className="row">
            <div className="col-7 order-1">
              <h4 className="modal-title form_new_edit no-margin">{title}</h4>
            </div>
          </div>
        </ModalHeader>
        <ModalBody className="overflow-visible" style={{ minHeight: 300 } }>
          <div className='pb-4 fs-7' id="uploadInfoText">
            <p><i>
              The AI Stock Order Assistant, powered by Google Gemini AI, will extract information from an order confirmation PDF and attempt to import it into a new stock order.
              <b> Keep in mind that the quality of results will vary depending on the quality of information and vendor. </b>
              <br/>
              <br/>
            </i></p>
            <p><i>
              After uploading, review the imported information carefully, make any necessary edits, and use the&nbsp;
              <a target="_blank" href="https://golfshopdocs.golfgenius.com/article/show/172845" className='link'>move items tool</a> to change product categories (if necessary).
              <br/>
              <br/>
              </i></p>
            <p><i>
              For upload tips and best practices, <a target="_blank" href="https://golfshopdocs.golfgenius.com/article/show/172844" className='link'>Click Here</a>.
              <br/>
              <br/>
            </i></p>
            <p>
              Please select a Vendor and upload a PDF Order Confirmation from that Vendor. Our assistant will then analyze the data and input it into a Stock Order form.
              <br/>
            </p>
          </div>
{/* 
          <Form.Group as={Row} className="mb-2" controlId="formVendor" id="uploadInstructions">
            <Col sm="6" className="pt-1">
              AI Assistant Expectations and Tips:
              <ul>
                <br />
                <li> You may not get 100% of the expected results </li>
                <br />
                <li> Mismatches can occur </li>
                <br />
                <li> Use an original PDF format </li>
              </ul>
            </Col>
            <Col sm="6">
              <br /> <br />
              <ul>
                <li> The Stock Order form is editable to make any changes </li>
                <br />
                <li> Symbols/Dashes on PDFs can skew sizing line </li>
              </ul>
            </Col>
          </Form.Group> */}

          <Form.Group as={Row} className="mb-3" controlId="formVendor" id="uploadVendorSelect">
            <label className="pt-2 w-auto d-inline">
              Vendor
            </label>
            <Col sm="6" md="5" lg="3">
              <Select id="vendor_id"
                name='vendor_id'
                value={props.vendors_list.filter(function (option) {
                  return option.value === vendorId;
                })}
                options={props.vendors_list}
                onChange={handleVendorSelectChange} 
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 pt-3" controlId="formEngine" id="uploadAiEngineSelect">
            <Col sm="2" className="pt-2 w-auto d-inline">
              Model
            </Col>
            <Col sm="6" md="5" lg="3">
              <Select id="ai_engine"
                name='ai_engine'
                value={getGeminiModelLabel(aiEngine)}
                options={getGeminiModelOptions()}
                onChange={handleAiEngineSelectChange} 
              />
            </Col>
          </Form.Group>

          {uploadIsStarted && !upload_finished_with_error &&
            <div>
              <div className='d-flex justify-content-center'>
                <img style={ {width: 300}} src={ props.cdn_assets_urls.in_progress_gif_url }/>
              </div>
              <p id="job_status_text" className='pb-5 fs-5 bold' style={{textAlign: "center"}}>
                <b>Uploading your PDF</b>
              </p>
            </div>
          }

          {upload_finished_with_error &&
            <div>
              <div className='d-flex justify-content-center' style={{paddingTop: "50px"}}>
                <img style={ {width: 200}} src={ props.cdn_assets_urls.error_gif_url }/>
              </div>
              <p className='pb-5' style={{textAlign: "center"}}>
                {upload_error_description}
              </p>
              <div className = 'd-flex justify-content-center'>
                <button
                    className={`btn gems_custom_button ps-3 pe-3 fs-6 errors_${props.upload_stock_order_job_id}`}
                    style={{width: "fit-content"}}
                    aria-label="manage_tabs"
                    onClick={handleJobRetry}
                    disabled={false}
                  >
                    <span>
                      Retry
                    </span>
                </button>
              </div>
            </div>
          }

          { !uploadIsStarted && !upload_finished_with_error && 
            <Form.Group controlId="formUploadAttch">
              <Row>
                <Col>
                  <input className = "d-none" type="file" name="file" id="files_id" onChange={changeHandler} ref={myInput} accept={".pdf, .png"}/>    
                  {!isSelected && <button
                      className="btn gems_custom_button gems_orange_button mt-2 ps-3 pe-3 fs-6"
                      style={{width: "fit-content"}}
                      aria-label="manage_tabs"
                      onClick={clickElement}
                      disabled={!vendorId}
                    >
                      <span>
                        <i className="fa fa-cloud-upload fa-fw me-1 fs-6"></i>
                        Upload PDF
                      </span>
                  </button>
                  }
                  {isSelected && 
                    <Form.Group as={Row} className="pt-2 mb-3" controlId="formVendor" id="uploadVendorSelect">
                      <Row>
                        <Col>
                        Selected PDF file:
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="9">
                          <span className="fs-6" style={{color: "#0098c4"}}>
                            {selectedFile.name}
                          </span>
                          <span onClick={clickElement} className="ps-2 cursor_pointer" style={{color: "#333f48"}}>
                            <i className="fa fa-pencil fa-fw"></i>
                          </span>
                        </Col>
                      </Row>
                    </Form.Group>              
                  }
                <img className="float-end" src={ props.cdn_assets_urls.gemini_logo } style={{ width: 100 }}/>
              </Col>
              </Row>
            </Form.Group>
          }
        </ModalBody>
        <ModalFooter>
          <button
            className="float-start btn gems_custom_button"
            aria-label="Close"
            onClick={handleClose}
          >
            Close
          </button>

          { !uploadIsStarted && !upload_finished_with_error && <button
            className="float-end btn gems_custom_button gems_orange_button"
            aria-label="Close"
            onClick={handleSubmission}
            disabled={!isSelected}
          >
            Submit
          </button>
          }
        </ModalFooter>
      </Modal>
    </div>
  );
}
export default UploadStockOrderModal