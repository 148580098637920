import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import {useState} from 'react';
import {Form, Col, Row} from 'react-bootstrap';
import update from 'immutability-helper';

function DashboardActions (props){


  const initialState = {new_quick_search_value: "", search_params: props.search_params}

  const [state, setState] = useState(initialState);

  
  //setup before functions
  let typingTimer;                //timer identifier
  let doneTypingInterval = 500;  //time in ms, 5 second for example

  const handleQuickSeachKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(QuickSeach, doneTypingInterval);
  }

  const handleQuickSeachKeyDown = () => {
    clearTimeout(typingTimer);
  }

  const QuickSeach = () => {
    props.changeQuickSearchValue(state.new_quick_search_value)
  }
  const handleChangeQuickSearchValue = (event) => {
    setState({...state, new_quick_search_value: event.target.value});
  }
  const handleQuickSeachReset = () => {
    setState({...state, new_quick_search_value: ""});
    props.changeQuickSearchValue(null)
  }


  return (
    <div className="row">
      <div className="col-12 col-xl-8 offset-xl-2 py-2">
        <div className="row">
          <div className="p-0 col col-md-6 col-lg-7 col-xl-8">
            <div className='mb-2 button_row'>
              <div className='btn gems_custom_button gems_orange_button cursor_pointer me-1 my-1'>
                <a href={props.create_text_message_url} data-method="get">Create Text Message</a>
              </div>
              <div className='btn gems_custom_button gems_orange_button cursor_pointer me-1 my-1'>
                <a href={props.create_template_url} data-method="get">Create Template</a>
              </div>
            </div>
            
          </div>
          <div className="col col-md-6 col-lg-5 col-xl-4 quick-search-dropdown">
              <div className="input-group input-group-sm">
                <Form.Control type="text" 
                  name='quick_search'
                  placeholder='Search...'
                  value={state.new_quick_search_value || ""}
                  onChange={handleChangeQuickSearchValue}
                  onKeyUp={handleQuickSeachKeyUp} 
                  onKeyDown={handleQuickSeachKeyDown} 
                />
                <div className="input-group-text clear_quick_search fa fa-eraser" onClick={handleQuickSeachReset}>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div> 
  )
}


export default DashboardActions
