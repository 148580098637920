import {callAPI, showErrorNotification} from 'shared/helpers'
import {ActionsConfig, EditStates, FieldLabels, initialEntry} from "../components/constants";
import moment from "moment";
export const ActionTypes = {
  FETCH_LOST_AND_FOUND_DATA_REQUEST: 'FETCH_LOST_AND_FOUND_DATA_REQUEST',
  OPEN_LIST_VIEW_ADDITIONAL_FIELDS: 'OPEN_LIST_VIEW_ADDITIONAL_FIELDS',
  UPDATE_LIST_VIEW_OPTIONAL_FIELDS: 'UPDATE_LIST_VIEW_OPTIONAL_FIELDS',
  CLOSE_OPTIONAL_FIELDS_MODAL: 'CLOSE_OPTIONAL_FIELDS_MODAL',
  CHANGE_VIEW_FILTER: 'CHANGE_VIEW_FILTER',
  UPDATE_LIST_VIEW_SORTING: 'UPDATE_LIST_VIEW_SORTING',
  NEW_LOST_ITEM: 'NEW_LOST_ITEM',
  NEW_FOUND_ITEM: 'NEW_FOUND_ITEM',
  CLOSE_ITEM_MODAL: 'CLOSE_ITEM_MODAL',
  CREATE_ITEM: 'CREATE_ITEM',
  UPDATE_ITEM: 'UPDATE_ITEM',
  EDIT_ITEM: 'EDIT_ITEM',
  CREATE_EMPLOYEE: 'CREATE_EMPLOYEE',
  CREATE_GUEST: 'CREATE_GUEST',
  TRANSITION: 'TRANSITION',
  OPEN_CLAIM_ITEM_MODAL: 'OPEN_CLAIM_ITEM_MODAL',
  CLOSE_CLAIM_ITEM_MODAL: 'CLOSE_CLAIM_ITEM_MODAL',
  OPEN_RETURN_TO_OWNER_ITEM_MODAL: 'OPEN_RETURN_TO_OWNER_ITEM_MODAL',
  CLOSE_RETURN_TO_OWNER_ITEM_MODAL: 'CLOSE_RETURN_TO_OWNER_ITEM_MODAL',
  OPEN_MARK_AS_FOUND_ITEM_MODAL: 'OPEN_MARK_AS_FOUND_ITEM_MODAL',
  CLOSE_MARK_AS_FOUND_ITEM_MODAL: 'CLOSE_MARK_AS_FOUND_ITEM_MODAL',
  UPDATE_NOTIFICATIONS: 'UPDATE_NOTIFICATIONS',
  UPDATE_NOTIF_CONTACT_INFO: 'UPDATE_NOTIF_CONTACT_INFO',
  CHANGE_NOTIFICATION_SEND_OPTION: 'CHANGE_NOTIFICATION_SEND_OPTION',
  OPEN_NOTIFICATION_OVERRIDE_MODAL: 'OPEN_NOTIFICATION_OVERRIDE_MODAL',
  CLOSE_NOTIFICATION_OVERRIDE_MODAL: 'CLOSE_NOTIFICATION_OVERRIDE_MODAL',
  UPDATE_NOTIFICATION_OVERRIDE: 'UPDATE_NOTIFICATION_OVERRIDE',
  QUICK_SEARCH: 'QUICK_SEARCH',
  SET_SEARCH_FILTERS: 'SET_SEARCH_FILTERS',
  SEARCH_BACKEND: 'SEARCH_BACKEND',
  APPLY_FILES_TO_ITEM: 'APPLY_FILES_TO_ITEM',
  REMOVE_FILE: 'REMOVE_FILE',
  SET_MEMBERS_DATA: 'SET_MEMBERS_DATA',
}

export const getMembersDropdownData = data => (dispatch, getState) => {
  const state = getState()

  const dataURL = "/api/web/customers/" + state.dashboard.meta.settings.customer_id + "/members/fetch_dropdown_data?key=" + data.key + "&edit=" + Boolean(data.edit)

  callAPI(dataURL, 'GET', data, new Headers({'Accept': 'application/json'}))
    .then(response => {
      dispatch({
        type: ActionTypes.SET_MEMBERS_DATA,
        data: response,
      })
      return true
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const openListViewOptionalFieldsModal = () => (dispatch, getState) => {
  const state = getState()
  const dataURL = `/api/web/customers/${state.dashboard.meta.settings.customer_id}/lost_and_found/items/optional_fields_modal`
  const params = {}

  callAPI(dataURL, 'GET', params, new Headers({'Accept': 'application/json'}))
    .then(response => {
      dispatch({
        type: ActionTypes.OPEN_LIST_VIEW_ADDITIONAL_FIELDS,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const updateListViewOptionalFields = data => (dispatch, getState) => {
  const state = getState()
  const dataURL = `/api/web/customers/${state.dashboard.meta.settings.customer_id}/lost_and_found/items/update_optional_fields`

  dispatch({
    type: ActionTypes.UPDATE_LIST_VIEW_OPTIONAL_FIELDS,
    data: data
  })

  callAPI(dataURL, 'POST', data, new Headers({'Accept': 'application/json'}))
    .then(response => {
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const closeOptionalFieldsModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_OPTIONAL_FIELDS_MODAL
  })
}

export const changeViewFilter = (view) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.CHANGE_VIEW_FILTER,
    data: {
      view: view,
    },
  })
}

export const updateListViewSorting = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_LIST_VIEW_SORTING,
    data: data,
  })
}


export const newLostItem = () => (dispatch, getState) => {
  const state = getState()

  const dataURL = `/api/web/customers/${state.dashboard.meta.settings.customer_id}/lost_and_found/items/new`
  const params = {
    entry: 'lost'
  }

  callAPI(dataURL, 'GET', params, new Headers({'Accept': 'application/json'}))
    .then(response => {
      dispatch({
        type: ActionTypes.NEW_LOST_ITEM,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const newFoundItem = () => (dispatch, getState) => {
  const state = getState()

  const dataURL = `/api/web/customers/${state.dashboard.meta.settings.customer_id}/lost_and_found/items/new`
  const params = {
    entry: 'found'
  }

  callAPI(dataURL, 'GET', params, new Headers({'Accept': 'application/json'}))
    .then(response => {
      dispatch({
        type: ActionTypes.NEW_FOUND_ITEM,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}


export const closeItemModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_ITEM_MODAL
  })
}

export const openClaimItem = (id) => (dispatch) => {
  dispatch({
    type: ActionTypes.OPEN_CLAIM_ITEM_MODAL,
    data: {
      id: id
    }
  })
}

export const closeClaimItemModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_CLAIM_ITEM_MODAL
  })
}

export const closeReturnToOwnerItemModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_RETURN_TO_OWNER_ITEM_MODAL
  })
}

export const openReturnToOwnerItem = (id) => (dispatch) => {
  dispatch({
    type: ActionTypes.OPEN_RETURN_TO_OWNER_ITEM_MODAL,
    data: {
      id: id
    }
  })
}

export const openMarkAsFoundItem = (id) => (dispatch) => {
  dispatch({
    type: ActionTypes.OPEN_MARK_AS_FOUND_ITEM_MODAL,
    data: {
      id: id
    }
  })
}

export const closeMarkAsFoundItemModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_MARK_AS_FOUND_ITEM_MODAL
  })
}


export const createItem = (data) => (dispatch, getState) => {
  const state = getState()
  const dataURL = `/api/web/customers/${state.dashboard.meta.settings.customer_id}/lost_and_found/items`
  if (data) {
    data.customer_id = state.dashboard.meta.settings.customer_id
    data.notification_options = JSON.stringify(getNotificationOptionsParam(state.dashboard.uiState.notificationData.communicationData || []))
    data.override_notifications = JSON.stringify(getNotificationOverrideParam(state.dashboard.uiState.notificationData.communicationData || []))
    data.attachments = JSON.stringify(state.dashboard.uiState.selectedItemAssociationIds.attachmentsIds || [])
    callAPI(dataURL, 'POST', data, new Headers({'Accept': 'application/json', 'Content-type': 'application/json'}))
      .then(response => {
        if (response.errors)
          response.errors.forEach((message, index) => window.gems.toast_message.init(message, "error"))
        else {
          dispatch({
            type: ActionTypes.CREATE_ITEM,
            data: response,
          })
          window.gems.toast_message.init('Item has been created successfully!', "success")
        }
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const editItem = id => (dispatch, getState) => {
  const state = getState()
  if (id) {
    const dataURL = `/api/web/customers/${state.dashboard.meta.settings.customer_id}/lost_and_found/items/${id}/edit`
    const params = {}
    callAPI(dataURL, 'GET', params, new Headers({'Accept': 'application/json'}))
      .then(response => {
        window.history.pushState(window.location.href, 'Title', window.location.href.split('?')[0]);
        dispatch({
          type: ActionTypes.EDIT_ITEM,
          data: response,
        })
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const updateItem = data => (dispatch, getState) => {
  const state = getState()

  if (data.id) {
    if (!data.claimed_by_entity_id)
      data.claimed_by_entity_type = null
    if (!data.lost_by_entity_id)
      data.lost_by_entity_type = null
    if (!data.found_by_entity_id)
      data.found_by_entity_type = null

    if (!data[EditStates(initialEntry(data.lost_date))[data.status].state_transition_date])
      data.transition = 'revert_to_previous_state'
    else
      data.transition = 'update'


    const dataURL = `/api/web/customers/${state.dashboard.meta.settings.customer_id}/lost_and_found/items/${data.id}`
    callAPI(dataURL, 'PATCH', data, new Headers({'Accept': 'application/json'}))
      .then(response => {
        if (response.errors)
          response.errors.forEach((message, index) => window.gems.toast_message.init(message, "error"))
        else {
          dispatch({
            type: ActionTypes.UPDATE_ITEM,
            data: response,
          })
          window.gems.toast_message.init('Item has been updated successfully!', "success")
        }


        window.gems.toast_message.init(`Item has been updated ${data.transition === 'revert_to_previous_state' ? "and has been reverted to the previous status " : ""}successfully!`, "success")
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}


export const createEmployee = (data, field) => (dispatch, getState) => {
  const state = getState()
  const dataURL = `/api/web/customers/${state.dashboard.meta.settings.customer_id}/employees`

  if (data) {
    data.customer_id = state.dashboard.meta.settings.customer_id
    return callAPI(dataURL, 'POST', data, new Headers({
      'Accept': 'application/json',
      'Content-type': 'application/json'
    }))
      .then(response => {
        if (response.errors) {
          response.errors.forEach((message, index) => window.gems.toast_message.init(message, "error"))
          return null
        } else {
          dispatch({
            type: ActionTypes.CREATE_EMPLOYEE,
            data: {...response, field: field},
          })
          return response.employee.id
        }
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const createGuest = (data, field) => (dispatch, getState) => {
  const state = getState()
  const dataURL = `/api/web/customers/${state.dashboard.meta.settings.customer_id}/guests`

  if (data) {
    data.customer_id = state.dashboard.meta.settings.customer_id
    return callAPI(dataURL, 'POST', data, new Headers({
      'Accept': 'application/json',
      'Content-type': 'application/json'
    }))
      .then(response => {
        if (response.errors) {
          response.errors.forEach((message, index) => window.gems.toast_message.init(message, "error"))
          return null
        } else {
          dispatch({
            type: ActionTypes.CREATE_GUEST,
            data: {...response, field: field},
          })
          return response.guest.id
        }
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const transitionAction = (item_id, transition, params) => (dispatch, getState) => {
  const state = getState()
  const dataURL = `/api/web/customers/${state.dashboard.meta.settings.customer_id}/lost_and_found/items/${item_id}/transition`

  let data = {
    ...params,
    id: item_id,
    transition: transition,
    customer_id: state.dashboard.meta.settings.customer_id,
    notification_options: JSON.stringify(getNotificationOptionsParam(state.dashboard.uiState.notificationData.communicationData || [])),
    override_notifications: JSON.stringify(getNotificationOverrideParam(state.dashboard.uiState.notificationData.communicationData || [])),
  }
  if (item_id) {
    return callAPI(dataURL, 'POST', data, new Headers({
      'Accept': 'application/json',
      'Content-type': 'application/json'
    }))
      .then(response => {
        if (response.errors) {
          response.errors.forEach((message, index) => window.gems.toast_message.init(message, "error"))
          return false;
        } else {
          dispatch({
            type: ActionTypes.TRANSITION,
            data: response,
          })
          window.gems.toast_message.init(`${ActionsConfig[transition].label} action has been successfully!`, "success")
          return true;
        }

      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const validateFields = (item, fields_required, edit = false) => {
  const newErrors = {};

  fields_required.forEach((field, index) => {
    if (field.includes('date') && !item[field]) {
      if (!edit || EditStates(initialEntry(item.lost_date))[item.status].mandatory.includes(field))
        newErrors[field] = `${FieldLabels[field]} is required!`
    }
    else if (!item[field])
      newErrors[field] = `${FieldLabels[field]} is required!`
  })

  if (edit && !item.found_date && !item.lost_date)
    newErrors.required_date = "Either Lost Date or Found Date is required.";
  // Validate date logic
  const lostDate = item.lost_date ? new Date(item.lost_date) : null;
  const foundDate = item.found_date ? new Date(item.found_date) : null;
  const claimedDate = item.claimed_date ? new Date(item.claimed_date) : null;
  const pickupDate = item.pickup_date ? new Date(item.pickup_date) : null;

  if (lostDate && foundDate && lostDate > foundDate) {
    newErrors.lost_date = "Lost Date cannot be after Found Date.";
  }

  if (claimedDate && pickupDate && claimedDate > pickupDate) {
    newErrors.claimed_date = "Claimed Date cannot be after Pickup Date.";
  }

  return newErrors;
};

export const updateNotifications = (notifications) => (dispatch, getState) => {
  const state = getState()
  const dataURL = `/api/web/customers/${state.dashboard.meta.settings.customer_id}/lost_and_found/items/get_notifications`

  let data = {
    notifications: JSON.stringify(notifications),
  }
  if (notifications) {
    return callAPI(dataURL, 'POST', data, new Headers({
      'Accept': 'application/json',
      'Content-type': 'application/json'
    }))
      .then(response => {
        if (response.errors) {
          response.errors.forEach((message, index) => window.gems.toast_message.init(message, "error"))
          return false;
        } else {
          dispatch({
            type: ActionTypes.UPDATE_NOTIFICATIONS,
            data: response,
          })
          return true;
        }

      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const updateContactInfo = (customer, channel, value) => (dispatch, getState) => {
  const state = getState()
  const dataURL = `/api/web/customers/${state.dashboard.meta.settings.customer_id}/lost_and_found/items/update_contact_info`

  let data = {
    notif_customer_type: customer.type,
    notif_customer_id: customer.id,
    channel: channel,
    value: value,
  }
  if (customer.id) {
    return callAPI(dataURL, 'POST', data, new Headers({
      'Accept': 'application/json',
      'Content-type': 'application/json'
    }))
      .then(response => {
        if (response.errors) {
          response.errors.forEach((message, index) => window.gems.toast_message.init(message, "error"))
          return false;
        } else {
          dispatch({
            type: ActionTypes.UPDATE_NOTIF_CONTACT_INFO,
            data: response,
          })
          window.gems.toast_message.init('Customer contact info has been updated successfully!', "success")
          return true;
        }

      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const changeNotificationSendOption = (customer, notification_type, channel, is_enabled) => (dispatch) => {
  dispatch({
    type: ActionTypes.CHANGE_NOTIFICATION_SEND_OPTION,
    data: {
      customer: customer,
      notification_type: notification_type,
      channel: channel,
      is_enabled: is_enabled
    }
  })
}

export const getOverrideNotification = (item, notif_customer_id, channel, template_type) => (dispatch, getState) => {
  const state = getState()

  let notification = (state.dashboard.uiState.notificationData.communicationData.filter((cn) => cn.customer.id === notif_customer_id)[0].notifications || []).filter((n) => n[channel].type === template_type)[0]
  if (notification[channel].is_overwritten) {
    dispatch({
      type: ActionTypes.OPEN_NOTIFICATION_OVERRIDE_MODAL,
      data: {
        body: notification[channel].overwritten_body,
        notification_type: template_type,
        notification_object_id: item.id,
        notification_customer_id: notif_customer_id,
        notification_channel: channel,
      },
    })
    return true;
  }

  const dataURL = `/api/web/customers/${state.dashboard.meta.settings.customer_id}/notifications/get_default_notification_template_for_object`

  let data = {
    ...item,
    customer_id: state.dashboard.meta.settings.customer_id,
    notification_channel_type: channel,
    notification_template_type: template_type,
    object_id: item.id || 'new',
    object_type: 'lost_found_item'
  }
  if (item) {
    return callAPI(dataURL, 'POST', data, new Headers({
      'Accept': 'application/json',
      'Content-type': 'application/json'
    }))
      .then(response => {
        if (response.errors) {
          response.errors.forEach((message, index) => window.gems.toast_message.init(message, "error"))
          return false;
        } else {
          dispatch({
            type: ActionTypes.OPEN_NOTIFICATION_OVERRIDE_MODAL,
            data: {
              body: response.body,
              notification_type: template_type,
              notification_object_id: item.id,
              notification_customer_id: notif_customer_id,
              notification_channel: channel,
            },
          })
          return true;
        }

      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const updateOverrideNotification = (notif_customer_id, channel, template_type, text) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_NOTIFICATION_OVERRIDE,
    data: {
      body: text,
      notification_type: template_type,
      notification_customer_id: notif_customer_id,
      channel: channel,
    }
  })

  dispatch({
    type: ActionTypes.CLOSE_NOTIFICATION_OVERRIDE_MODAL
  })
}

export const closeOverrideNotificationModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_NOTIFICATION_OVERRIDE_MODAL
  })
}



const getNotificationOptionsParam = (notifications)  => {
  if (notifications.length === 0)
    return {}

  const channels = ['email', 'sms']
  let notification_options = {
    "email": {},
    "sms": {}
  }

  notifications.forEach((customer_notifications) => {
    let notif_customer_type = `${customer_notifications.customer.type}_id`
    let notif_customer_id = customer_notifications.customer.id

    customer_notifications.notifications.forEach((n) => {
      let notif_type = n.email.type
      channels.forEach((channel) => {
        if (!notification_options[channel].hasOwnProperty(notif_type))
          notification_options[channel][notif_type] = {}
        if (!notification_options[channel][notif_type].hasOwnProperty(notif_customer_type))
          notification_options[channel][notif_type][notif_customer_type] = {}
        notification_options[channel][notif_type][notif_customer_type][notif_customer_id] = n[channel].enabled ? "on" : ''
      })
    })
  })
  return notification_options
}

const getNotificationOverrideParam = (notifications) => {
  if (notifications.length === 0)
    return {}

  const channels = ['email', 'sms']
  let override_notification = {
    "email": {},
    "sms": {}
  }
  notifications.forEach((customer_notifications) => {
    let notif_customer_type = `${customer_notifications.customer.type}_id`
    let notif_customer_id = customer_notifications.customer.id
    customer_notifications.notifications.forEach((n) => {
      let notif_type = n.email.type
      channels.forEach((channel) => {
        if (!override_notification[channel].hasOwnProperty(notif_type))
          override_notification[channel][notif_type] = {}
        if (!override_notification[channel][notif_type].hasOwnProperty(notif_customer_type))
          override_notification[channel][notif_type][notif_customer_type] = {}
        if (n[channel].is_overwritten)
          override_notification[channel][notif_type][notif_customer_type][notif_customer_id] = n[channel].overwritten_body
      })
    })
  })

  return {notification_options: override_notification}
}

export const changeQuickSearchValue = (value) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.QUICK_SEARCH,
    data: { quick_search_value: value },
  })
}

export const search = data => (dispatch, getState) => {
  const state = getState()
  if (Object.keys(data).includes('deleted') || Object.keys(data).includes('returned_to_owner')) {
    const dataURL = "/api/web/customers/" + state.dashboard.meta.settings.customer_id + "/lost_and_found/items/search"

    callAPI(dataURL, 'POST', {filters: JSON.stringify(data)}, new Headers({'Accept': 'application/json'}))
      .then(response => {
        dispatch({
          type: ActionTypes.SEARCH_BACKEND,
          data: {
            ...response,
            filters: data,
          },
        })
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
  else
    dispatch({
      type: ActionTypes.SET_SEARCH_FILTERS,
      data: { filters: data },
    })
}


export const uploadFile = data => (dispatch, getState) => {
  const state = getState()

  const dataURL = "/api/web/customers/"+state.dashboard.meta.settings.customer_id+"/attachments/bulk_create"
  if (data) {
    document.getElementById("file_zone_text").classList.toggle("d-none");
    document.getElementById("upload_spinner").classList.toggle("d-none");
    return new Promise((resolve, reject) => {
      fetch(
        dataURL,
        {
          method: 'POST',
          body: data,
        }
      )
        .then(response => Promise.all([response, response.json()]))
        .then(([response, json]) => {
          if (response.status === 200 && _.isEmpty(json.errorMessage)) {
            resolve(json)
          } else {
            reject(json.errorMessage, json)
          }
        })
        .catch(() => {
          reject()
        })
    }).then(response => {
      dispatch({
        type: ActionTypes.APPLY_FILES_TO_ITEM,
        data: response,
      })
      document.getElementById("file_zone_text").classList.toggle("d-none");
      document.getElementById("upload_spinner").classList.toggle("d-none");

    })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}


export const removeFile = file => (dispatch, getState) => {
  const state = getState()

  const dataURL = "/api/web/customers/"+state.dashboard.meta.settings.customer_id+"/attachments/"+file.id
  if (file.id) {
    let data = {
      customer_id: state.dashboard.meta.settings.customer_id,
      id: file.id,
    }
    return callAPI(dataURL, 'DELETE', data, new Headers({
      'Accept': 'application/json',
      'Content-type': 'application/json'
    }))
      .then(response => {
        if (response.errors) {
          response.errors.forEach((message, index) => window.gems.toast_message.init(message, "error"))
          return false;
        } else {
          window.gems.toast_message.init('File has been removed!', "success")
          dispatch({
            type: ActionTypes.REMOVE_FILE,
            data: file,
          })
          return true;
        }

      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}