import { showErrorNotification, callAPI } from 'shared/helpers'

export const ActionTypes = {
  FETCH_STOCK_ORDERS_DATA_REQUEST: 'FETCH_STOCK_ORDERS_DATA_REQUEST',
  CREATE_STOCK_ORDER: 'CREATE_STOCK_ORDER',
  NEW_STOCK_ORDER: 'NEW_STOCK_ORDER',
  UPLOAD_STOCK_ORDER: 'UPLOAD_STOCK_ORDER',
  EDIT_STOCK_ORDER: 'EDIT_STOCK_ORDER',
  DELETE_STOCK_ORDER: 'DELETE_STOCK_ORDER',
  UPDATE_STOCK_ORDER: 'UPDATE_STOCK_ORDER',
  SPLIT_STOCK_ORDER: 'SPLIT_STOCK_ORDER',
  CANCEL_STOCK_ORDER: 'CANCEL_STOCK_ORDER',
  OPEN_PLACE_STOCK_ORDER: 'OPEN_PLACE_STOCK_ORDER',
  OPEN_RECEIVE_STOCK_ORDER: 'OPEN_RECEIVE_STOCK_ORDER',
  OPEN_FINALIZE_STOCK_ORDER: 'OPEN_FINALIZE_STOCK_ORDER',
  OPEN_SPLIT_STOCK_ORDER: 'OPEN_SPLIT_STOCK_ORDER',
  OPEN_CANCEL_STOCK_ORDER: 'OPEN_CANCEL_STOCK_ORDER',
  OPEN_SHIP_DETAILS_STOCK_ORDER: 'OPEN_SHIP_DETAILS_STOCK_ORDER',
  OPEN_FILTER_MODAL: 'OPEN_FILTER_MODAL',
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  OPEN_UPLOAD_MODAL: 'OPEN_UPLOAD_MODAL',
  CLOSE_UPLOAD_MODAL: 'CLOSE_UPLOAD_MODAL',
  DISPLAY_UPLOAD_ERROR: 'DISPLAY_UPLOAD_ERROR',
  UPDATE_UPLOAD_JOB_ID: 'UPDATE_UPLOAD_JOB_ID',
  RETRY_UPLOAD: 'RETRY_UPLOAD',
  OPEN_LOGOS_MODAL: 'OPEN_LOGOS_MODAL',
  CLOSE_LOGOS_MODAL: 'CLOSE_LOGOS_MODAL',
  CLOSE_PLACE_MODAL: 'CLOSE_PLACE_MODAL',
  CLOSE_SHIP_DETAILS_MODAL: 'CLOSE_SHIP_DETAILS_MODAL',
  CLOSE_RECEIVE_MODAL: 'CLOSE_RECEIVE_MODAL',
  CLOSE_FINALIZED_MODAL: 'CLOSE_FINALIZED_MODAL',
  CLOSE_SPLIT_MODAL: 'CLOSE_SPLIT_MODAL',
  SWITCH_VIEW: 'SWITCH_VIEW',
  CLOSE_CANCEL_MODAL: 'CLOSE_CANCEL_MODAL',
  CLOSE_FILTER_MODAL: 'CLOSE_FILTER_MODAL',
  APPLY_FILTERS: 'APPLY_FILTERS',
  REMOVE_ALL_FILTERS: 'REMOVE_ALL_FILTERS',
  APPLY_FILES_TO_STOCK_ORDER: 'APPLY_FILES_TO_STOCK_ORDER',
  CHANGE_CALENDAR_FILTER_DATE: 'CHANGE_CALENDAR_FILTER_DATE',
  CHANGE_TILE_LAYOUT: 'CHANGE_TILE_LAYOUT',
  QUICK_SEARCH: 'QUICK_SEARCH',
  UPDATE_PIPEDRIVE_SORTING: 'UPDATE_PIPEDRIVE_SORTING',
  OPEN_LIST_VIEW_ADDITIONAL_FIELDS: 'OPEN_LIST_VIEW_ADDITIONAL_FIELDS',
  CLOSE_OPTIONAL_FIELDS_MODAL: 'CLOSE_OPTIONAL_FIELDS_MODAL',
  UPDATE_LIST_VIEW_OPTIONAL_FILEDS: 'UPDATE_LIST_VIEW_OPTIONAL_FILEDS',
  UPDATE_LIST_VIEW_SORTING: 'UPDATE_LIST_VIEW_SORTING',
  DELETE_LOGO: 'DELETE_LOGO'
}

export const fetchStockOrdersData = () => (dispatch, getState) => {
  const state = getState()

  const dataURL = '/api/web/stock_orders'
  const params = { customer_id: state.stock_order.customer_id }

  callAPI(dataURL, 'GET', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.FETCH_STOCK_ORDERS_DATA_REQUEST,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const uploadFile = data => (dispatch, getState) => {
  const state = getState()

  const dataURL = '/api/web/stock_orders/upload_files'
  if (data) {
    // document.getElementById("upload_spinner").classList.remove("d-none");
    return new Promise((resolve, reject) => {
      fetch(
        dataURL,
        {
          method: 'POST',
          body: data,
        }
      )
        .then(response => Promise.all([response, response.json()]))
        .then(([response, json]) => {
          if (response.status === 200 && _.isEmpty(json.errorMessage)) {
            resolve(json)
          } else {
            reject(json.errorMessage, json)
          }
        })
        .catch(() => {
          reject()
        })
    }).then(response => {
      dispatch({
        type: ActionTypes.APPLY_FILES_TO_STOCK_ORDER,
        data: response,
      })
      document.getElementById("upload_spinner").classList.add("d-none");
    })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const createStockOrder = (data, open_place_popup) => (dispatch, getState) => {
  const state = getState()
  const dataURL = '/api/web/stock_orders?open_place_popup=' + open_place_popup
  if (data) {
    data.customer_id = state.stock_order.customer_id
    callAPI(dataURL, 'POST', data, new Headers({ 'Accept': 'application/json', 'Content-type': 'application/json' }))
      .then(response => {
        dispatch({
          type: ActionTypes.CREATE_STOCK_ORDER,
          data: response,
          open_place_popup: open_place_popup
        })
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const newStockOrder = (similarStockOrder) => (dispatch, getState) => {
  const state = getState()

  const dataURL = '/api/web/stock_orders/new'
  const params = { customer_id: state.stock_order.customer_id, similar_stock_order: similarStockOrder }
  // if(state.stock_order.vendors_list.length > 0 && state.stock_order.categories_list.length > 0)
  // {
  //   dispatch({
  //     type: ActionTypes.NEW_STOCK_ORDER,
  //     data: {vendors_list: state.stock_order.vendors_list, categories_list: state.stock_order.categories_list},
  //   })
  // }
  // else
  // {
  callAPI(dataURL, 'GET', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.NEW_STOCK_ORDER,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
  // }
}

export const uploadStockOrder = () => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.UPLOAD_STOCK_ORDER
  })
  const state = getState()
}

export const uploadStockOrderFile = formData => (dispatch, getState) => {
  const state = getState()

  const dataURL = '/api/web/stock_orders/upload_stock_order'
  if (formData) {
    document.getElementById("uploadInfoText").classList.add('d-none')
    // document.getElementById("uploadInstructions").classList.add('d-none')
    document.getElementById("uploadVendorSelect").classList.add('d-none')
    document.getElementById("uploadAiEngineSelect").classList.add('d-none')

    fetch(
      dataURL,
      {
        method: 'POST',
        body: formData,
      })
      .then(response => response.json())
      .then((result) => {
        if (_.isEmpty(result.errorMessage)) {
          const url_check_job_status = '/customers/'+result.customer_id+'/jobs/'+result.job_id+'/poll'
        
          let processStatusIndex = 0
          const processStatus = {
            0: "Uploading your PDF",
            1: "Initiating AI processing",
            2: "Reading PDF",
            3: "Extracting order details",
            4: "Extracting products",
            5: "Extracting product attributes",
            6: "Extracting sizing lines",
            7: "Preparing Stock Order Draft"
          }
        
          dispatch({
            type: ActionTypes.UPDATE_UPLOAD_JOB_ID,
            data: result
          })
                
          function refresh() {
            $.ajax({
              url: url_check_job_status,
              cache: false,
              type: "GET",
              dataType: "json",
              data: {id: result.job_id},
              success: function(job_data){
                if (job_data['status'] !== 'finalized' && job_data['status'] !== 'errors') {
                  document.getElementById("job_status_text").innerHTML = processStatus[processStatusIndex]
                  if (processStatusIndex < 7){
                    processStatusIndex++
                  }
                  setTimeout(refresh, 4000);
                }
                else {
                  if (job_data['status'] == "errors"){
                    dispatch({
                      type: ActionTypes.DISPLAY_UPLOAD_ERROR,
                      data: job_data["description"]
                    })
                  }
                  else {
                    dispatch({
                      type: ActionTypes.CLOSE_UPLOAD_MODAL
                    })
        
                    dispatch({
                      type: ActionTypes.NEW_STOCK_ORDER,
                      data: JSON.parse(job_data["result"]),
                    })
                  }
                }
              }
            });
          }
        
          setTimeout(refresh, 4000);
        }
      })
      .catch(() => {
        //close upload stock order popup
        dispatch({
          type: ActionTypes.CLOSE_UPLOAD_MODAL
        })
        alert("An error has occurred while processing this stock order file.")
      })
  }
}

export const retryUploadStockOrder = (jobId, formData) => (dispatch, getState) => {
  const state = getState()
  if (jobId) {
    const dataURL = '/api/web/stock_orders/retry_stock_order_upload'
    const params = { customer_id: state.stock_order.customer_id, id: jobId }

    callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        if (response.successful_retry){
          dispatch({
            type: ActionTypes.RETRY_UPLOAD
          })
          const url_check_job_status = '/customers/'+state.stock_order.customer_id+'/jobs/'+jobId+'/poll'
                  
          let processStatusIndex = 0
          const processStatus = {
            0: "Uploading your PDF",
            1: "Initiating AI processing",
            2: "Reading PDF",
            3: "Extracting order details",
            4: "Extracting products",
            5: "Extracting product attributes",
            6: "Extracting sizing lines",
            7: "Preparing Stock Order Draft"
          }
                        
          function refresh() {
            $.ajax({
              url: url_check_job_status,
              cache: false,
              type: "GET",
              dataType: "json",
              data: {id: jobId},
              success: function(job_data){
                if (job_data['status'] !== 'finalized' && job_data['status'] !== 'errors') {
                  document.getElementById("job_status_text").innerHTML = processStatus[processStatusIndex] || processStatus[7]
                  if (processStatusIndex < 7){
                    processStatusIndex++
                  }
                  setTimeout(refresh, 6000);
                }
                else {
                  if (job_data['status'] == "errors"){
                    dispatch({
                      type: ActionTypes.DISPLAY_UPLOAD_ERROR,
                      data: job_data["description"]
                    })
                  }
                  else {
                    dispatch({
                      type: ActionTypes.CLOSE_UPLOAD_MODAL
                    })
        
                    dispatch({
                      type: ActionTypes.NEW_STOCK_ORDER,
                      data: JSON.parse(job_data["result"]),
                    })
                  }
                }
              }
            });
          }
        
          setTimeout(refresh, 4000);
        }
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const editStockOrder = id => (dispatch, getState) => {
  const state = getState()
  if (id) {
    const dataURL = "/api/web/stock_orders/" + id + "/edit"
    const params = { customer_id: state.stock_order.customer_id, id: id }

    callAPI(dataURL, 'GET', params, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        window.history.pushState(window.location.href, 'Title', window.location.href.split('?')[0]);
        dispatch({
          type: ActionTypes.EDIT_STOCK_ORDER,
          data: response,
        })
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const deleteLogo = data => (dispatch, getState) => {
  const state = getState()
  const dataURL = "/api/web/customers/"+state.stock_order.customer_id+"/stock_orders/logos/"+data+"/delete_logo"
  
  callAPI(dataURL, 'DELETE', data, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.DELETE_LOGO,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const updateStockOrder = data => (dispatch, getState) => {
  const state = getState()

  if (state.stock_order.item.id) {
    const dataURL = "/api/web/stock_orders/" + state.stock_order.item.id + "?customer_id=" + state.stock_order.customer_id
    // const params = {customer_id: state.stock_order.customer_id, id: id}
    callAPI(dataURL, 'PATCH', data, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch({
          type: ActionTypes.UPDATE_STOCK_ORDER,
          data: response,
        })
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const restoreStockOrder = id => (dispatch, getState) => {
  const state = getState()
  if (id) {
    const dataURL = "/api/web/stock_orders/" + id + "/restore"
    const params = { customer_id: state.stock_order.customer_id, id: id }

    callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch({
          type: ActionTypes.UPDATE_STOCK_ORDER,
          data: response,
        })
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const deleteStockOrder = id => (dispatch, getState) => {
  const state = getState()
  if (id) {
    const dataURL = "/api/web/stock_orders/" + id + "?customer_id=" + state.stock_order.customer_id
    const params = { customer_id: state.stock_order.customer_id, id: id }

    callAPI(dataURL, 'DELETE', params, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch({
          type: ActionTypes.DELETE_STOCK_ORDER,
          data: response,
        })
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const openPlaceStockOrder = id => (dispatch, getState) => {
  const state = getState()
  if (id) {
    const dataURL = "/api/web/stock_orders/" + id + "/open_place_stock_order"
    const params = { customer_id: state.stock_order.customer_id, id: id }

    callAPI(dataURL, 'GET', params, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch({
          type: ActionTypes.OPEN_PLACE_STOCK_ORDER,
          data: response,
        })
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const openShipDetailsStockOrder = id => (dispatch, getState) => {
  const state = getState()
  if (id) {
    const dataURL = "/api/web/stock_orders/" + id + "/open_ship_details_stock_order"
    const params = { customer_id: state.stock_order.customer_id, id: id }

    callAPI(dataURL, 'GET', params, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch({
          type: ActionTypes.OPEN_SHIP_DETAILS_STOCK_ORDER,
          data: response,
        })
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}


export const openReceiveStockOrder = id => (dispatch, getState) => {
  const state = getState()
  if (id) {
    const dataURL = "/api/web/stock_orders/" + id + "/open_receive_stock_order"
    const params = { customer_id: state.stock_order.customer_id, id: id }

    callAPI(dataURL, 'GET', params, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch({
          type: ActionTypes.OPEN_RECEIVE_STOCK_ORDER,
          data: response,
        })
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const openFinalizeStockOrder = id => (dispatch, getState) => {
  const state = getState()
  if (id) {
    const dataURL = "/api/web/stock_orders/" + id + "/open_finalize_stock_order"
    const params = { customer_id: state.stock_order.customer_id, id: id }

    callAPI(dataURL, 'GET', params, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch({
          type: ActionTypes.OPEN_FINALIZE_STOCK_ORDER,
          data: response,
        })
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const openSplitStockOrder = id => (dispatch, getState) => {
  const state = getState()
  if (id) {
    const dataURL = "/api/web/stock_orders/" + id + "/open_split_cancel_stock_order"
    const params = { customer_id: state.stock_order.customer_id, id: id, action_type: "split" }

    callAPI(dataURL, 'GET', params, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch({
          type: ActionTypes.OPEN_SPLIT_STOCK_ORDER,
          data: response,
        })
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const createSimilarOrder = id => (dispatch, getState) => {
  const state = getState()
  if (id) {

  }
}

export const splitStockOrder = data => (dispatch, getState) => {
  const state = getState()

  if (state.stock_order.item.id) {
    const dataURL = "/api/web/stock_orders/" + state.stock_order.item.id + "/split?customer_id=" + state.stock_order.customer_id
    // const params = {customer_id: state.stock_order.customer_id, id: id}

    callAPI(dataURL, 'PATCH', data, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch({
          type: ActionTypes.SPLIT_STOCK_ORDER,
          data: response,
        })
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const openCancelStockOrder = id => (dispatch, getState) => {
  const state = getState()
  if (id) {
    const dataURL = "/api/web/stock_orders/" + id + "/open_split_cancel_stock_order"
    const params = { customer_id: state.stock_order.customer_id, id: id, action_type: "cancel" }

    callAPI(dataURL, 'GET', params, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch({
          type: ActionTypes.OPEN_CANCEL_STOCK_ORDER,
          data: response,
        })
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const cancelStockOrder = data => (dispatch, getState) => {
  const state = getState()

  if (state.stock_order.item.id) {
    const dataURL = "/api/web/stock_orders/" + state.stock_order.item.id + "/cancel?customer_id=" + state.stock_order.customer_id
    // const params = {customer_id: state.stock_order.customer_id, id: id}

    callAPI(dataURL, 'PATCH', data, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch({
          type: ActionTypes.CANCEL_STOCK_ORDER,
          data: response,
        })
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const openListViewOptionalFieldsModal = id => (dispatch, getState) => {
  const state = getState()
  if (id)
  {
    const dataURL = "/api/web/stock_orders/"+id+"/open_list_view_optional_fields_modal"
    const params = {customer_id: state.stock_order.customer_id, id: id}

    callAPI(dataURL, 'GET', params, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch({
          type: ActionTypes.OPEN_LIST_VIEW_ADDITIONAL_FIELDS,
          data: response,
        })
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const updateStockOrdersListViewOptionalFields = data => (dispatch, getState) => {
  const state = getState()
  const dataURL = "/api/web/stock_orders/update_optional_fields?customer_id="+state.stock_order.customer_id

  dispatch({
    type: ActionTypes.UPDATE_LIST_VIEW_OPTIONAL_FILEDS,
    data: data
  })

  callAPI(dataURL, 'POST', data, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const search = data => (dispatch, getState) => {
  const state = getState()

  const dataURL = "/api/web/stock_orders/search?customer_id=" + state.stock_order.customer_id
  // const params = {customer_id: state.stock_order.customer_id, id: id}

  callAPI(dataURL, 'POST', data, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.FETCH_STOCK_ORDERS_DATA_REQUEST,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}


export const updatePipedriveSorting = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_PIPEDRIVE_SORTING,
    data: data,
  })
}

export const updateListViewSorting = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_LIST_VIEW_SORTING,
    data: data,
  })
}

export const openModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.OPEN_MODAL,
    data: { modal_open: true },
  })
}

export const closeModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_MODAL
  })
}
export const openUploadModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.OPEN_UPLOAD_MODAL,
    data: { modal_upload_open: true },
  })
}

export const closeUploadModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_UPLOAD_MODAL
  })
}

export const closeLogosModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_LOGOS_MODAL
  })
}

export const openLogosModal = (item, category_id) => (dispatch) => {
  dispatch({
    type: ActionTypes.OPEN_LOGOS_MODAL,
    data: {item: item, category_id: category_id}
    
  })
}

export const closePlaceModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_PLACE_MODAL
  })
}

export const closeShipDetailsModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_SHIP_DETAILS_MODAL
  })
}

export const closeReceiveModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_RECEIVE_MODAL
  })
}
export const closeFinalizeModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_FINALIZED_MODAL
  })
}
export const closeSplitModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_SPLIT_MODAL
  })
}
export const closeFilterModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_FILTER_MODAL
  })
}

export const switchView = (view) => (dispatch) => {
  dispatch({
    type: ActionTypes.SWITCH_VIEW,
    data: view
  })
}

export const closeCancelModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_CANCEL_MODAL
  })
}

export const closeOptionalFieldsModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_OPTIONAL_FIELDS_MODAL
  })
}

export const openCategoryFilterModal = () => (dispatch, getState) => {
  const state = getState()

  const dataURL = '/api/web/stock_orders/new'
  const params = { customer_id: state.stock_order.customer_id }
  if (state.stock_order.categories_list_for_filter.length > 0) {
    dispatch({
      type: ActionTypes.OPEN_FILTER_MODAL,
      data: { categories_list_for_filter: state.stock_order.categories_list_for_filter },
    })
  }
  else {
    callAPI(dataURL, 'GET', params, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch({
          type: ActionTypes.OPEN_FILTER_MODAL,
          data: response,
        })
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const applyFilters = data => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.APPLY_FILTERS,
    data: { filters_list: data },
  })
}
export const removeAllFilters = () => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.REMOVE_ALL_FILTERS
  })
}
export const changeCalendarFilterDate = (filter_date) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.CHANGE_CALENDAR_FILTER_DATE,
    data: { calendar_filter_date: filter_date },
  })
}
export const changeTileLayout = (condensed_tile_layout_bool) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.CHANGE_TILE_LAYOUT,
    data: { condensed_tile_layout: condensed_tile_layout_bool },
  })
}

export const changeQuickSearchValue = (value) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.QUICK_SEARCH,
    data: { quick_search_value: value },
  })
}
