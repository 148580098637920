import { connect } from 'react-redux'
import Home from '../components/Home'
import {load_component_data, reorder_tiles} from '../actions'

const mapStateToProps = (state) => ({
  components: state.dashboard.components,
  customer_id: state.dashboard.customer_id,
  to: state.dashboard.to,
  from: state.dashboard.from,
  tiles: state.dashboard.tiles,
  moduleKey: state.dashboard.module_key,
  refresh_date: state.dashboard.refresh_date,
  isLoading: state.dashboard.isLoading,
})

const mapDispatchToProps = (dispatch) => ({
  load_component_data: data => dispatch(load_component_data(data)),
  reorder_tiles: data => dispatch(reorder_tiles(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)