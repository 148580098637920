import React from 'react'
import {Table} from 'react-bootstrap';
import HeaderTable from "./HeaderTable";
import ItemRow from "./ItemRow";
import {SortStatusPriority} from "./constants";
import {updateNotifications} from "../actions";

function ListBody(props) {
  const {
    items,
    categories,
    current_locations,
    entered_by_managers,
    attachments,

    in_fields,
    fields_column_mapping,
    sort_column,
    sort_direction,
    date_format,

    openListViewOptionalFieldsModal,
    updateListViewSorting,

    editItem,
    transitionAction,
    openClaimItem,
    openReturnToOwnerItem,
    openMarkAsFoundItem,
    getMembersDropdownData,
    updateNotifications,
  } = props



  let items_sorted = items.length > 0 ? _.sortBy(Object.values(items), [function (o) { return sort_column === 'status' ? SortStatusPriority[o['attributes'][sort_column]] : o['attributes'][sort_column]; }]) : items

  return (<div className='col-12 h-100 p-0'>
      <div className="table-responsive tableFixHead h-100">
        <Table striped className="table fields_table align-middle background_white table-striped">
          <HeaderTable
            in_fields={in_fields}
            fields_column_mapping={fields_column_mapping}
            sort_column={sort_column}
            sort_direction={sort_direction}
            openListViewOptionalFieldsModal={openListViewOptionalFieldsModal}
            updateListViewSorting={updateListViewSorting}
          />

          <tbody>
          {
            items_sorted.length === 0 && <tr>
              <td></td>
              <td colSpan={in_fields.length} className={'ps-4'}>
                No items
              </td>
            </tr>
          }
          {(sort_direction === 'desc' ? items_sorted.reverse() : items_sorted).map((lost_found_item, _) => {
            let attachments_ids = Array.from(lost_found_item.relationships?.attachments?.data).map(a => a.id); // Example of a local variable

            return (
              <ItemRow
                key={lost_found_item.id}
                item={lost_found_item}
                category={categories[lost_found_item.relationships?.category?.data?.id]}
                current_location={current_locations[lost_found_item.relationships?.current_location?.data?.id]}
                entered_by={entered_by_managers[lost_found_item.relationships?.entered_by?.data?.id]}
                attachments={Object.values(attachments).filter((attachment) => attachments_ids.includes(attachment.id))}
                in_fields={in_fields}
                date_format={date_format}

                editItem={editItem}
                transitionAction={transitionAction}
                openClaimItem={openClaimItem}
                openReturnToOwnerItem={openReturnToOwnerItem}
                openMarkAsFoundItem={openMarkAsFoundItem}
                getMembersDropdownData={getMembersDropdownData}
                updateNotifications={updateNotifications}
              />
            );
          })}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default ListBody
