export const ViewFilter = {
  LOST_AND_FOUND: {label: "Lost and Found Items", value: "LOST_AND_FOUND"},
  LOST: {label: "Lost Items", value: "LOST"},
  FOUND: {label: "Found Items", value: "FOUND"},
}


export const CustomerTypeList = [
  {value: 'Member', label: 'Member'},
  {value: 'Guest', label: 'Guest'},
  {value: 'Employee', label: 'Staff'},
];


export const StatusLabels = {
  LOST_CLAIMED: {
    label: 'Lost',
    color: 'red',
  },
  LOST_CLAIMED_DELETED: {
    label: 'Lost and Deleted',
    color: 'red',
  },
  FOUND_UNCLAIMED: {
    label: 'Found and Unclaimed',
    color: 'black',
  },
  FOUND_UNCLAIMED_DELETED: {
    label: 'Found and Deleted',
    color: 'red',
  },
  FOUND_CLAIMED: {
    label: 'Found and Claimed',
    color: 'orange',
  },
  FOUND_CLAIMED_DELETED: {
    label: 'Found and Deleted',
    color: 'red',
  },
  FOUND_RETURNED_TO_OWNER: {
    label: 'Found and Returned to Owner',
    color: 'green',
  },
  FOUND_RETURNED_TO_OWNER_DELETED: {
    label: 'Found and Deleted',
    color: 'red',
  },
};

export const DropdownActionsByStatus = {
  LOST_CLAIMED: ['mark_as_found', 'send_email', 'edit', 'remove_item'],
  LOST_CLAIMED_DELETED: ['restore_item'],
  FOUND_UNCLAIMED: ['claim_item', 'return_to_owner', 'send_email', 'edit', 'remove_item'],
  FOUND_UNCLAIMED_DELETED: ['restore_item'],
  FOUND_CLAIMED: ['return_to_owner', 'send_email', 'edit', 'remove_item', 'revert_to_previous_state'],
  FOUND_CLAIMED_DELETED: ['restore_item'],
  FOUND_RETURNED_TO_OWNER: ['edit', 'remove_item', 'revert_to_previous_state'],
  FOUND_RETURNED_TO_OWNER_DELETED: ['restore_item'],
};


export const QuickActionByStatus = {
  FOUND_CLAIMED: ['return_to_owner'],
};

export const ActionsConfig = {
  edit: {
    label: 'Edit', icon: 'fa fa-pencil', colorClass: 'color_black',
  },
  send_email: {
    label: 'Send Email', icon: 'icon-menu-communication-bold', colorClass: 'color_black',
  },
  remove_item: {
    label: 'Delete', icon: 'fa fa-close', colorClass: 'color_red',
  },
  restore_item: {
    label: 'Restore', icon: 'icon-ccw', colorClass: 'color_green',
  },
  mark_as_found: {
    label: 'Mark as Found', icon: 'fa fa-forward', colorClass: 'color_black',
  },
  claim_item: {
    label: 'Found and Claimed', icon: 'fa fa-forward', colorClass: 'color_black',
  },
  return_to_owner: {
    label: 'Return to Owner', icon: 'fa fa-forward', colorClass: 'color_black',
  },
  revert_to_previous_state: {
    label: 'Revert to Previous Status', icon: 'icon-backward', colorClass: 'color_red',
  },
};

export const initialEntry = (lost_date) => {
  return lost_date ? 'lost' : 'found'
}

export const EditStates = (initial_entry) => {
  return {
    LOST_CLAIMED: {
      state_transition_date: 'lost_date',
      editable: ["lost_date"],
      mandatory: ['lost_date'],
      restricted: ['claimed_date', 'claimed_by_entity_id', 'claimed_by_entity_type'],
      autoFill: {
        lost_date: "claimed_date",
        lost_by_entity_id: "claimed_by_entity_id",
        lost_by_entity_type: "claimed_by_entity_type"
      },
      nextState: "FOUND_CLAIMED",
    },
    FOUND_UNCLAIMED: {
      state_transition_date: 'found_date',
      editable: ["found_date"],
      mandatory: ['found_date'],
      restricted: [],
      autoFill: {},
      nextState: "FOUND_CLAIMED",
    },
    FOUND_CLAIMED: {
      state_transition_date: (initial_entry === 'lost' ? 'found_date' : 'claimed_date'),
      editable: [(initial_entry === 'lost' ? 'found_date' : 'claimed_date')],
      mandatory: [],
      restricted: [],
      autoFill: {},
      prevState: ["LOST_CLAIMED", "FOUND_UNCLAIMED"],
      nextState: "FOUND_RETURNED_TO_OWNER",
    },
    FOUND_RETURNED_TO_OWNER: {
      state_transition_date: 'pickup_date',
      editable: ["pickup_date"],
      mandatory: [],
      restricted: [],
      autoFill: {},
      prevState: "FOUND_CLAIMED",
    },
  }
};

export const FieldLabels = {
  lost_date: "Lost Date",
  found_date: "Found Date",
  claimed_date: "Claimed Date",
  pickup_date: "Return to Owner Date",
  lfin: "Item Number",
  lost_by_entity_id: 'Lost By',
  found_by_entity_id: 'Found By',
  claimed_by_entity_id: 'Claimed By',
  description: 'Description',
  current_location: 'Current Location',
  found_location: 'Found Location',
}

export const SortStatusPriority = {
  LOST_CLAIMED: 1,
  LOST_CLAIMED_DELETED: 1,
  FOUND_UNCLAIMED: 2,
  FOUND_UNCLAIMED_DELETED: 2,
  FOUND_CLAIMED: 3,
  FOUND_CLAIMED_DELETED: 3,
  FOUND_RETURNED_TO_OWNER: 4,
  FOUND_RETURNED_TO_OWNER_DELETED: 4,
}

export const selectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderRadius: 0,
    boxShadow: "none",
    backgroundColor: "white",
    color: "#212529",
    width: "100%",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected || state.isFocused ? '#FDEDE9' : 'inherit',
    color: state.isSelected ? '#212529' : 'inherit',
  }),
  borderRadius: 0,
  groupHeading: (base) => ({
    ...base,
    marginRight: '10px',
    marginLeft: '10px',
    border: '1px solid #F7F7F7',
    borderWidth: 'thin'
  })
}

Object.freeze(ViewFilter);
Object.freeze(CustomerTypeList);
Object.freeze(StatusLabels);
Object.freeze(DropdownActionsByStatus);
Object.freeze(QuickActionByStatus);
Object.freeze(ActionsConfig);
Object.freeze(FieldLabels);
Object.freeze(selectStyles);