import React, {useState} from 'react';
import Modal, {ModalBody, ModalFooter, ModalHeader} from '../Modal';
import {Col, Form, Row} from 'react-bootstrap';
import Summary from "../Summary";
import moment from 'moment';
import {validateFields} from "../../actions";
import FormBuilder from "../FormBuilder";
import {NotificationTypes} from "../../../../shared/components/notifications/constants";


function MarkAsFoundItemModal(props) {
  const initialState = {
    found_date: moment(),
    found_by_entity_type: 'Member',
    current_location_id: props.default_current_location_id,
    found_location: '',
    lost_by_entity_id: props.item.attributes?.lost_by_entity_id,
    lost_by_entity_type: props.item.attributes?.lost_by_entity_type,
    internal_notes: props.item.attributes?.internal_notes,
  };

  const [state, setState] = useState(initialState);

  const {
    found_by_entity_id,
    found_date,
    found_by_entity_type,
    current_location_id,
    found_location,
    internal_notes,
  } = state;

  const {
    date_format,
    customer_id,

    members_dropdown_list,
    guests_dropdown_list,
    employees_dropdown_list,
    managers_dropdown_list,
    current_locations_dropdown_list,

    item,
    markAsFoundItemIsOpen,
    notification_communication_data,

    closeModal,
    transitionAction,
    createEmployee,
    createGuest,
    updateNotifications,
    updateContactInfo,
    changeNotificationSendOption,
    getOverrideNotification,
    uploadFile,
    removeFile,
  } = props;

  const required_fields = ['found_date', 'found_by_entity_id'];

  const fields_list = ['found_date', 'found_by_entity_id', 'found_location', 'current_location'];


  const handleClose = () => closeModal();

  const handleInputChange = (name, value) => setState((prev) => ({...prev, [name]: value}));

  const handleCustomerTypeChange = (field, value) => {
    setState((prev) => ({...prev, [`${field}_entity_type`]: value, [`${field}_entity_id`]: null}));
  }

  const handleDateChange = (name, date) => handleInputChange(name, date);


  const handleCreateEmployee = (fields, entity_name) => {
    createEmployee(fields, entity_name).then((response => {
      setState({...state, [entity_name]: response})

      handleUpdateNotifications({type: NotificationTypes[entity_name], customer: {type: 'employee', id: response}})
    }))
  }

  const handleCreateGuest = (fields, entity_name) => {
    createGuest(fields, entity_name).then((response => {
      setState({...state, [entity_name]: response})

      handleUpdateNotifications({type: NotificationTypes[entity_name], customer: {type: 'guest', id: response}})
    }))
  }

  const handleGetOverrideNotification = (channel, template_type) => {
    getOverrideNotification({...props.item.attributes, ...state}, props.item.attributes?.lost_by_entity_id, channel, template_type)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateFields(state, required_fields);

    if (Object.keys(validationErrors).length > 0) {
      Object.values(validationErrors).forEach((message, index) => window.gems.toast_message.init(message, "warning"))
    } else {
      console.log("Form submitted successfully", state);
      transitionAction(props.item.id, 'mark_as_found', {
        found_date: found_date,
        found_by_entity_type: found_by_entity_type,
        found_by_entity_id: found_by_entity_id,
        current_location_id: current_location_id,
        found_location: found_location,
        internal_notes: internal_notes
      })
    }
  };

  const handleUpdateNotifications = (notification) => {
    if (notification.customer.id)
      updateNotifications([notification])
  }

  const title = 'MARK ITEM AS FOUND';
  const submitButtonName = 'Save';

  return (<div className="modal_mark_as_found_item_container">
    <Modal isOpen={markAsFoundItemIsOpen} scrollable={true} className="MarkAsFoundItemModal min-height-body-modal"
           dialogClassName="modal-dialog-centered">
      <ModalHeader>
        <div className="row">
          <div className="col">
            <h4 className="modal-title form_new_edit no-margin">
              <span>{title} </span>
              {props.item.attributes?.lost_by && <span>
                 - #{props.item.attributes?.lfin} - Lost By: {props.item.attributes?.lost_by?.full_name}
              </span>}
            </h4>
          </div>
        </div>
      </ModalHeader>
      <ModalBody style={{display: 'flex', padding: '0'}}>
        <div className="col overflow-auto pe-3 ps-1 pt-2">

          <Row>
            <Col className='color_orange mb-4'>
              <div className='text-center icon-item-returned font_size_50'></div>
              <div className='text-center'>
                <label className='bold'>
                  The item has been found and turned in
                </label>
              </div>
            </Col>
          </Row>
          <FormBuilder
            form_action={'mark_as_found'}
            fields_list={fields_list}
            item={state}
            members={members_dropdown_list}
            employees={employees_dropdown_list}
            guests={guests_dropdown_list}
            managers={managers_dropdown_list}
            current_locations={current_locations_dropdown_list}
            date_format={date_format}

            handleCustomerTypeChange={handleCustomerTypeChange}
            handleInputChange={handleInputChange}
            handleCreateEmployee={handleCreateEmployee}
            handleCreateGuest={handleCreateGuest}
            handleDateChange={handleDateChange}
            handleUpdateNotifications={handleUpdateNotifications}
            getMembersDropdownData={props.getMembersDropdownData}
          />
        </div>
        <div className="col background_grey p-2">
          <Summary customer_id={customer_id}
                   item={item}
                   internal_notes={internal_notes}
                   selectedItemAssociationIds={props.selectedItemAssociationIds}
                   activity_logs={props.activity_logs}
                   attachments={props.attachments}
                   email_delivery_reports={props.email_delivery_reports}
                   isRestricted={true}
                   action={'mark_as_found'}
                   notification_communication_data={notification_communication_data}


                   handleInputChange={handleInputChange}
                   updateContactInfo={updateContactInfo}
                   changeNotificationSendOption={changeNotificationSendOption}
                   getOverrideNotification={handleGetOverrideNotification}
                   uploadFile={uploadFile}
                   removeFile={removeFile}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className="float-start btn gems_custom_button"
          aria-label="Close"
          onClick={handleClose}
        >
          Close
        </button>

        <button
          className={`btn gems_custom_button gems_orange_button float-end`}
          onClick={(event) => handleSubmit(event)}
        >
          {submitButtonName}
        </button>
      </ModalFooter>
    </Modal>
  </div>);
}

export default MarkAsFoundItemModal