import React, {useRef, useState} from 'react';
import Modal, {ModalBody, ModalFooter, ModalHeader} from './Modal';
import {Col, Form} from 'react-bootstrap';

function ListViewOptionalFieldsModal(props) {

  const initialState = {
    ...props
  }

  const dragItem = useRef();
  const dragOverItem = useRef();

  const [state, setState] = useState(initialState);

  const {configureFieldsIsOpen, fields, in_fields, mandatory_fields} = props;

  const handleFieldChange = (event) => {
    setState({...state, [in_fields]: []})
    let in_fields_copy = [...state.in_fields]
    let index = in_fields_copy.indexOf(event.target.name)
    if (index !== -1) {
      in_fields_copy.splice(index, 1)
      setState({...state, in_fields: in_fields_copy})
    } else {
      setState({...state, in_fields: [...state.in_fields, `${event.target.name}`]})
    }
  }

  const handleClose = (event) => {
    props.closeOptionalFieldsModal()
  }

  const handleSubmit = (event) => {
    let params = {in_fields: JSON.stringify(state.in_fields), fields: JSON.stringify(state.fields)}

    props.updateListViewOptionalFields(params)
  }

  const onDragStart = (e, position) => {
    dragItem.current = position;
  };

  const onDragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...state.fields];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;

    const in_fields = copyListItems.map(field => field[0]).filter((item) => state.in_fields.includes(item))

    setState({...state, fields: copyListItems, in_fields: in_fields})
  };

  return (configureFieldsIsOpen && <div id="optionalFieldsModalDiv">
    <Modal isOpen={configureFieldsIsOpen} scrollable={true} className="optionalFieldsModalSize"
           dialogClassName="modal-dialog-centered">
      <ModalHeader className="header">
        <h4 className="modal-title form_new_edit m-0">OPTIONAL FIELDS</h4>
      </ModalHeader>
      <ModalBody>
        {state.fields.map((fields_formatted, index) =>
          <div className="row"
               key={index}
               draggable
               onDragStart={(e) => onDragStart(e, index)}
               onDragEnter={(e) => onDragEnter(e, index)}
               onDragEnd={drop}
          >
            <Form.Group as={Col} className="mb-3 d-flex align-items-center" controlId={fields_formatted[0]}>
              <i className='fa fa-bars me-2'/>
              <Form.Check
                name={fields_formatted[0]}
                value={in_fields.indexOf(fields_formatted[0]) > -1 || false}
                defaultChecked={in_fields.indexOf(fields_formatted[0]) > -1 || false}
                type='checkbox'
                id={fields_formatted[0]}
                label={fields_formatted[1]}
                className="d-flex align-items-center flex-grow-1"
                onChange={handleFieldChange}
                disabled={mandatory_fields.indexOf(fields_formatted[0]) > -1}
              />
            </Form.Group>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <button
          className="float-start btn gems_custom_button "
          aria-label="Close"
          onClick={handleClose}
        >
          Close
        </button>
        <button
          className="btn gems_custom_button gems_orange_button float-end"
          onClick={handleSubmit}
        >
          Save
        </button>
      </ModalFooter>
    </Modal>
  </div>);
}

export default ListViewOptionalFieldsModal