import {connect} from 'react-redux'
import ViewFilterComponent from "../components/ViewFilterComponent";
import {changeViewFilter} from "../actions";


const mapStateToProps = (state) => ({
  viewFilter: state.dashboard.uiState.viewFilter,
})

const mapDispatchToProps = (dispatch) => ({
  changeViewFilter: (view) => dispatch(changeViewFilter(view))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewFilterComponent)