import ReorderTilesModal from "./ReorderTilesModal";
import React, {useState} from "react";

const ReorderTilesComponent = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSave = (newTiles) => {
        setIsModalOpen(false);
        props.reorder_tiles({ titles: newTiles });
    };

    return (
        <div>
            <button className="btn gems_custom_button gems_orange_button" onClick={handleOpenModal}>
                Reorder Tiles
            </button>

            <ReorderTilesModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onSave={handleSave}
                tiles={props.tiles}
            />
        </div>
    );
};

export default ReorderTilesComponent;
