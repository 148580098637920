import React from 'react'

function HeaderTable(props) {
  const {
    in_fields, fields_column_mapping, sort_column, sort_direction,

    openListViewOptionalFieldsModal, updateListViewSorting,
  } = props


  const tableHeadColumns = in_fields.map((in_field, index) => <th key={in_field}
                                                                  className={sort_column === in_field ? sort_direction === "desc" ? "color_orange icon-sort-desc" : "color_orange icon-sort-asc" : "icon-sort"}
                                                                  style={{
                                                                    whiteSpace: "nowrap",
                                                                    paddingLeft: "15px",
                                                                    cursor: "pointer"
                                                                  }}
                                                                  onClick={() => handleUpdateListViewSorting(in_field, sort_direction === "asc" ? "desc" : "asc")}>{(fields_column_mapping[in_field] || "").toUpperCase()}
  </th>);

  const handleOpenOptionalFields = (event) => {
    openListViewOptionalFieldsModal()
  }

  const handleUpdateListViewSorting = (col, dir) => {
    let data = {
      sort_column: col, sort_direction: dir
    }
    updateListViewSorting(data)
  }


  return (<thead className="table-lightgrey">
  <tr className="color_black">
    <th className="text_align_center ps-4">
      <div className="btn btn-actions container_fields_btn background_white"
           style={{paddingBottom: "2px", border: '1px solid rgb(176,177,180)'}}>
        <a className="icon-th-list" onClick={handleOpenOptionalFields}></a>
      </div>
    </th>
    {tableHeadColumns}
  </tr>
  </thead>)
}

export default HeaderTable
