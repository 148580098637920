import React from 'react'
import ItemDropdown from "./ItemDropdown";
import ItemStatus from "./ItemStatus";
import {CustomerTypeList} from "./constants";
import moment from "moment";
import {updateNotifications} from "../actions";

function ItemRow(props) {
  const {
    item,
    category,
    current_location,
    entered_by,
    attachments,
    in_fields,
    date_format,

    editItem,
    openClaimItem,
    openMarkAsFoundItem,
    openReturnToOwnerItem,
    transitionAction,
    getMembersDropdownData,
    updateNotifications,
  } = props

  const getFieldValue = (field) => {
    if (['lost_by', 'found_by', 'claimed_by'].includes(field))
      return item.attributes[field]?.full_name

    if (field === 'category')
      return category?.attributes?.name

    if (field === 'current_location')
      return current_location?.attributes?.name

    if (field === 'entered_by')
      return entered_by?.attributes?.full_name

    if (field === 'customer_name')
      return item.attributes.displayed_customer?.full_name

    if (field === 'customer_type') {
      let customer_type = item.attributes.displayed_customer?.type
      return item.attributes.displayed_customer?.type ? CustomerTypeList.filter((ct) => ct.value === customer_type)[0].label : null
    }

    if (field.includes('date')) {
      return item.attributes[field] ? moment(item.attributes[field], moment.ISO_8601).format(date_format.replaceAll('d', 'D').replaceAll('m', 'M')) : null
    }


    return item.attributes[field]
  }


  return (<tr key={item.id}>
      <ItemDropdown
        item={item}
        editItem={editItem}
        openMarkAsFoundItem={openMarkAsFoundItem}
        openClaimItem={openClaimItem}
        openReturnToOwnerItem={openReturnToOwnerItem}
        transitionAction={transitionAction}
        getMembersDropdownData={getMembersDropdownData}
        updateNotifications={updateNotifications}
      />
      {in_fields.map((in_field, _) => {
        const isStatusField = in_field === "status";
        const isLinkField = ["lfin"].includes(in_field);

        return (<td
          key={`${in_field}_${item.id}`}
          style={{
            whiteSpace: "nowrap", paddingLeft: "15px", color: in_field === "status" ? "#8F8F8F" : ""
          }}
        >
          {isLinkField && (<a
            className="link"
            onClick={(e) => editItem(e.currentTarget.id)}
            id={item.id}
          >
            {getFieldValue(in_field) || "-"}
          </a>)}

          {isStatusField && <ItemStatus item={item} transitionQuickAction={transitionAction}/>}

          {!isLinkField && !isStatusField && (getFieldValue(in_field) || "-")}
        </td>);
      })}
    </tr>
  )
}

export default ItemRow
