import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import update from 'immutability-helper';
import moment from 'moment';
import { MdCancel } from "@react-icons/all-files/md/MdCancel";
import _ from "lodash";
import {SyncLoader} from "react-spinners";
function TaskDashboardFilter(props) {


  const initialState = {  }

  const [state, setState] = useState(initialState);
  const {assign_to_options, tags_options, filters, applyFilters, historySearch, user_id, taskId} = props;

  const {assignee, task_tag, quick_search, searching} = filters;
  let flt_assign_to_options = [{label: null, options: [{value: "all", label: "All"}]}, ...assign_to_options]
  let flt_tags_options = [{value: "all", label: "All"}, ...tags_options]

  let typingTimer;                //timer identifier
  let doneTypingInterval = 300;  //time in ms, 5 second for example

  let default_user = {label: "All", value: "all"}
  let default_tag = {label: "All", value: "all"}

  try {
    default_user = (assignee["value"] === user_id ? assignee : assign_to_options.length > 0 ?  _.find(assign_to_options[1].options, (t) => t["value"] == user_id) || default_user : default_user)
  }
  catch(err) {
  }

  const disabled_reset_btn = (assignee &&  assignee["value"] === default_user["value"]) && (task_tag && task_tag["value"] === default_tag["value"]) && (quick_search === "")

  const handleQuickSeachKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(QuickSearch, doneTypingInterval);
  }

  const handleQuickSeachKeyDown = () => {
    clearTimeout(typingTimer);
  }

  const handleResetQuickSearch = () => {
    applyFilters({name: "quick_search", value: ""})
    historySearch("");
  }

  const handleSetFilter = (name, value) => {
    localStorage.setItem(name + props.customerId, JSON.stringify(value));
    applyFilters({name: name, value: value})
  }

  const handleResetAllFilters = () => {
    localStorage.removeItem("assignee"+ props.customerId);
    localStorage.removeItem("task_tag"+ props.customerId);
    applyFilters({name: "quick_search", value: ""})
    applyFilters({name: "task_tag", value: default_tag})
    applyFilters({name: "assignee", value: default_user})
    historySearch("");
  }

  const handleResetFilter = (name, value) => {
    localStorage.removeItem(name + props.customerId);
    applyFilters({name: name, value: value})
  }
  const QuickSearch = () => {
    applyFilters({name: "searching", value: true})
    historySearch(quick_search);
  }

  if(!taskId && localStorage.getItem("assignee"+ props.customerId) && disabled_reset_btn && assign_to_options.length > 0)
    if(JSON.stringify(assignee) !== localStorage.getItem("assignee"+ props.customerId))
      applyFilters({name: "assignee", value: JSON.parse(localStorage.getItem("assignee"+ props.customerId))})

  if(!taskId && localStorage.getItem("task_tag"+ props.customerId) && disabled_reset_btn && tags_options.length > 0)
    if(JSON.stringify(task_tag) !== localStorage.getItem("task_tag"+ props.customerId))
      applyFilters({name: "task_tag", value: JSON.parse(localStorage.getItem("task_tag"+ props.customerId))})

  if (taskId && disabled_reset_btn && assign_to_options.length > 0) {
    if(JSON.stringify(assignee) !== JSON.stringify({value: "all", label: "All"}))
      applyFilters({name: "assignee", value: {value: "all", label: "All"}})
  }

  return (
    <div className="task-filter-container pe-3">
      <div className="task-filter-body-first">
        <span className= "task-filter-item">
          <b>Filter by:</b>
        </span>
        <div className="task-filter-item">
          <b>Assignee</b>
          <div className={"filter-assignee-container"}>
            <Select id="filter_assignee"
                    name='assignee'
                    options={flt_assign_to_options}
                    onChange={(e) => handleSetFilter( "assignee", e)}
                    value={assignee}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: 0,
                        boxShadow: "none",
                        backgroundColor: "white",
                        color: "#212529",
                        width: "100%",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected || state.isFocused ? '#FDEDE9' : 'inherit',
                        color: state.isSelected ? '#212529' : 'inherit',
                      }),
                      borderRadius: 0,
                      groupHeading: (base) => ({
                        ...base,
                        marginRight: '10px',
                        marginLeft: '10px',
                        border: '1px solid #F7F7F7',
                        borderWidth: 'thin',
                      })
                    }}
            />
          </div>
          {assignee && assignee["value"] !== default_user["value"] && <MdCancel className="cursor_pointer fs-4 color_red"
                   onClick={(e) => handleResetFilter("assignee", default_user)}/>}
        </div>
        <div className="task-filter-item">
          <b>Task Tag</b>
          <div className={"filter-task-container"}>
            <Select id="tags"
                    name='tags'
                    options={flt_tags_options}
                    value={task_tag}
                    onChange={(e) => handleSetFilter( "task_tag", e)}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: 0,
                        boxShadow: "none",
                        backgroundColor: "white",
                        color: "#212529",
                        width: "100%",

                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected || state.isFocused ? '#FDEDE9' : 'inherit',
                        color: state.isSelected ? '#212529' : 'inherit',
                      }),
                    }}
            />
          </div>
          {task_tag && task_tag["value"] !== default_tag["value"] && <MdCancel className="cursor_pointer fs-4 color_red"
                   onClick={(e) => handleResetFilter("task_tag", default_tag)}/>}
        </div>
      </div>
      <div className="task-filter-body-last">
        {searching &&  <SyncLoader
          loading={true}
          size={10}
          color="#FF6633"
          radius="9"
          className="p-2"
        />}
        <div className="task-filter-search input-group input-group-sm">
          <>
            <Form.Control type="text"
                          name='quick_search'
                          placeholder='Search...'
              value={quick_search || ""}
              onChange={(e) => applyFilters({name: "quick_search", value: e.target.value})}
              onKeyUp={handleQuickSeachKeyUp}
              onKeyDown={handleQuickSeachKeyDown}
            />
            <div className="input-group-text clear_quick_search fa fa-eraser cursor_pointer" onClick={(e) => handleResetQuickSearch()}>
            </div>
          </>
          <a
            className={`btn gems_custom_button gems_orange_button float-end align-vertical-middle ${disabled_reset_btn ? "disabled_class" : ""}`}
            onClick={handleResetAllFilters}
          >Reset All Filters</a>
        </div>
      </div>
    </div>
  )
}


export default TaskDashboardFilter
