import React from 'react';
import { Spinner} from 'react-bootstrap';

function RentalsHome(props) {
    return (
        <div className='col p-3 add-margin-all-10 text-center home_class' >
            <h4 className='mx-3 text-start mb-0 mt-2'>RENTALS</h4>
            {props.data.isLoading
                ?
                <div className='spinner_class'>
                    <Spinner className="d-block" animation="border" variant="warning" id="loading_spinner"/>
                </div>
                :
                <>
                    <div className='m-3 mt-20 mb-2 body_blue_component text-start'>
                        <div className='d-flex ms-3 me-3 item'>
                            <h3 className='order-1 m-3 mx-0'><b>{props.data.today}</b></h3>
                            <h5 className='order-2 m-3 add-line-height-35 fw-bold'>Today</h5>
                        </div>
                    </div>
                    <div className='m-3 mt-0 mb-2 body_blue_component text-start'>
                        <div className='d-flex ms-3 me-3 item'>
                            <h3 className='order-1 m-3 mx-0'><b>{props.data.tomorrow}</b></h3>
                            <h5 className='order-2 m-3 add-line-height-35 fw-bold'>Tomorrow</h5>
                        </div>
                    </div>
                    {props.data.overdue > 0 &&
                        <div className='m-3 mt-0 mb-2 body_red_component text-start'>
                            <div className='d-flex ms-3 me-3 item'>
                                <h3 className='order-1 m-3 mx-0'><b>{props.data.overdue}</b></h3>
                                <h5 className='order-2 m-3 add-line-height-35 flex-grow-1 fw-bold'>Overdue</h5>
                                <span className='m-3 add-line-height-35 me-0 gsgicon-Alert_Icon-02 fs-3 order-3'/>
                            </div>
                        </div>
                    }
                    <div className='m-3 mt-0 mb-2 button_row'>
                        <div className='shop_dashboard_btn btn gems_custom_button cursor_pointer me-1'>
                            <a href={props.data.dashboard_url} data-method="get">Dashboard</a>
                        </div>
                        <div className='shop_dashboard_btn btn gems_custom_button cursor_pointer ms-1'>
                            <a href={props.data.analytics_url} data-method="get">Analytics</a>
                        </div>
                    </div>
                </>
            }
        </div>
    )}
export default RentalsHome
