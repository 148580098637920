import React, {useEffect, useState} from "react";
import Modal, {ModalBody, ModalFooter, ModalHeader} from "../../stock_orders/components/Modal";
import SortableList from "./shared/SortableList";

const ReorderTilesModal = ({isOpen, onClose, onSave, tiles}) => {
    const titleize = (str) => {
        return str
            .toLowerCase()
            .replace(/_/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const initialState = tiles
    const [state, setState] = useState(initialState);

    useEffect(() => {
        if (isOpen) {
            setState(initialState);
        }
    }, [isOpen, tiles]);

    const handleSave = () => {
        onSave(state);
    };


    return isOpen ? (
        <div id="homeDashboardReorderTilesModalDiv">
            <div
                style={{position: "fixed", top: 0, left: 0, width: "100vw", height: "100vh", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 1040,}}
                onClick={onClose}
            ></div>

            <Modal
                isOpen={isOpen}
                scrollable
                className="homeDashboardReorderTilesModalSize"
                dialogClassName="modal-dialog-centered"
                style={{zIndex: 1050}}
            >
                <ModalHeader className="header">
                    <h4 className="modal-title form_new_edit m-0">ORGANIZE HOME FEATURES</h4>
                </ModalHeader>
                <ModalBody style={{minHeight: "300px", maxHeight: "calc(100vh - 200px)", overflowY: "auto"}}>
                    <SortableList
                        items={state.map((tile, index) => ({
                            id: index.toString(),
                            label: titleize(tile),
                            raw: tile
                        }))}
                        setItems={(newItems) => setState(newItems.map(item => item.raw))}
                        renderItem={(item) => (
                            <div className="sortable-item d-flex align-items-center justify-content-between">
                                <span className="form-check-label ps-2 pt-1">{item.label}</span>
                                <i className="fa fa-bars mt-2 drag-handle"></i>
                            </div>
                        )}
                    />
                </ModalBody>
                <ModalFooter className="d-flex justify-content-between">
                    <button className="btn gems_custom_button" onClick={onClose} style={{marginLeft: "0.5rem"}}>
                        Close
                    </button>
                    <button className="btn gems_custom_button gems_orange_button" onClick={handleSave}
                            style={{marginLeft: "auto", marginRight: "0.5rem"}}>
                        Save
                    </button>
                </ModalFooter>
            </Modal>
        </div>
    ) : null;
};

export default ReorderTilesModal