import React from 'react'
import GsgButton from "../../../shared/components/gsg_button";

function Footer(props) {

  const {
    newFoundItem,
    newLostItem,
    getMembersDropdownData
  } = props;

  const submitNewForm = (entry) => {
    getMembersDropdownData({key: ''})
    if (entry === 'lost')
      newLostItem()
    else
      newFoundItem()
  }

  return (

    <div className='row bottom_buttons_bar'>
      <div className="col-12 py-2 px-3">
        <GsgButton text="New Found Item"
                   className="btn gems_custom_button gems_orange_button btn-default add-margin-left-0 float-end  me-2"
                   disabled={false}
                   onClick={() => submitNewForm('found')}/>

        <GsgButton text="New Lost Item"
                   className="btn gems_custom_button gems_orange_button btn-default add-margin-left-0 float-end  me-2"
                   disabled={false}
                   onClick={() => submitNewForm('lost')}/>
      </div>
    </div>
  )
}


export default Footer