import React, {useEffect} from "react";
import {Form, OverlayTrigger, Row, Spinner, Table, Tooltip} from "react-bootstrap";
import UploadImage from "./UploadImage";

const ImagesTab = (props) => {
  const {
    customer_id,
    files_type,
    object_id,
    object_type,
    attachments,

    uploadFile,
    removeFile,
  } = props;

  return (
    <div className={'col d-flex flex-column'}>
      <Row className={'m-0'}>
        <UploadImage
          customer_id={customer_id}
          files_type={files_type}
          object_id={object_id}
          object_type={object_type}

          uploadFile={uploadFile}
        />
      </Row>
      <Row className={'m-0 mt-2 overflow-auto flex-grow-1'}>
        {(attachments.length > 0) &&
          attachments.map((file) => {
            return (
              <div className="col-3 file_container" key={`attach_${file.id}`}>
                <div className="file_icon position-relative text-center">
                  <span className={`fa ${file.attributes?.icon}`}></span>
                  <div className="file_actions">
                    <a data-confirm="Are you sure?" title="Delete" className="fa fa-remove color_red file_delete"
                       onClick={() => removeFile(file)}></a>
                    {object_id !== 'new' && <a className="fa fa-download file_download" target="_blank" style={{color: 'darkgreen'}}
                                               href={file.links?.download}></a>}
                  </div>
                </div>
                <div className="file_text text-center position-relative p-0">
                  <span className="file_name">{file.attributes?.name}</span>
                </div>
              </div>
            )
          })}
      </Row>
</div>
)
  ;
};

export default ImagesTab;
