import { showErrorNotification, callAPI } from 'shared/helpers'
import {showNotification} from "../../shared/helpers";

export const ActionTypes = {
  SET_TILES_ORDER: 'SET_TILES_ORDER',
  LOAD_COMPONENT_DATA: 'LOAD_COMPONENT_DATA',
  SET_IS_LOADING: 'SET_IS_LOADING'
}

export const load_component_data = (data) => (dispatch, getState) => {
  const state = getState()

  const dataURL = "/customers/"+state.dashboard.customer_id+"/shop_dashboard/"+data.key+"/load_component_data"
  let subcomponent_filters = {}

  if(data.subcomponent_key)
    subcomponent_filters[data.subcomponent_key] = data.subcomponent_filters
  else
    Object.entries(state.dashboard.components).map(key => key[1]["filters"] ? subcomponent_filters[key[0]] = key[1]["filters"] : "");

  const params = {
                  customer_id: data.customer_id,
                  id: data.key,
                  subcomponent_filters: subcomponent_filters,
                  subcomponent_key: data.subcomponent_key,
                  to: data.to || state.dashboard.to,
                  from: data.from || state.dashboard.from,
                  reference_date: data.reference_date || state.dashboard.reference_date,
                  date_format: data.date_format || state.dashboard.date_format,
                  refresh: data.refresh || false,
                 }

  if(!state.dashboard.isLoading)
    dispatch({
      type: ActionTypes.SET_IS_LOADING,
      data: {subcomponent_key: data.subcomponent_key, isLoading: true},
    })

  callAPI(dataURL, 'GET', params, new Headers({ 'Accept': 'application/json', 'Content-Type': 'applications/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.LOAD_COMPONENT_DATA,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const reorder_tiles = (data) => (dispatch, getState) => {
    const state = getState();
    const dataURL = `/customers/${state.dashboard.customer_id}/dashboard/reorder_tiles`;

    const params = data.titles.reduce((acc, title, index) => {
        acc[`tile_${index + 1}`] = title;
        return acc;
    }, {});

    callAPI(dataURL, "POST", params, new Headers({ 'Accept': 'application/json', 'Content-Type': 'applications/json' }))
        .then(response => {
            dispatch({
                type: ActionTypes.SET_TILES_ORDER,
                data: response,
            })
            window.gems.toast_message.init("Successfully updated!", "success")
        })
        .catch(() => {
            window.gems.toast_message.init("Error while updating!", "error")
            showErrorNotification('Error while fetching data!')
        })
};